import React from "react";
import Proptypes from "prop-types";

import Util from "./lib/Util";

import Page from "./Components/Page";
import SelectCountry from "./Components/SelectCountry";

class VisitMsg extends React.Component {
	constructor(props) {
		super(props);
		this.props.App.storyNeed("/VISIT", Util.HOME);

		let callback = "";
		let phones = this.props.App.state.me.phones;
		if (phones && phones.length) callback = phones[0];
		let story = this.props.App.storyGet();
		let name = story.firstname + " " + story.lastname;
		this.state = this.props.App.storyGet({
			callback: callback,
			countryCode: Util.countryFromPhone(callback),
			msg: "",
			name: name.trim(),
			msgLength: Util.MAIL_MSG_MAX_SIZE,
		});
	}
	notify(readyToProcess) {
		this.forceUpdate();
	}
	onChange(value, field, process) {
		if (field === "callback") {
			value = Util.niceInternationalPhone(value, this.state.countryCode);
		} else if (field === "msg") {
			value = value.slice(0, this.state.msgLength);
		} else value = Util.nice(value, field);

		let update = { [field]: value };
		console.log("onChange", update);
		this.setState(update);
		this.props.App.storyUpdate(update);
		if (this.props.form) this.props.form.notify(process);
	}
	onCountry(value) {
		if (value === this.state.countryCode) return;
		this.setState({ countryCode: value });
		this.onChange("", "callback", true);
		if (this.refInput) this.refInput.focus();
	}
	onBlur(field) {
		let value = this.state[field].trim();
		this.onChange(value, field, true);
	}
	goNext() {
		this.props.App.storyProcess();
	}
	render() {
		const { App } = this.props;
		let maxMsg = this.state.msgLength <= this.state.msg.length;
		return (
			<Page
				App={App}
				titleCenterH2={Util.loc("Envoyer un message")}
				stepClass="title-containerEtape"
				claimTitle="claimTitle"
			>
				<div className="row containt text-center claimBackground">
					<div className="m-auto col-12 col-sm-10 col-md-8 col-lg-6">
						<div className="claimPadding20">
							<div id="visit-call-back">
								<div className="row form-group">
									<div className="col-12 px-0 mt-5">
										<input
											type="text"
											className="form-control formControlStep"
											id="name"
											placeholder=""
											required
											value={this.state.name}
											onKeyPress={event => {
												if (event.key === "Enter")
													event.target.blur();
											}}
											onChange={event => {
												this.onChange(
													event.target.value,
													"name"
												);
											}}
											onBlur={event => {
												this.onBlur("name");
											}}
										/>
										<label htmlFor="name">
											{Util.loc("Mon nom *")}
										</label>
										<div
											style={Util.check(
												this.state.name,
												false
											)}
											className="invalid-tooltip"
										>
											{Util.loc(
												"Veuillez remplir ce champ correctement"
											)}
										</div>
									</div>

									<div className="col-12 pt-block mx-auto needs-validation px-0 pt-5 mt-2">
										<div className="claim-textarea formControlStep">
											<textarea
												className="text-center"
												rows="3"
												value={this.state.msg}
												onChange={event => {
													this.onChange(
														event.target.value,
														"msg"
													);
												}}
											/>
										</div>
										<label className="label-for-message">
											{Util.loc("Mon message")}
										</label>
										<div
											className="mr-4"
											style={{
												textAlign: "right",
												fontSize: "0.8rem",
											}}
										>
											&nbsp;
											{maxMsg
												? Util.loc(
														"Taille maximale atteinte"
												  )
												: " "}
										</div>
									</div>

									<div
										className="col-12 px-0 mt-5"
										style={{ textAlign: "left" }}
									>
										<SelectCountry
											onCountry={country =>
												this.onCountry(country)
											}
											countryCode={this.state.countryCode}
										/>
										<input
											type="tel"
											spellCheck={false}
											className="form-control formControlStep"
											id={"callback"}
											ref={ref => (this.refInput = ref)}
											placeholder={""}
											required
											value={this.state.callback}
											onKeyPress={event => {
												if (event.key === "Enter")
													event.target.blur();
											}}
											onBlur={event => {
												this.onBlur("callback");
											}}
											onChange={event => {
												this.onChange(
													event.target.value,
													"callback"
												);
											}}
										/>

										<label htmlFor={"callback"}>
											{Util.loc(
												"Un numéro pour me joindre *"
											)}
										</label>
										<div
											style={Util.checkInternationalPhone(
												this.state.callback,
												this.state.countryCode,
												this.props.withNext
											)}
											className="invalid-tooltip"
										>
											{Util.loc(
												"Veuillez saisir un numéro valide"
											)}
										</div>
									</div>
								</div>
							</div>
							{Util.checkPhone(this.state.callback) ? null : (
								<div className="row pt-block">
									<div className="col-12">
										<p className="text-center color-title worksans-light cgu">
											{Util.loc(
												"En validant, j'accepte que mes coordonnées soient communiquées et conservées par mon correspondant."
											)}
										</p>
									</div>
								</div>
							)}
							<div className="row">
								<div className="color-btn col-10 m-auto">
									<button
										className={Util.nextButtonClassic(
											this.props.App.checkMsg()
										)}
										onClick={() => this.goNext()}
									>
										{Util.loc("Envoyer")}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Page>
		);
	}
}

VisitMsg.propTypes = {
	App: Proptypes.object.isRequired,
};

export default VisitMsg;
