import propTypes from "prop-types";
import React, { Component } from "react";
import Util from "../lib/Util";

class CarouselText extends Component {
	render() {
		return this.props.position === 0 ? (
			<div className="carousel-text-block-1">
				<span className="text-principal">
					{Util.loc("Un colis à ne pas\u00a0manquer\u00a0?")}
				</span>
			</div>
		) : this.props.position === 1 ? (
			<div
				className="carousel-text-block-2"
				/*style={{
					height:
						window.innerWidth < 501 &&
						this.props.imgHeight * 2.75 + "px",
				}}*/
			>
				<div className={window.innerWidth < 501 ? "col-10" : "col-7"}>
					<span className="text-principal">
						{Util.loc("Avec mes SPOORS")}
					</span>
					<span className="text-secondaire">
						{Util.loc(
							"je suis joignable depuis n'importe quel objet sans dévoiler mon numéro"
						)}
					</span>
				</div>
			</div>
		) : (
			<div className="carousel-text-block-3">
				<span className="text-principal">
					{Util.loc("Perdu\u00a0? Retrouvé\u00a0!")}
				</span>
			</div>
		);
	}
}
CarouselText.propTypes = {
	position: propTypes.number,
	imgHeight: propTypes.number,
};

export default CarouselText;
