import React, { Component } from "react";

import Util from "../lib/Util";

import BlueContainer from "./BlueContainer";
import Footer from "./Footer";

class HomeBottomPage extends Component {
	setRefForScroll(elem) {
		if (!elem) return;
		//		console.log("setRef", elem.id);
		if (elem.id === this.props.section) {
			//			console.log("FOUND!!");
			elem.scrollIntoView();
		}
	}

	render() {
		return (
			<>
				<div className="curious-container">
					<BlueContainer
						title={Util.loc("Vous êtes curieux ?")}
						text={
							"<p>" +
							Util.loc(
								"La meilleure façon de se faire un avis, c’est d’essayer <br/>C’est par ici !"
							) +
							"</p>"
						}
						btnText={Util.loc("Profitez de l'offre !")}
						href="https://www.amazon.fr/stores/page/AC67A204-56B4-4C8A-BECC-710DF040FBF8?ingress=0&visitId=f2e3cada-2f14-431e-a499-4c56ad690a0c&ref_=ast_bln"
					/>
				</div>
				<div style={{ position: "relative", top: -50 }} />
				<Footer home={true} />
			</>
		);
	}
}

export default HomeBottomPage;
