import propTypes from "prop-types";
import React, { Component } from "react";

import CarouselText from "./CarouselText";

class Carousel extends Component {
	constructor(props) {
		super(props);
		this.autoAnim = true;
		this.state = {
			position: 1,
			touchStart: 0,
			touchEnd: 0,
		};
	}

	move(delta) {
		let pictures = this.props.pictures;
		let position = delta + this.state.position;
		position = (position + pictures.length) % pictures.length;
		this.setState({ position: position });
		clearInterval(this.interval);
		if (this.autoAnim)
			this.interval = setInterval(() => this.move(1), 5000);
	}

	handleTouchStart(e) {
		this.setState({ touchStart: e.targetTouches[0].clientX });
	}

	handleTouchMove(e) {
		this.setState({ touchEnd: e.targetTouches[0].clientX });
	}

	handleTouchEnd(e) {
		if (
			this.state.touchStart - this.state.touchEnd > 150 &&
			this.state.touchEnd !== 0
		) {
			this.setState({ touchStart: 0 });
			this.setState({ touchEnd: 0 });
			this.move(1);
		}
		if (
			this.state.touchStart - this.state.touchEnd < -150 &&
			this.state.touchEnd !== 0
		) {
			this.setState({ touchStart: 0 });
			this.setState({ touchEnd: 0 });
			this.move(-1);
		}
	}

	componentDidMount() {
		if (this.autoAnim)
			this.interval = setInterval(() => this.move(1), 5000);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	computeImageStyle(picture) {
		let height = null;
		let width = null;
		if (window.innerWidth > 500) return {};
		width = window.innerWidth;
		height = (width * picture.height) / picture.width;
		return {
			width: width + "px",
			height: Math.round(height) + "px",
			heightInteger: Math.round(height),
		};
	}
	render() {
		let picture = this.props.pictures[this.state.position];
		let imageStyle = this.computeImageStyle(picture);
		/*console.log(
			"window.innerWidth : ",
			window.innerWidth,
			"-> ",
			imageStyle
		);*/
		return (
			<section
				id="carousel"
				onTouchStart={e => this.handleTouchStart(e)}
				onTouchMove={e => this.handleTouchMove(e)}
				onTouchEnd={e => this.handleTouchEnd(e)}
			>
				<button
					className="btn"
					id="left-btn"
					onClick={() => this.move(-1)}
				>
					<img
						className={"arrow slide" + this.state.position}
						alt=""
					/>
				</button>

				<div className="carousel-img-txt" style={imageStyle}>
					<img
						id="carousel-img"
						className="img"
						src={picture.img}
						alt=""
					/>
					<CarouselText
						position={this.state.position}
						imgHeight={imageStyle.heightInteger}
					/>
				</div>
				<button
					className="btn"
					id="right-btn"
					onClick={() => this.move(1)}
				>
					<img
						className={"arrow slide" + this.state.position}
						alt=""
					/>
				</button>
			</section>
		);
	}
}

Carousel.propTypes = {
	pictures: propTypes.array.isRequired,
};

export default Carousel;
