import Util from "../lib/Util";

import VignetteEngie from "../style/img/connected-sticker-ENGIE.png";
import BellEngie from "../style/img/connected-bell-ENGIE.png";

import EngieWindow from "../style/img/fenetreEngie.png";
import EngieCloud from "../style/img/nuageEngie.png";
import EngieSun from "../style/img/soleilEngie.png";

export const PRODUCTS = {
	ENGIE_VIGNETTE: {
		id: Util.ENGIE_VIGNETTE,
		color: "blue",
		get name() {
			return Util.loc("1 vignette SPOORS");
		},
		info: {
			get size() {
				return Util.loc("85,6 mm x 85,6 mm");
			},
			get material() {
				return Util.loc("Vitrophanie");
			},
			get bonding() {
				return Util.loc("Face avant adhésive");
			},
		},
		pictures: {
			get description() {
				return Util.loc(
					"Pour le pare-brise de mon véhicule et les surfaces vitrées"
				);
			},
			small: VignetteEngie,
			large: VignetteEngie,
		},
		card: {
			header: {
				get text() {
					return Util.loc("Surfaces vitrées");
				},
				icons: [EngieWindow],
			},
			get title() {
				return Util.loc(
					"<span><strong>1 Vignette auto</strong><br /> connectée offerte</span>"
				);
			},
			get description() {
				return Util.loc(
					"Pour le pare-brise de mon véhicule et les surfaces vitrées"
				);
			},
			className: "orange",
		},
	},
	ENGIE_BELL: {
		id: Util.ENGIE_BELL,
		color: "blue",
		get name() {
			return Util.loc("1 sonnette SPOORS");
		},
		info: {
			get size() {
				return Util.loc("85,6 mm x 53,9 mm");
			},
			get material() {
				return Util.loc("Carte en PVC");
			},
			get bonding() {
				return Util.loc("Dos adhésif");
			},
		},
		pictures: {
			get description() {
				return Util.loc(
					"Sonnette Connectée : être prévenu en cas de dégât des eaux"
				);
			},
			small: BellEngie,
			large: BellEngie,
		},
		card: {
			header: {
				get text() {
					return Util.loc("Pour l'extérieur");
				},
				icons: [EngieCloud, EngieSun],
			},
			get title() {
				return Util.loc(
					"<span><strong>1 Sonnette</strong><br /> connectée offerte</span>"
				);
			},
			get description() {
				return Util.loc(
					"Pour ma boite aux lettres, mon portail ou ma porte d’entrée"
				);
			},
			className: "green",
		},
	},
};
