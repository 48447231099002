import React from "react";
import Proptypes from "prop-types";

import Util from "./lib/Util";
import { customHistory } from "./index";
import IcoFin from "./style/img/ico-fin.png";

import Page from "./Components/Page";
import BoxNumber from "./Components/BoxNumber";
import Button from "./Components/Button";

class PurchaseDone extends React.Component {
	constructor(props) {
		super(props);
		this.props.App.storyNeedMulti(
			["/PURCHASE", "/PURCHASE_DONE"],
			Util.HOME
		);

		this.props.App.storySet({ url: "/PURCHASE_DONE" });

		this.url = Util.getProviderExt().url;
		Util.setProvider();
	}

	onClick() {
		if (this.url) return window.location.replace(this.url);
		this.props.App.go(Util.HOME);
	}
	renderSteps() {
		const steps = [
			Util.loc("Dès réception, je scanne le QR code."),
			Util.loc("Je paramètre mon SPOORS en 2 étapoes !"),
			Util.loc("Je colle mon SPOORS !"),
		];

		return steps.map((step, index) => {
			return (
				<div key={index} className="step">
					<BoxNumber number={index + 1} />
					<span className="worksans">{step}</span>
				</div>
			);
		});
	}

	render() {
		const { App } = this.props;

		return (
			<Page App={App} title={Util.loc("C'est noté")} headerTheme="light">
				<div id="purchase-done" className="containt">
					<div className="row">
						<div className="col-12 col-sm-10 col-md-8 col-lg-6 mx-auto text-center block">
							<div className="ico-title m-auto image">
								<img
									src={IcoFin}
									alt="check"
									className="img-check"
								/>
							</div>
							<div className="row content">
								<div className="col-12 m-auto">
									<p className="worksans">
										{Util.loc(
											"Je recevrai ma commande sous un délai de 15 jours."
										)}
									</p>
									<div>
										<h2 className="worksans-bold mb-4">
											{Util.loc(
												"Pour activer mon SPOORS"
											)}
											:
										</h2>
										{this.renderSteps()}
									</div>
									<div className="col-12 text-center mt-5">
										<Button
											onClick={() => this.onClick()}
											enabled
										>
											{Util.loc("Retour à l'accueil")}
										</Button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Page>
		);
	}
}

PurchaseDone.propTypes = {
	App: Proptypes.object.isRequired,
};

export default PurchaseDone;
