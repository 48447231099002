import Util from "../lib/Util";

export function COUNTRIES() {
	return {
		AT: Util.loc("Autriche"),
		BE: Util.loc("Belgique"),
		CH: Util.loc("Suisse"),
		DE: Util.loc("Allemagne"),
		DK: Util.loc("Danemark"),
		ES: Util.loc("Espagne"),
		FI: Util.loc("Finlande"),
		FR: Util.loc("France"),
		GB: Util.loc("Royaume-Uni"),
		GF: Util.loc("Guyane"),
		GP: Util.loc("Guadeloupe"),
		GR: Util.loc("Grèce"),
		HU: Util.loc("Hongrie"),
		IE: Util.loc("Irlande"),
		IT: Util.loc("Italie"),
		KR: Util.loc("Corée du Sud"),
		LU: Util.loc("Luxembourg"),
		//MA: Util.loc("Maroc"),
		NO: Util.loc("Norvège"),
		MQ: Util.loc("Martinique"),
		NL: Util.loc("Pays-bas"),
		PL: Util.loc("Pologne"),
		PT: Util.loc("Portugal"),
		RO: Util.loc("Roumanie"),
		SE: Util.loc("Suède"),
		US: Util.loc("USA"),
		YT: Util.loc("Mayotte ou La Réunion"),
	};
}
