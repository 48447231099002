import React, { Component } from "react";

class Modal extends Component {
	static instance = undefined;

	constructor(props) {
		super(props);

		this.state = {
			show: false,
			content: undefined,
			background: undefined,
		};

		Modal.instance = this;
	}

	hideAndClear() {
		this.setState({
			show: false,
			content: undefined,
			background: undefined,
		});
	}

	show() {
		const { content } = this.state;

		if (content === undefined) {
			console.error("Modal content not defined cannot show the modal!");
			return;
		}

		this.setState({ show: true });
	}

	/**
	 * @param modalContent - JSX component
	 * @param {"light" | "dark"} background - used to override the default modal's background color: transparent
	 */
	configureAndShow(modalContent, background = "dark") {
		this.setState({ content: modalContent, background }, () => {
			this.show();
		});
	}

	render() {
		const { show, content, background } = this.state;

		if (!show) return null;

		return (
			<div id="modal" className={`bg-${background}`}>
				<div className="content">{content}</div>
			</div>
		);
	}
}

export default Modal;
