import React from "react";
import Proptypes from "prop-types";
import { Link } from "react-router-dom";
import Util from "../lib/Util";
import ChevRevenirIcon from "../style/img/chevron-revenir.svg";

const Retour = ({ id, classNames, to, title }) => {
	return (
		<a
			id="step-revenir"
			className="bouttonRevenir bell-btn"
			onClick={() => to()}
		>
			<img src={ChevRevenirIcon} alt="" />
			{title ? title : Util.loc("Précédent")}
		</a>
	);
};

/* ChevRetour.propTypes = {
	classNames: Proptypes.string,
	to: Proptypes.string.isRequired,
}; */

export default Retour;
