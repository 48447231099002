import React from "react";
import { Link } from "react-router-dom";
import Proptypes from "prop-types";

import Util from "./lib/Util";

import IcoId from "./style/img/letter.svg";
import IcoMdp from "./style/img/lock.svg";

import Page from "./Components/Page";
import Api from "./lib/Api";

class HomeSignin extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			email: "",
			code: "",
			askCode: false,
			askCgu: false,
			cgu: false,
			resend: true,
		};
	}
	changeField(event, field) {
		//		console.log(field);
		//		console.log(event.target.checked);
		let update = { [field]: Util.nice(event.target.value, field) };
		this.setState(update);
	}
	reSendCode() {
		this.setState({ resend: false });
		this.sendCode(true);
	}
	sendCode(force) {
		if (!force) {
			if (!this.checkButton()) return;
			if (this.state.code != "")
				return this.props.App.signin(this.state.email, this.state.code);
		}
		new Api().fetch("sendCode", { email: this.state.email }, result => {
			if (!result) return this.props.App.alert();
			if (result.err) return this.props.App.alert(result.err, result.msg);
			this.setState({ askCode: true, askCgu: result.askCgu });
		});
	}
	checkButton() {
		console.log("checkButton", this.state);
		if (Util.checkEmail(this.state.email)) return false;
		if (!this.state.askCode) return true;
		if (this.state.code.length != 6) return false;
		if (this.state.askCgu && !this.state.cgu) return false;
		return true;
	}
	render() {
		const { App } = this.props;
		console.log("enabled=", this.checkButton());
		return (
			<Page App={App} className="home-signin">
				<section id="home-signin">
					<div className="row bg-primary">
						<div className="col-12 my-30 text-center" />
					</div>
					<div className="row position-relative">
						<div className="bg-primary fix" />
						<div className="col-12 col-sm-10 col-md-8 col-lg-6 mx-auto mb-60">
							<div className="carton board">
								<div className="carton-head carton-head-secondary">
									<h1 className="text-center montserrat-bold">
										{Util.loc("Connexion")}
									</h1>
								</div>
								<div className="carton-body body-connexion carton-body-primary">
									<div className="row">
										<div
											className="col-12 needs-validation"
											noValidate
										>
											<div className="form-row connexion">
												{this.state.askCode ? (
													<>
														<p>
															{Util.loc(
																"Je saisis le code à 6 chiffres que je viens de recevoir par email à cette adresse :"
															)}
															<br />
															<br />
															<span>
																{
																	this.state
																		.email
																}
															</span>
														</p>
														<p>
															<small>
																{Util.loc(
																	"(à noter : l’email peut mettre plus d’une minute à arriver. Pensez aussi à vérifier dans votre courrier indésirable)"
																)}
															</small>
														</p>
														<div className="form-group col-12">
															<div className="row password">
																<label
																	htmlFor="password"
																	className="col-3 text-center"
																>
																	<img
																		src={
																			IcoMdp
																		}
																		alt=""
																	/>
																</label>
																<input
																	type="text"
																	id="code"
																	spellCheck={
																		false
																	}
																	autoComplete={Util.random()}
																	className="form-control col-9"
																	placeholder={Util.loc(
																		"saisir le code à 6 chiffres"
																	)}
																	required
																	value={
																		this
																			.state
																			.code
																	}
																	onKeyPress={event => {
																		if (
																			event.key ===
																			"Enter"
																		)
																			event.target.blur();
																	}}
																	onChange={event => {
																		this.changeField(
																			event,
																			"code"
																		);
																	}}
																/>
															</div>
														</div>
														{this.state.askCgu ? (
															<div className="row d-flex flex-row justify-content-center mb-10">
																<div className="col-10">
																	<div className="custom-control custom-checkbox">
																		<input
																			type="checkbox"
																			className="custom-control-input"
																			id="cgu-part"
																			checked={
																				this
																					.state
																					.cgu
																			}
																			onChange={event => {
																				this.setState(
																					{
																						cgu:
																							event
																								.target
																								.checked,
																					}
																				);
																			}}
																		/>

																		<label
																			className="custom-control-label text-left worksans-light cgu"
																			htmlFor="cgu-part"
																		>
																			{Util.loc(
																				"Je déclare avoir pris connaissance des CGV et CGU et de la politique de confidentialité consultables"
																			) +
																				" "}
																			<Link
																				to={
																					Util.HOME_CGV
																				}
																			>
																				{Util.loc(
																					"ici"
																				)}
																			</Link>
																			{" " +
																				Util.loc(
																					"et les accepter sans réserve."
																				)}
																		</label>
																	</div>
																</div>
															</div>
														) : null}
													</>
												) : (
													<div className="form-group col-12">
														<div className="row">
															<label
																htmlFor="id"
																className="col-3"
															>
																<img
																	src={IcoId}
																	alt=""
																/>
															</label>
															<input
																type="text"
																spellCheck={
																	false
																}
																className="form-control col-9"
																id="id"
																placeholder={Util.loc(
																	"saisir votre identifiant email"
																)}
																required
																value={
																	this.state
																		.email
																}
																onKeyPress={event => {
																	if (
																		event.key ===
																		"Enter"
																	)
																		event.target.blur();
																}}
																onChange={event => {
																	this.changeField(
																		event,
																		"email"
																	);
																}}
															/>
															<div
																style={Util.checkEmail(
																	this.state
																		.email,
																	true
																)}
																className="invalid-tooltip"
															>
																{Util.loc(
																	"Veuillez remplir ce champ correctement"
																)}
															</div>
														</div>
													</div>
												)}
											</div>
											<div className="col-12 text-center">
												<button
													className={Util.nextButton(
														!this.checkButton()
													)}
													type="button"
													onClick={() =>
														this.sendCode()
													}
												>
													{Util.loc("Me connecter")}
												</button>
												{this.state.resend &&
												this.state.askCode ? (
													<div>
														<a
															className="forgetPassword"
															onClick={() =>
																this.reSendCode()
															}
														>
															{Util.loc(
																"Renvoyer le code par email"
															)}
														</a>
													</div>
												) : null}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</Page>
		);
	}
}

HomeSignin.propTypes = {
	App: Proptypes.object.isRequired,
};

export default HomeSignin;
