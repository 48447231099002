import React from "react";
import { Link } from "react-router-dom";
import Proptypes from "prop-types";

import Util from "./lib/Util";
import GoogleAnalytics from "./lib/GoogleAnalytics";

import Page from "./Components/Page";
import Phone from "./Components/Phone";

// img
import Tel from "./style/img/phone-white.png";

class Visit extends React.Component {
	constructor(props) {
		super(props);
		this.props.App.storyNeed("/VISIT", Util.HOME);
		this.state = this.props.App.storyGet({
			reason: "",
			reasons: [],
			disabledReasons: {},
			mandatoryReason: false,
		});
		//		this.state.showModal = true;
		this.story = this.props.App.storyGet();
		this.ga = GoogleAnalytics.getInstance();
		this.isMobile = Util.isMobile();
		//if (this.story.sms && !this.isMobile) this.story.callMeBack = false;
	}
	onChange(value, field) {
		if (field == "reason" && this.state.disabledReasons[value]) {
			this.setState({ showModal: true });
			return;
		}

		let update = {};
		update[field] = value;
		this.setState(update);
		this.props.App.storyUpdate(update);
	}
	emailRing() {
		this.props.App.confirmPopup(
			Util.loc(
				"Votre correspondant n'est actuellement joignable que par mail. Nous allons le prévenir, mais voulez-vous lui laisser vos coordonnées ou un message\u00a0?"
			),
			result => {
				if (!result) return this.props.App.storyProcess();
				this.props.App.storyUpdate({ withMsg: true });
				this.props.App.go(Util.VISIT_MSG);
			},
			Util.loc("Non")
		);
	}

	onCallTapped = () => {
		this.ga.sendEvent(
			"Bell/Sticker event",
			"VISITING (PROSPECT) - CALL ACTION",
			"A prospect calls the owner of the bell/sticker"
		);
	};

	onVisitWebsiteTapped = () => {
		this.ga.sendEvent(
			"Bell/Sticker event",
			"VISITING (PROSPECT) - REDIRECTION PRO WEBSITE",
			"A prospect wants to visit the website of the owner's bell/sticker"
		);
	};

	onBeRecalledTapped = () => {
		this.ga.sendEvent(
			"Bell/Sticker event",
			"VISITING (PROSPECT) - BEING RECALLED ACTION",
			"A prospect wants to be recalled"
		);
	};

	onConfigureTapped = () => {
		this.ga.sendEvent(
			"Bell/Sticker event",
			"VISITING (OWNER) - CONFIGURATION",
			"The owner of the sticker/bell wants to configure it"
		);
	};

	componentDidMount() {
		if (this.story.bellRole === Util.ROLE_OWNER) {
			this.ga.sendEvent(
				"Bell/Sticker event",
				"VISITING (OWNER)",
				"The owner of the sticker/bell scanned it"
			);
		} else {
			this.ga.sendEvent(
				"Bell/Sticker event",
				"VISITING (PROSPECT)",
				"A prospect scanned a sticker/bell"
			);
		}
	}
	nextNumber() {
		this.props.App.storyProcess(result => {
			this.story = this.props.App.storyGet();
			this.forceUpdate();
		});
	}
	smsUrl() {
		let body =
			this.story.body + this.story.reasons[this.state.reason] + "\n";
		let str =
			"sms:" + this.story.sms + ";?&body=" + encodeURIComponent(body);
		console.log(str);
		return str;
	}
	modalAlreadyDone() {
		return (
			<div id="ModaleStep">
				<div className="modal-step-content">
					<p className="big-title" style={{ lineHeight: "2rem" }}>
						{Util.loc("Le problème est en cours de résolution !")}
					</p>
					<br />
					<p>{Util.loc("Merci de votre alerte.")}</p>
					<br />
					<p style={{ lineHeight: "1.5rem" }}>
						{Util.loc(
							"Le syndic a déjà été prévenu et gère le problème dans les meilleurs délais."
						)}
					</p>

					<button
						className="blueOk-bg big-text"
						onClick={() => {
							this.setState({ showModal: false });
						}}
					>
						{Util.loc("Compris !")}
					</button>
				</div>
			</div>
		);
	}
	render() {
		const { App } = this.props;
		let admin = this.props.App.isAdmin();

		let selectLabel = this.story.selectLabel
			? this.story.selectLabel
			: Util.loc("Cliquer sur le motif");
		let callbackLabel = this.story.callbackLabel
			? this.story.callbackLabel
			: Util.loc("Être rappelé");
		let ring = this.story.ring;
		let token = this.story.token;
		let reasonReady =
			!this.state.mandatoryReason || this.state.reason !== "";
		let showCallback = this.story.callMeBack ? true : false;
		let showSms = showCallback && this.story.sms;
		let showRing = ring >= 0 || token ? true : false;
		let showNumber = ring >= 0 && this.story.number;
		if (showNumber) showRing = false;
		let showUrl = false;

		if (this.state.company_url && this.state.company_url !== "") {
			showUrl = this.state.company_url;
			if (
				!(
					showUrl.startsWith("http://") ||
					showUrl.startsWith("https://")
				)
			)
				showUrl = "https://" + showUrl;
		}
		//		console.log("render", showRing);
		console.log(
			"reasonReady && showCallback && showSms",
			reasonReady && showCallback && showSms,
			reasonReady,
			showCallback,
			showSms
		);
		return (
			<>
				{this.state.showModal && this.modalAlreadyDone()}
				<Page App={App} withoutHeader>
					<div className="row containt text-center claimBackground">
						<div className="m-auto col-12 col-sm-10 col-md-8 col-lg-6">
							<div className="row">
								<div className="m-auto col-12 pt-block mb-4">
									<div className="px-3 py-3">
										<p className="text-center color-title font-size-1-3 font-weight-regular mb-4 mt-4">
											{this.story.welcome}
										</p>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-12 m-auto">
									<h1 className="upper font-weight-bold color-title">
										{selectLabel}
									</h1>
									<div
										id="btn-call-rattachement"
										className="d-flex justify-content-around flex-wrap"
									>
										{Object.keys(this.story.reasons).map(
											(key, i) => {
												return (
													<p
														key={i}
														className={
															"btn-rattachement" +
															(this.state
																.reason === key
																? " btn-rattachement-check"
																: "") +
															(this.state
																.disabledReasons[
																key
															]
																? " btn-rattachement-disabled"
																: "")
														}
														onClick={() => {
															this.onChange(
																key,
																"reason"
															);
														}}
													>
														{
															this.story.reasons[
																key
															]
														}
													</p>
												);
											}
										)}
									</div>
								</div>
							</div>
							{!reasonReady && (showRing || showNumber) ? (
								<div className="row">
									<div className="phone-img col-10 m-auto d-flex justify-content-center">
										<button className="btn btn-primary orangeOpacity-bg btn-block">
											<img src={Tel} alt="tel" />
											{Util.loc("Appeler")}
										</button>
									</div>
								</div>
							) : null}
							{reasonReady && showNumber ? (
								<div className="row">
									<div className="phone-img col-10 m-auto d-flex justify-content-center">
										<a
											href={this.story.number}
											onClick={() => this.nextNumber()}
										>
											<button className="btn btn-primary btn-block">
												<img src={Tel} alt="tel" />
												{Util.loc("Appeler")}
											</button>
										</a>
									</div>
								</div>
							) : null}
							{reasonReady && showRing ? (
								<div className="phone-img">
									<Phone App={this.props.App}></Phone>
								</div>
							) : null}
							{!reasonReady && showCallback ? (
								<div className="row">
									<div className="phone-img col-10 m-auto d-flex justify-content-center">
										<button className="btn btn-primary orangeOpacity-bg btn-block">
											{callbackLabel}
										</button>
									</div>
								</div>
							) : null}
							{reasonReady && showCallback && !showSms && (
								<div className="row">
									<div className="phone-img col-10 m-auto d-flex justify-content-center">
										<Link to={Util.VISIT_MSG}>
											<button
												onClick={
													this.onBeRecalledTapped
												}
												className="btn btn-primary btn-block"
											>
												{callbackLabel}
											</button>
										</Link>
									</div>
								</div>
							)}
							{reasonReady && showCallback && showSms && (
								<div className="row">
									<div className="phone-img col-10 m-auto d-flex justify-content-center">
										<a href={this.smsUrl()}>
											<button
												className="btn btn-primary btn-block"
												onClick={() => {
													this.story.sms = false;
												}}
											>
												{callbackLabel}
											</button>
										</a>
									</div>
								</div>
							)}

							{showUrl ? (
								<div className="row">
									<div className="col-10 m-auto">
										<a
											onClick={this.onVisitWebsiteTapped}
											href={showUrl}
											target="_blank"
											rel="noopener noreferrer"
											style={{ color: "#fff" }}
											className="btn btn-primary btn-block"
										>
											{Util.loc("Voir mon site web")}
										</a>
									</div>
								</div>
							) : null}
							{admin ? (
								<a
									href={
										Util.URL +
										"admin.php#/bell/" +
										encodeURIComponent(
											JSON.stringify({
												code: this.story.code,
											})
										)
									}
									target="_blank"
									rel="noopener noreferrer"
								>
									π
								</a>
							) : null}
						</div>
					</div>
				</Page>
			</>
		);
	}
}

Visit.propTypes = {
	App: Proptypes.object.isRequired,
};

export default Visit;
