import React from "react";
import { Link } from "react-router-dom";

import Util from "./lib/Util";
import IcoGreat from "./style/img/ico-great.png";

// Svg
import IconParam from "./style/img/icon-param.svg";
import IconBoard from "./style/img/icon-board.svg";

import Page from "./Components/Page";

class ClaimDone extends React.Component {
	constructor(props) {
		super(props);
		this.props.App.storyNeed("/BELL_UPDATE", Util.HOME);
	}
	render() {
		const { App } = this.props;
		let story = App.storyGet();
		let usage = story.usage;
		let alerts = story.alerts;
		console.log(JSON.stringify(alerts));

		return (
			<Page
				App={App}
				titleCenterH2={Util.loc("SPOORS activé !")}
				stepClass="title-containerEtape"
				claimTitle="claimTitle"
			>
				<div id="claim-done" className="claimBackground">
					<div className="row">
						<div className="col-12 col-sm-10 col-md-8 col-lg-6 mx-auto text-center color-blue600">
							<div className="row claimPadding20 justify-content-center">
								<div>
									<p className="mt-3 mb-4 info-title">
										{Util.loc(
											"Bravo ! Mon SPOORS est actif avec le réglage suivant\u00a0:"
										)}
									</p>
									<div className="claim-bold mb-4 mt-4">
										{true ? null : (
											<p>
												<span>
													{Util.loc("SPOORS:")}
												</span>
												{story.title}
											</p>
										)}
										{alerts
											? alerts.map((item, index) => {
													if (index > 0) return null;
													return (
														<React.Fragment
															key={index}
														>
															{item.type &
															Util.ALERT_PHONE ? (
																<div className="claim-done-title">
																	<p>
																		{Util.loc(
																			"Téléphone :"
																		)}
																	</p>

																	<p className="claim-done-item">
																		{
																			item.phone
																		}
																	</p>
																	<p className="claim-done-horaire">
																		{Util.schedule(
																			item
																		)}
																	</p>
																</div>
															) : null}
															{item.type &
															Util.ALERT_SMS ? (
																<div className="claim-done-title">
																	<p>
																		{Util.loc(
																			"SMS :"
																		)}
																	</p>

																	<p className="claim-done-item">
																		{
																			item.phone
																		}
																	</p>
																</div>
															) : null}
															{item.type &
															Util.ALERT_EMAIL ? (
																<div className="claim-done-title">
																	<p>
																		{Util.loc(
																			"Email :"
																		)}
																	</p>
																	<p className="claim-done-item">
																		{
																			item.email
																		}
																	</p>
																</div>
															) : null}
														</React.Fragment>
													);
											  })
											: null}
									</div>
								</div>

								<div id="btn-icons">
									<Link
										to={Util.CLAIM_WELCOME}
										className="btn btn-board btn-icon"
									>
										<img src={IconParam} alt="" />
										{Util.loc("Personnalisation")}
									</Link>
									<p className="mb-5 info-text">
										{Util.loc(
											"Je personnalise mon SPOORS pour adapter le message d’accueil, limiter les horaires d’appels ou modifier les destinataires des alertes."
										)}
									</p>
									<Link
										style={{ marginTop: "20px" }}
										to={Util.BOARD}
										className="btn btn-board btn-icon"
									>
										<img src={IconBoard} alt="" />
										{Util.loc("tableau de bord")}
									</Link>
									<p className="mb-5 info-text">
										{Util.loc(
											"Mon tableau de bord me permet de gérer la totalité de mes SPOORS."
										)}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Page>
		);
	}
}
export default ClaimDone;
