import React from "react";
import Proptypes from "prop-types";

import Util from "./lib/Util";
import { Link } from "react-router-dom";

import Page from "./Components/Page";
import ButtonSuivant from "./Components/ButtonSuivant";
import FormName from "./Components/FormName";
import UtilitiesSelector from "./Components/UtilitiesSelector";

// moddale
import ModalStep from "./Components/ModalStep";

class ClaimUsage extends React.Component {
	constructor(props) {
		super(props);
		this.props.App.storyNeed("/CLAIM", Util.HOME);

		this.state = this.props.App.storyGet({
			user: 0,
			usage: "",
			title: "",
			type: "",
			askTitle: false,
			popupDone: false,
			withSms: true,
			cgu: false,
			cpv: "",
			usageList: [],
		});
		if (this.state.usageList && this.state.usageList.length == 1)
			setTimeout(() => {
				this.changeField(this.state.usageList[0], "usage");
			}, 100);
		//		this.state.popupDone = false;
	}

	changeField(value, field) {
		let update = { [field]: value };
		this.setState(update);
		this.props.App.storyUpdate(update);
		if (
			field === "usage" &&
			this.state.usageList.length > 1 &&
			this.scrollView
		)
			this.scrollView.scrollIntoView({
				behavior: "smooth",
			});
	}
	goNext() {
		//		console.log(JSON.stringify(this.props.App.storyGet()));
		if (this.props.App.checkClaimUsage()) return;
		if (this.props.App.isRegistered()) return this.props.App.storyProcess();
		this.props.App.go(Util.CLAIM_FORM);
	}
	render() {
		const { App } = this.props;
		const { usage, askTitle, cpv } = this.state;
		const ready = !this.props.App.checkClaimUsage();
		console.log("ready", ready);
		let usageList = this.state.usageList;

		return (
			<>
				{this.state.popupDone ? null : (
					<ModalStep
						App={App}
						type={this.state.type}
						notify={() => {
							this.changeField(true, "popupDone");
						}}
					/>
				)}
				<Page
					App={App}
					title={Util.loc("Etape 1 :")}
					titleH2={Util.loc("mon SPOORS")}
					stepClass="title-containerEtape step-class-h1"
					claimTitle="claimTitle"
				>
					<div
						id="claim-usage"
						className="row position-relative containt claimBackground"
						style={{ minHeight: 0 }}
					>
						<div
							className="col-12 m-auto  text-center"
							style={{ minHeight: "550px" }}
						>
							<div className="claimPadding20">
								<div className="row d-flex flex-row justify-content-center">
									<div className="col-12 col-md-10 col-lg-6">
										<p className="etape text-left">
											{Util.loc(
												"Je choisis comment je l'utilise"
											)}
										</p>
										<UtilitiesSelector
											onItemChecked={itemId =>
												this.changeField(
													itemId,
													"usage"
												)
											}
											usage={usage}
											usageList={usageList}
										/>
									</div>
								</div>
								<div ref={ref => (this.scrollView = ref)} />
								{askTitle ? (
									<div className="row d-flex flex-row justify-content-center">
										<div className="col-12 col-md-10 col-lg-6">
											<FormName
												className="no-form-container"
												onChange={event => {
													this.changeField(
														event.target.value,
														"title"
													);
												}}
												title={Util.loc("Je le nomme")}
												value={this.state.title}
											/>
										</div>
									</div>
								) : null}

								{cpv.length && App.isRegistered() ? (
									<div className="row d-flex flex-row justify-content-center mb-30">
										<div className="col-12 col-md-10 col-lg-6">
											<div className="custom-control custom-checkbox">
												<input
													type="checkbox"
													className="custom-control-input"
													id="cgu-part"
													checked={this.state.cgu}
													onChange={event => {
														this.changeField(
															event.target
																.checked,
															"cgu"
														);
													}}
												/>
												<label
													className="custom-control-label text-left worksans-light cgu"
													htmlFor="cgu-part"
												>
													{Util.loc(
														"Je déclare avoir pris connaissance des"
													) + " "}
													<Link to={Util.HOME_CGV}>
														{Util.loc(
															"Conditions générales de vente"
														)}
													</Link>
													{" " +
														Util.loc(
															"et d'utilisation de SPOORS, des"
														) +
														" "}
													<Link
														to={
															Util.HOME_CGV +
															"/" +
															cpv
														}
													>
														{Util.loc(
															"Conditions particulières"
														)}
													</Link>
													{" " +
														Util.loc(
															"y afférentes et de la Politique de confidentialité et les accepter sans réserve."
														)}
												</label>
											</div>
										</div>
									</div>
								) : null}

								{/* <div className="row block-support"> */}
								<div className="row d-flex flex-row justify-content-center">
									<div className="col-12 col-sm-10 col-md-8 col-lg-6">
										<div className="row">
											<div className="btnRevenirSuivant">
												<ButtonSuivant
													onClick={() =>
														this.goNext()
													}
													enabled={ready}
													className="step"
												>
													{Util.loc("Suivant")}
												</ButtonSuivant>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Page>
			</>
		);
	}
}

ClaimUsage.propTypes = {
	App: Proptypes.object.isRequired,
};

export default ClaimUsage;
