import React from "react";
import Proptypes from "prop-types";

import Util from "./lib/Util";
import { Link } from "react-router-dom";

import CenteredContainer from "./Components/CenteredContainer";
import TopMenu from "./Components/TopMenu";

import ArrowLeft from "../src/style/img/icon-arrow-left.svg";
import ArrowUp from "./style/img/arrow-up-blue.svg";
import ArrowDown from "./style/img/arrow-down-blue.svg";
import IcoValid from "./style/img/check-blue.svg";
import BottomPage from "./Components/BottomPage";

class ContactRole extends React.Component {
	constructor(props) {
		super(props);
		this.props.App.storyNeed("/CONTACT_UPDATE", Util.CONTACTS);

		// this.state=this.props.App.storyGet({label:"",bells:[]});
		this.story = this.props.App.storyGet({ label: "", bells: [] });
		let roles = [];
		if (this.story.bells)
			for (let b of this.story.bells) {
				roles.push(b["role"]);
			}
		let isAll = roles.every(v => v === roles[0]);

		this.state = {
			label: this.props.App.storyGet().label,
			bells: this.story.bells,
			all: isAll,
			roleDisplayed: -1,
		};
	}

	changeAllField(value, field) {
		if (this.story.emails.length === 0)
			return this.props.App.alert(
				-1,
				Util.loc(
					"Aucune adresse mail pour ce contact, et donc impossible d'attribuer des droits"
				)
			);
		let contactEmails = this.story.emails;
		console.log(contactEmails);
		let myEmails = this.props.App.myEmails();
		console.log(myEmails);
		let intersection = myEmails.filter(x => contactEmails.includes(x));
		if (intersection.length > 0)
			return this.props.App.alert(
				-1,
				Util.loc(
					"Vous ne pouvez pas retirer vos propres droits d'administration, or ce contact utilise votre email"
				) +
					":\n" +
					intersection[0]
			);

		for (let b of this.state.bells) b[field] = value;

		let update = { bells: this.state.bells };

		this.props.App.storyUpdate(update);
		this.props.App.storyProcess(
			result => {
				console.log("done");
			},
			err => {
				this.props.App.go(Util.CONTACTS);
			}
		);
	}

	changeField(bell, value, field) {
		if (this.story.emails.length === 0)
			return this.props.App.alert(
				-1,
				Util.loc(
					"Aucune adresse mail pour ce contact, et donc impossible d'attribuer des droits"
				)
			);
		let contactEmails = this.story.emails;
		console.log(contactEmails);
		let myEmails = this.props.App.myEmails();
		console.log(myEmails);
		let intersection = myEmails.filter(x => contactEmails.includes(x));
		if (intersection.length > 0)
			return this.props.App.alert(
				-1,
				Util.loc(
					"Vous ne pouvez pas retirer vos propres droits d'administration, or ce contact utilise votre email"
				) +
					":\n" +
					intersection[0]
			);

		bell[field] = value;

		let update = { bells: this.state.bells };

		this.props.App.storyUpdate(update);
		this.props.App.storyProcess(
			result => {
				console.log("done");
			},
			err => {
				this.props.App.go(Util.CONTACT_EDIT);
			}
		);
	}
	renderBellsPrivileges() {
		const bells = this.state.bells;

		return (
			<React.Fragment>
				<p className="col-12 text-left contact-droits-info">
					{Util.fillStr(
						Util.loc(
							"Je gère les autorisations de [nom] pour chacun de mes SPOORS"
						),
						{
							nom: this.state.label,
						}
					)}
				</p>
				<div className="col-12 text-left">
					<span className="toggle-att">
						{Util.loc("Attribution par SPOORS")}
					</span>

					<div
						className="custom-control custom-switch role-switch"
						style={{ float: "right" }}
					>
						<input
							type="checkbox"
							className="custom-control-input"
							id="attribution-SPOORS"
							checked={!this.state.all}
							onChange={() => {
								this.setState({
									all: true,
								});
							}}
						/>
						<label
							className="custom-control-label text-left"
							htmlFor={"attribution-SPOORS"}
						>
							&nbsp;
						</label>
					</div>
				</div>

				{bells.map((elem, index) => {
					return (
						<div className="col-12 my-4 text-center" key={index}>
							<div className={"card role-bg-building"}>
								<div className="card-body">
									<label className="role-card-title">
										{Util.bellTitle(elem)}
									</label>
								</div>
							</div>
							<div
								className="role-liste-row"
								onClick={() =>
									this.state.roleDisplayed === index
										? this.setState({ roleDisplayed: -1 })
										: this.setState({
												roleDisplayed: index,
										  })
								}
							>
								<label className="role-label">
									{elem.role === Util.ROLE_OWNER
										? Util.loc("Administrateur")
										: elem.role === Util.ROLE_USER
										? Util.loc("Utilisateur")
										: Util.loc("Restreint")}
								</label>
								<div>
									<img
										src={
											this.state.roleDisplayed === index
												? ArrowDown
												: ArrowUp
										}
										alt=""
									/>
								</div>
							</div>
							{this.state.roleDisplayed === index && (
								<div
									className="role-attr"
									style={{
										marginBottom: 15,
									}}
								>
									<div className="col-12 role-form-check">
										<input
											type="radio"
											className="form-check-input"
											id={"contact-" + index + "-admin"}
											name={"role" + index}
											checked={
												elem.role === Util.ROLE_OWNER
											}
											onChange={() =>
												this.changeField(
													elem,
													Util.ROLE_OWNER,
													"role",
													index
												)
											}
										/>
										<label
											className="form-check-label adm"
											htmlFor={
												"contact-" + index + "-admin"
											}
										>
											{Util.loc("administrateur")}
										</label>
										<div>
											<img
												src={
													elem.role ===
													Util.ROLE_OWNER
														? IcoValid
														: null
												}
												alt=""
											/>
										</div>
										{elem.role === Util.ROLE_OWNER && (
											<p>
												{Util.loc(
													"Mon contact a les mêmes droits que moi : il peut gérer les alertes et résilier le SPOORS. Pour activer ce droit, il doit créer un compte avec l'email que je lui ai associé."
												)}
											</p>
										)}
									</div>
									<hr></hr>
									<div className="col-12 role-form-check">
										<input
											type="radio"
											className="form-check-input"
											name={"role" + index}
											id={"contact-" + index + "-user"}
											checked={
												elem.role === Util.ROLE_USER
											}
											onChange={() => {
												this.changeField(
													elem,
													Util.ROLE_USER,
													"role",
													index
												);
											}}
										/>
										<label
											className="form-check-label utl"
											htmlFor={
												"contact-" + index + "-user"
											}
										>
											{Util.loc("utilisateur")}
										</label>
										<div>
											<img
												src={
													elem.role === Util.ROLE_USER
														? IcoValid
														: null
												}
												alt=""
											/>
										</div>
										{elem.role === Util.ROLE_USER && (
											<p>
												{Util.loc(
													"Mon contact peut seulement rediriger les alertes sur son téléphone en scannant le SPOORS (par exemple dans la cadre d’une vignette auto lorsqu’il emprunte mon véhicule). Pour activer ce droit, il doit créer un compte avec son téléphone et avec l'email que je lui ai associé."
												)}
											</p>
										)}
									</div>
									<hr></hr>
									<div className="col-12 role-form-check">
										<input
											type="radio"
											className="form-check-input"
											id={
												"contact-" +
												index +
												"-restreint"
											}
											name={"role" + index}
											checked={
												elem.role === Util.ROLE_NONE
											}
											onChange={() => {
												this.changeField(
													elem,
													Util.ROLE_NONE,
													"role",
													index
												);
											}}
										/>
										<label
											className="form-check-label rst"
											htmlFor={
												"contact-" +
												index +
												"-restreint"
											}
										>
											{Util.loc("restreint")}
										</label>
										<div>
											<img
												src={
													elem.role === Util.ROLE_NONE
														? IcoValid
														: null
												}
												alt=""
											/>
										</div>
										{elem.role === Util.ROLE_NONE && (
											<p>
												{Util.loc(
													"Mon contact peut uniquement recevoir des alertes mais n’a aucun droit de gestion sur mes SPOORS. Par exemple, mon voisin qui recevrait mes alertes pendant mes congés."
												)}
											</p>
										)}
									</div>
								</div>
							)}
						</div>
					);
				})}
			</React.Fragment>
		);
	}

	render() {
		const { App } = this.props;
		this.story = this.props.App.storyGet();
		let firstRole = this.state.bells[0]["role"];

		let roles = [];
		for (let b of this.story.bells) {
			roles.push(b["role"]);
		}
		let isAll = roles.every(v => v === roles[0]);
		console.log(isAll);

		return (
			<CenteredContainer id="page" className="screen-container">
				<div id="contact-roles" className="claimBackground">
					<TopMenu App={App} />
					<div className="row d-flex flex-row justify-content-center role-inputTop">
						<div className="col-12 col-md-10 col-lg-6">
							<div className="d-flex align-items-center justify-content-between padd20">
								<Link to={Util.CONTACT_EDIT}>
									<img src={ArrowLeft} alt="ArrowLeft" />
								</Link>
								<label className="form-control">
									{Util.loc("GESTION DES DROITS")}
								</label>
							</div>
						</div>
						<div className="w-100">
							<p className="input-top-name">{this.state.label}</p>
						</div>
					</div>

					<div id="contact-roles" className="row pb-block">
						<div className="col-12 col-sm-10 col-md-8 col-lg-6 text-center mx-auto">
							{this.state.all ? (
								<div className="col-12">
									<p className="text-left contact-droits-info">
										{Util.fillStr(
											Util.loc(
												"[nom] possède les droits suivants pour tous mes SPOORS"
											),
											{
												nom: this.state.label,
											}
										)}
									</p>
									<div className="m-auto needs-validation p-0">
										<div className="row mt-30 mb-2">
											<div
												className="col-12 num-attr"
												style={{
													marginBottom: 15,
												}}
											>
												<div className="form-check role-radio-groupe">
													<input
														type="radio"
														className="form-check-input"
														id={"contact-admin"}
														name={"role-admin"}
														checked={
															firstRole ===
																Util.ROLE_OWNER &&
															isAll
														}
														onChange={() => {
															this.changeAllField(
																Util.ROLE_OWNER,
																"role"
															);
														}}
													/>
													<label
														className="form-check-label"
														htmlFor={
															"contact-admin"
														}
													>
														{Util.loc(
															"administrateur"
														)}
													</label>
													<div>
														<img
															src={
																firstRole ===
																	Util.ROLE_OWNER &&
																isAll
																	? IcoValid
																	: null
															}
															alt=""
														/>
													</div>
												</div>
												<div className="role-description">
													<p>
														{Util.loc(
															"Mon contact a les mêmes droits que moi : il peut gérer les alertes et résilier le SPOORS. Pour activer ce droit, il doit créer un compte avec l'email que je lui ai associé."
														)}
													</p>
												</div>
												<div className="form-check role-radio-groupe">
													<input
														type="radio"
														className="form-check-input"
														id={"contact-user"}
														name={"role-user"}
														checked={
															firstRole ===
																Util.ROLE_USER &&
															isAll
														}
														onChange={() => {
															this.changeAllField(
																Util.ROLE_USER,
																"role"
															);
														}}
													/>
													<label
														className="form-check-label"
														htmlFor={"contact-user"}
													>
														{Util.loc(
															"utilisateur"
														)}
													</label>
													<div>
														<img
															src={
																firstRole ===
																	Util.ROLE_USER &&
																isAll
																	? IcoValid
																	: null
															}
															alt=""
														/>
													</div>
												</div>
												<div className="role-description">
													<p>
														{Util.loc(
															"Mon contact peut seulement rediriger les alertes sur son téléphone en scannant le SPOORS (par exemple dans la cadre d’une vignette auto lorsqu’il emprunte mon véhicule). Pour activer ce droit, il doit créer un compte avec son téléphone et avec l'email que je lui ai associé."
														)}
													</p>
												</div>
												<div className="form-check role-radio-groupe">
													<input
														type="radio"
														className="form-check-input"
														id={"contact-restreint"}
														name={"role-restreint"}
														checked={
															firstRole ===
																Util.ROLE_NONE &&
															isAll
														}
														onChange={() => {
															this.changeAllField(
																Util.ROLE_NONE,
																"role"
															);
														}}
													/>
													<label
														className="form-check-label"
														htmlFor={
															"contact-restreint"
														}
													>
														{Util.loc("restreint")}
													</label>
													<div>
														<img
															src={
																firstRole ===
																	Util.ROLE_NONE &&
																isAll
																	? IcoValid
																	: null
															}
															alt=""
														/>
													</div>
												</div>
												<div className="role-description">
													<p>
														{Util.loc(
															"Mon contact peut uniquement recevoir des alertes mais n’a aucun droit de gestion sur mes SPOORS. Par exemple, mon voisin qui recevrait mes alertes pendant mes congés."
														)}
													</p>
												</div>
											</div>
											<div className="col-12 text-left">
												<span className="toggle-att-off">
													{Util.loc(
														"Attribution par SPOORS"
													)}
												</span>

												<div
													className="custom-control custom-switch role-switch"
													style={{ float: "right" }}
												>
													<input
														type="checkbox"
														className="custom-control-input"
														id="attribution-SPOORS"
														checked={
															!this.state.all
														}
														onChange={() => {
															this.setState({
																all: false,
															});
														}}
													/>
													<label
														className="custom-control-label text-left"
														htmlFor={
															"attribution-SPOORS"
														}
													>
														&nbsp;
													</label>
												</div>
											</div>
										</div>
									</div>
								</div>
							) : (
								this.renderBellsPrivileges()
							)}
						</div>
					</div>
				</div>
				<BottomPage />
			</CenteredContainer>
		);
	}
}

ContactRole.propTypes = {
	App: Proptypes.object.isRequired,
};

export default ContactRole;
