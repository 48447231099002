import React from "react";
import Util from "../lib/Util";

const ModalBellAlert = ({ onClick }) => {
	return (
		<div id="ModaleStep">
			<div className="modal-step-content">
				<p className="mt-4 mb-4 big-text">
					{Util.loc("REDIRECTION PROVISOIRE")}
				</p>
				<p>
					{Util.loc(
						"La redirection provisoire des alertes est utile lorsqu’un SPOORS est partagé. Par exemple, si vous avez une vignette véhicule SPOORS, vous pouvez provisoirement recevoir les alertes à la place du conducteur principal lorsque vous empruntez son véhicule."
					)}
				</p>
				<button className="blueOk-bg" onClick={onClick}>
					{Util.loc("J'ai compris")}
				</button>
			</div>
		</div>
	);
};

export default ModalBellAlert;
