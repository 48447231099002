import React from "react";
import Proptypes from "prop-types";

import Util from "./lib/Util";
import { customHistory } from "./index";
import IcoGreat from "./style/img/ico-great.png";

import Page from "./Components/Page";
import Button from "./Components/Button";

class BellRedirectDone extends React.Component {
	constructor(props) {
		super(props);
		this.props.App.storyNeed("/BELL_USER", Util.HOME);
	}
	next() {
		this.props.App.storyUpdate({ url: "/VISIT_AUTO" });
		this.props.App.go("/VISIT_AUTO");
	}

	render() {
		const { App } = this.props;

		return (
			<Page App={App} title={Util.loc("Félicitations !")}>
				<div id="bell-redirect-done" style={{ height: "500px" }}>
					<div className="row" style={{ marginBottom: "20px" }}>
						<div className="col-12 col-sm-10 col-md-8 col-lg-6 m-auto text-center">
							<div className="row mt-5">
								<div className="ico-title m-auto">
									<img src={IcoGreat} alt="pouce" />
								</div>
								<div className="col-12 mx-auto mt-3">
									<p className="worksans-light">
										{Util.loc(
											"Mon alerte a bien été mise à jour."
										)}
									</p>
								</div>
								<div className="col-sm-8 col-10 mx-auto mt-5">
									<Button onClick={() => this.next()} enabled>
										{Util.loc("Continuer")}
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Page>
		);
	}
}

BellRedirectDone.propTypes = {
	App: Proptypes.object.isRequired,
};

export default BellRedirectDone;
