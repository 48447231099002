const Loc = {
	en: {
		"'[nom]' : envoi d'un SMS": "[nom]' : sending a SMS",
		"(à noter : l’email peut mettre plus d’une minute à arriver. Pensez aussi à vérifier dans votre courrier indésirable)":
			"(Please note: the email may take more than a minute to arrive. Also, remember to check your junk mail folder)",
		"+ d'informations": "see more",
		"+[nbUnits]\u00a0unité(s)": "+[nbUnits]\u00a0unit(s)",
		"- d'informations": "see less",
		"... recherche du SPOORS": "… searching the right SPOORS",
		"... vérification en cours ...": "… check in progress …",
		"/ [total] unités": "/ [total] units",
		"1 heure": "1 hour",
		"1 jour": "1 day",
		"1 sonnette SPOORS": "1 SPOORS bell",
		"1 vignette SPOORS": "1 SPOORS car sticker",
		"1. Une personne scanne mon QR code":
			"1. When someone scans my QR code",
		"2 jours": "2 days",
		"2. Réception de l’appel sur mon mobile\n":
			"2. I am contacted by phone call, SMS or email",
		"3 jours": "3 days",
		"4 heures": "4 hours",
		"85,6 mm x 53,9 mm": "85,6 mm x 53,9 mm",
		"85,6 mm x 85,6 mm": "85,6 mm x 85,6 mm",
		"<p>Si vous avez besoin d’aide, n’hésitez pas à nous contacter : <strong>toute l’équipe SPOORS se tient à votre disposition.<strong/></p>":
			"<p>If you need help, please don't hesitate to contact us : <strong>the entire SPOORS team is available to assist you.<strong/></p>",
		"<span><strong>1 Sonnette</strong><br /> connectée offerte</span>":
			"<span>1 offered<strong><br /> connected bell</strong></span>",
		"<span><strong>1 Vignette auto</strong><br /> connectée offerte</span>":
			"<span>1 offered<strong><br /> connected car sticker</strong></span>",
		"A noter": "To be noted",
		"A noter : dans mon espace privé, je pourrai ultérieurement rediriger les alertes vers une autre personne et paramétrer les horaires d’appel, si je le souhaite":
			"Note : in my private area, I can later redirect the alerts to another person and set the call times, if I wish",
		"ACHETER DES UNITES": "PURCHASE UNITS",
		ADRESSE: "ADDRESS",
		ALERTES: "ALERTS",
		Accueil: "Home",
		"Acheter des unités": "Purchase units",
		Administrateur: "Administrator",
		"Adresse *": "Address *",
		"Adresse internet du site": "Website address",
		"Agriculteurs exploitants": "Farmer",
		Agriculture: "Agriculture",
		"Ajouter un contact": "Add a contact",
		"Ajouter un email": "Add an email",
		"Ajouter un numéro": "Add a phone number",
		"Ajouter une alerte": "Add an alert",
		Alertes: "Alerts",
		Allemagne: "Germany",
		Annuler: "Cancel",
		Anonymisation: "Anonymization",
		"Anonymisation des appels et SMS": "Anonymization of calls and SMS",
		Août: "August",
		"Appel en cours": "Call in progress",
		Appeler: "Call",
		"Artisans, commerçants, chefs d'entreprise":
			"Craftsmen, merchants, business owners/CEO",
		"Assurez-vous du volume et du micro":
			"Make sure the volume and microphone are on",
		"Attention !": "Please note!",
		"Attribution par SPOORS": "Allocation by SPOORS",
		"Aucun scan à date": "No scan to date",
		"Aucune adresse mail pour ce contact, et donc impossible d'attribuer des droits":
			"No mail address for this contact, and therefore unable to assign rights",
		"Auto-entrepreneur, artisans": "Self-Employer, craftsmen",
		"Autre commerce de détail": "Other retail",
		"Autre numéro": "Other phone number",
		"Autres personnes sans activité professionnelle": "Unemployed",
		Autriche: "Austria",
		Avec: "With",
		"Avec ma sonnette, même si je suis absent, mes voisins pourront me prévenir d’un problème dans mon logement sans jamais voir mon numéro.":
			"With my bell, even if I am absent, my neighbors will be able to warn me of a problem in my dwelling without ever seeing my number.",
		"Avec mes SPOORS": "With my SPOORS",
		Avril: "April",
		Backoffice: "Backoffice",
		Belgique: "Belgium",
		"Bloquer cet interlocuteur": "Blacklist this contact person",
		"Bonjour [firstname],": "Hello [firstname],",
		Boulangeries: "Bakeries",
		"Bravo !": "Well done !",
		"Bravo ! Mon SPOORS est actif avec le réglage suivant\u00a0:":
			"Well done ! My SPOORS is active with the following setting\u00a0:",
		"C'est noté": "It is noted",
		COORDONNÉES: "CONTACT INFORMATION",
		"Cadres et professions intellectuelles supérieures":
			"Executives and higher intellectual professions",
		"Café-Hôtels-Restaurant": "Café-Hotels-Restaurant",
		"Carte en PVC": "PVC card",
		"Ces cookies nous aident à mesurer les modèles de trafic pour déterminer quelles zones de notre outil ont été visitées et pour mesurer les tendances. Cela nous permet de comprendre les habitudes d’un visiteur afin d’améliorer nos produits et services en ligne.":
			"These cookies help us measure traffic patterns to determine which areas of our tool have been visited and to measure trends. This allows us to understand a visitor’s habits in order to improve our online products and services.",
		"Chaque SMS consomme une unité de compte.":
			"Every SMS consumes an account’s unit.",
		"Cliquer sur le motif": "Click on the reason",
		"Cliquez sur le lien pour modifier les horaires.":
			"Click on the link to modify the schedules.",
		Collage: "Collage",
		Commander: "Order",
		"Comme porte-clef": "As a key ring",
		"Comment ça marche": "How it works",
		"Complément d'adresse": "Additional adress",
		"Compris !": "Got it!",
		"Conditions générales de vente": "General terms and conditions of sale",
		"Conditions particulières": "The related special terms and conditions",
		"Connecté :": "Logged in :",
		"Connecté : [delay] s": "Logged in : [delay] s",
		Connexion: "Login",
		"Connexion impossible ! Assurez-vous que vous n'êtes pas bloqué par un firewall d'entreprise":
			"Can't connect ! Make sure you are not blocked by a corporate firewall",
		"Connexion réussie !": "Successful login !",
		"Contactez notre équipe": "Contact our team",
		"Contactez-moi": "Contact me",
		"Contactez-nous": "Contact us",
		Continuer: "Continue",
		"Cookies statistiques": "Statistical cookies",
		"Cookies strictement nécessaires": "Strictly necessary cookies",
		"Corée du Sud": "South Korea",
		"DETAIL CONSO": "DETAILED CONSUMPTION",
		Danemark: "Denmark",
		"Dans mon espace privé, je pourrai ultérieurement rediriger les alertes vers une autre personne et paramétrer les horaires d'appel, si je le souhaite.":
			"In my private area, I can later redirect the alerts to another person and set the call times, if I wish.",
		"Dans mon habitat": "In my home",
		De: "From",
		Direction: "Direction/CEO",
		"Dos adhésif": "Adhesive back",
		"Droits de mes données": "Rights of my data",
		"DÉCONNEXION GLOBALE": "GLOBAL LOGOUT",
		"Dès réception, je scanne le QR code.":
			"Upon receipt, I scan the QR code.",
		Déc: "December",
		Décembre: "December",
		"Déconnexion globale": "Global logout",
		"Découvrez bien plus\u00a0!": "Find out lot more\u00a0!",
		"Détails conso": "Consumption details",
		"Détails de ma consommation": "Details of my consumption",
		EMAIL: "EMAIL",
		"Email *": "Email *",
		"Email :": "Email :",
		Employés: "Employees",
		"En cliquant sur un scan ci-dessous, je vois toutes les alertes adressées par ce même interlocuteur. Je peux alors le bloquer pour qu'il ne puisse me contacter que par email !":
			"By clicking on a scan below, I see all the alerts sent by the same contact person. I can then blacklist him so that he can only contact me by email!",
		"En poursuivant votre navigation, vous acceptez nos cookies destinés à l'affichage d'annonces liées à vos intérêts et à nos statistiques. Pour plus d'informations, vous pouvez consulter":
			"By continuing to browse, you agree to our cookies for the display of ads related to your interests and our statistics. For more information, see",
		"En recherche de votre correspondant...":
			"In search of your correspondent...",
		"En recherche de votre correspondant... Assurez-vous du volume et du micro":
			"Looking for your correspondent... Make sure the volume and microphone are on",
		"En semaine": "On weekdays",
		"En validant, j'accepte que mes coordonnées soient communiquées et conservées par mon correspondant.":
			"By validating, I agree that my contact details are communicated and kept by my correspondent.",
		Envoyer: "Send",
		"Envoyer aussi par sms": "Also send a SMS",
		"Envoyer un message": "Send a message",
		Espagne: "Spain",
		"Est-ce volontaire ?": "Is this voluntary?",
		"Etape 1 :": "Step 1 :",
		"Etape 2 :": "Step 2 :",
		"Etape 3 :": "Step 3 :",
		"Etes-vous sûr de vouloir supprimer ce SPOORS ?":
			"Are you sure you want to delete this SPOORS ?",
		"Etes-vous sûr de vouloir supprimer ce contact\u00a0? Cela supprimera les alertes et les droits qui lui sont associés":
			"Are you sure you want to delete this contact\u00a0? This will remove associated alerts and rights",
		"Etes-vous sûr de vouloir supprimer ce numéro ?":
			"Are you sure you want to delete this phone number ?",
		"Etes-vous sûr de vouloir supprimer cette adresse ?":
			"Are you sure you want to delete this address ?",
		"Etes-vous sûr de vouloir supprimer cette alerte ?":
			"Are you sure you want to delete this alert ?",
		"Etes-vous sûr de vouloir supprimer cette sonnette ?":
			"Are you sure you want to delete this bell ?",
		"Etes-vous sûr de vouloir supprimer cette vignette ?":
			"Are you sure you want to delete this sticker ?",
		"Etes-vous sûr de vouloir supprimer cette étiquette ?":
			"Are you sure you want to delete this tag ?",
		"Face avant adhésive": "Adhesive front side",
		"Faire un essai": "Do a test",
		Finlande: "Finland",
		Fonction: "Position/Function",
		Fonctionnement: "How SPOORS works",
		"Frais de livraison": "Delivery costs",
		France: "France",
		"Félicitations !": "Congratulation !",
		Fév: "February",
		Février: "February",
		"GESTION DES DROITS": "RIGHTS MANAGEMENT",
		"Garages, concessions automobiles": "Garages, car dealerships",
		"Gestion de ma consommation": "Consumption management",
		"Gestion des droits": "Rights management",
		"Go !": "Go !",
		"Grâce à la technologie brevetée SPOORS, je suis mis en relation sans que mes coordonnées ne soient dévoilées.":
			"Thanks to the patented SPOORS technology, I am contacted without my phone number or email being shown.",
		"Grâce à mon SPOORS, je serai joignable sans dévoiler mes coordonnées !":
			"Thanks to my SPOORS, I will be reachable without revealing my coordinates !",
		Grèce: "Greece",
		Guadeloupe: "Guadalupe",
		Guyane: "Guyana",
		Hongrie: "Hungary",
		"Il reste des erreurs sur cette page\n\nVoulez-vous vraiment la quitter ?\n\nEn cliquant 'oui' vos modifications seront perdues.":
			"There are still errors on this page\n\nAre you sure you want to quit?\n\nClicking 'yes' your changes will be lost.",
		"Ils sont indispensables au bon fonctionnement du site et permettent par exemple de garder votre session active pour vous évitez de vous reconnecter à chaque fois que vous visitez notre site.":
			"They are essential for the proper functioning of the site and allow for example to keep your session active so you avoid logging back in each time you visit our site.",
		Installation: "Device set up",
		"Installation en 3 minutes": "Installation in 3 minutes",
		Irlande: "Irland",
		Italie: "Italy",
		"J'ai compris": "I understood",
		"J'ai terminé": "I’m done",
		"JE GÈRE MES CONTACTS": "Contacts' management",
		"JE LE NOMME": "I NAME IT",
		Jamais: "Never",
		Jan: "January",
		Janvier: "January",
		"Je choisis comment je l'utilise": "I choose how I use it",
		"Je choisis l'ordre et les personnes à contacter lorsqu'un utilisateur scanne le SPOORS":
			"I choose the order and who to contact when a user scans the SPOORS",
		"Je colle mon SPOORS": "I stick my SPOORS",
		"Je colle mon SPOORS !": "I stick my SPOORS !",
		"Je colle mon SPOORS, il est opérationnel.": "This is now operational",
		"Je commande": "I order",
		"Je commande un SPOORS": "Order a SPOORS",
		"Je commence": "I start",
		"Je confirme mon choix": "I confirm my choice",
		"Je donne mes coordonnées pour activer mon SPOORS et être contacté si on scanne mon QR code. Mon numéro reste masqué !":
			"I give my contact information to activate my SPOORS and be contacted if my QR code is scanned. My number remains hidden !",
		"Je déclare avoir pris connaissance des":
			"I declare that I have read the",
		"Je déclare avoir pris connaissance des CGV et CGU et de la politique de confidentialité consultables":
			"I declare that I have read the GTCS and GTU and the searchable privacy policy",
		"Je découvre": "I find out",
		"Je déménage": "I’m moving out",
		"Je gère les autorisations de [nom] pour chacun de mes SPOORS":
			"I manage [nom]’s permissions for each of my SPOORS",
		"Je gère mes contacts": "I manage my contacts",
		"Je le nomme": "I name it",
		"Je me laisse guider et je personnalise les heures d’appels, le mode de contact et le numéro de téléphone.":
			"I let myself be guided by the instructions shown, in order to customize the contact modes and times.",
		"Je paramètre mon SPOORS en 2 étapoes !":
			"I configure my SPOORS in 2 steps !",
		"Je personnalise mes alertes": "I customize my alerts",
		"Je personnalise mon SPOORS pour adapter le message d’accueil, limiter les horaires d’appels ou modifier les destinataires des alertes.":
			"I customize my SPOORS to adapt the greeting message, limit call schedules or change the recipients of alerts.",
		"Je peux modifier le message que verra la personne qui scanne mon SPOORS.":
			"I can change the message the person scanning my SPOORS will see.",
		"Je recevrai ma commande sous un délai de 15 jours.":
			"I will receive my order within 15 days.",
		"Je renseigne mon numéro pour être contacté anonymement si mon QR code est scanné. Mon numéro ne sera pas dévoilé !":
			"I enter my number to be contacted anonymously if my QR code is scanned. My number will not be disclosed!",
		"Je saisis le code à 6 chiffres que je viens de recevoir par email à cette adresse :":
			"I enter the 6-digit code that I just received by email at this address:",
		"Je saisis mon email pour rattacher la sonnette à mon compte":
			"I enter my email to link the bell to my account",
		"Je scanne le QR code": "I scan the QR code",
		"Je scanne un QR code": "I’m scanning a QR code",
		"Je sélectionne le produit le plus adapté à mes besoins\u00a0!":
			"I choose the product that best suits my needs\u00a0!",
		"Je sélectionne les partenaires pour lesquels j’accepte que SPOORS transmette mes données afin de qualifier leurs bases clients visant à des actions non commerciales.":
			"I select the partners for whom I agree that SPOORS will transmit my data in order to qualify their customer databases for non-commercial actions.",
		"Je sélectionne les partenaires pour lesquels j’accepte que SPOORS transmette mes données afin de recevoir des recommandations adaptées à ma situation":
			"I select the partners for whom I agree to have SPOORS transmit my data in order to receive recommendations adapted to my situation",
		"Je valide": "I confirm",
		"Je veux rattacher ce SPOORS au compte\u00a0:":
			"I want to attach this SPOORS to the account\u00a0:",
		"Je veux rediriger provisoirement les alertes pendant :":
			"I want to temporarily redirect alerts during :",
		"Je viens de scanner ma sonnette.": "I just scanned my bell.",
		"Je viens de scanner ma vignette.": "I just scanned my sticker.",
		"Je viens de scanner mon SPOORS.": "I just scanned my SPOORS.",
		"Je viens de scanner mon porte-clefs.": "I just scanned my keychain.",
		"Je viens de scanner mon étiquette.": "I just scanned my tag.",
		"Je visualise ma commande": "I check my order",
		"Je vérifie mes coordonnées de contact et si besoin je les modifie.":
			"I check my contact details and if necessary I modify them.",
		"Joignable par email ou appel téléphonique, selon l’heure de la journée et en fonction de mes choix. Je peux aussi déléguer l’alerte à un(e) ami(e).":
			"Can be contacted by email or phone (call, sms), depending on the time of day and according to my choices. I can also delegate the alert to a friend.",
		Juil: "July",
		Juillet: "July",
		Juin: "June",
		"L'anonymisation des appels et des SMS permet de masquer votre numéro de téléphone. Pour utiliser ce service, vous devez disposer d'unités disponibles. Votre solde actuel est de [count] unité(s).":
			"Call and SMS anonymization hides your phone number. To use this service, you must have units available. Your current balance is [count] unit(s).",
		"L'objet de ma demande *": "The reason of my request *",
		"La meilleure façon de se faire un avis, c’est d’essayer <br/>C’est par ici !":
			"The best way to form an opinion is to try it.<br/>click here.",
		"La redirection provisoire des alertes est utile lorsqu’un SPOORS est partagé. Par exemple, si vous avez une vignette véhicule SPOORS, vous pouvez provisoirement recevoir les alertes à la place du conducteur principal lorsque vous empruntez son véhicule.":
			"Temporary redirection of alerts is useful when a SPOORS is shared. For example, if you have a SPOORS vehicle sticker, you can temporarily receive alerts instead of the primary driver when you borrow his vehicle.",
		"La vignette permettra de me contacter sans voir mon numéro de téléphone, si je suis mal garé ou que mon véhicule est percuté sur un parking !":
			"The sticker will allow you to contact me without seeing my phone number, if I am parked incorrectly or if my vehicle is hit on a parking lot !",
		"Le code à 6 chiffres": "The 6-digit code",
		"Le problème est en cours de résolution !":
			"The problem is being solved!",
		"Le syndic a déjà été prévenu et gère le problème dans les meilleurs délais.":
			"The syndic has already been notified and is handling the problem as soon as possible.",
		"Le type de mon objet *": "The type of my object *",
		"Le week-end": "The weekend",
		"Lecteur QR code": "QR code reader",
		"Lecteur QrCode": "QrCode reader",
		"Les atouts": "Benefits",
		"Les atouts de notre solution": "The benefits of our solution",
		"Les frais d’expédition couvrent la préparation de la commande, la mise sous pli et l’affranchissement":
			"Shipping costs cover order preparation, packing and postage",
		"Les unités permettent d’anonymiser les appels et SMS, un SMS ou une minute d’appel anonyme consommant une unité.":
			"These units allow me to hide my phone number for calls or SMS. One unit is consumed for every anonymous SMS sent or every minute of anonymous call.",
		"Les unités sont utilisées pour envoyer des SMS et recevoir des appels anonymisés. 1\u00a0SMS consomme une unité. 1\u00a0mn d’appel anonyme consomme une unité.":
			"The units are used to hide your phone number for calls or SMS. 1\u00a0SMS consumes one unit. 1\u00a0mn of anonymous call consumes one unit.",
		"Lorsque votre solde d'unités sera épuisé, vous pourrez recharger votre compte":
			"When your unit balance is empty, you can recharge it",
		Luxembourg: "Luxemburg",
		"L’étiquette permet de me contacter facilement et anonymement.":
			"The tag allows you to contact me easily and anonymously.",
		"M'alerter par :": "Notify me by :",
		"MES FACTURES": "MY INVOICES",
		"MESSAGE DE BIENVENUE": "WELCOME MESSAGE",
		"MON PROFIL": "MY PROFILE",
		"MON TABLEAU DE BORD": "MY DASHBOARD",
		"Ma commande": "My order",
		"Ma commande inclut": "My order include",
		"Ma consommation": "My consumption",
		"Ma consommation (Appels anonymes et envois de SMS)":
			"My consumption (Anonymous calls and SMS)",
		"Ma situation personnelle": "My personnal situation",
		Mai: "May",
		"Maintenant, je paramètre mon SPOORS en 2 étapes.":
			"Now I set my SPOORS in 2 steps.",
		Marketing: "Marketing",
		Maroc: "Marocco",
		Mars: "March",
		Martinique: "Martinica",
		"Matière carte": "Card material",
		"Mayotte ou La Réunion": "Mayotte or La Réunion",
		"Me connecter": "Login",
		"Merci !": "Thank you !",
		"Merci de votre alerte.": "Thank you for the alert.",
		"Mes SPOORS": "My SPOORS",
		"Mes coordonnées ne sont pas dévoilées. Mon anonymat est préservé.":
			"My contact information is not disclosed. My anonymity is preserved",
		"Mes factures": "My invoices",
		"Mes informations": "My details",
		"Message *": "Message *",
		"Microphone inaccessible": "Microphone inaccessible",
		Modifier: "Modify",
		"Mon Email *": "My Email *",
		"Mon adresse": "My address",
		"Mon alerte a bien été mise à jour.":
			"My alert has been successfuly updated.",
		"Mon contact a les mêmes droits que moi : il peut gérer les alertes et résilier le SPOORS. Pour activer ce droit, il doit créer un compte avec l'email que je lui ai associé.":
			"My contact has the same rights as me : he can manage alerts and erase the SPOORS – to activate this right, they must create an account and an email is mandatory.",
		"Mon contact peut seulement rediriger les alertes sur son téléphone en scannant le SPOORS (par exemple dans la cadre d’une vignette auto lorsqu’il emprunte mon véhicule). Pour activer ce droit, il doit créer un compte avec son téléphone et avec l'email que je lui ai associé.":
			"My contact can only redirect alerts to his phone by scanning the SPOORS (for instance, when he borrows my car). To activate this right, he has to create an account with his phone and with the email I associated to him.",
		"Mon contact peut uniquement recevoir des alertes mais n’a aucun droit de gestion sur mes SPOORS. Par exemple, mon voisin qui recevrait mes alertes pendant mes congés.":
			"My contact can only receive alerts but has no management rights on my SPOORS. For example, my neighbour who would receive my alerts during my holidays",
		"Mon email": "My email",
		"Mon email *": "My email *",
		"Mon entreprise": "My company",
		"Mon identité": "My identity",
		"Mon interlocuteur scanne le code avec son appareil photo ou un lecteur de QR code. Il peut me contacter ou demander à être rappelé sans avoir à charger d’application.":
			"Someone scans the QR code with its camera. He can call me, send a message or asks for being called back. It doesn't required to download any application.",
		"Mon message": "My message",
		"Mon nom *": "My name *",
		"Mon numéro de téléphone (facultatif)": "My phone number (optional)",
		"Mon numéro n'apparaît pas": "My number is not shown",
		"Mon profil": "My profile",
		"Mon tableau de bord me permet de gérer la totalité de mes SPOORS.":
			"My dashboard allows me to manage all of my SPOORS.",
		"Mot de passe": "Password",
		NOM: "LAST NAME",
		Navigation: "Navigation",
		"Nom *": "Last name *",
		"Nom du SPOORS *": "SPOORS’ name *",
		"Nom du contact": "Contact Name",
		"Nom du nouveau contact": "New contact Name",
		Non: "No",
		Norvège: "Norway",
		"Nos partenaires": "Our partners",
		"Nos produits": "Products",
		"Nous contacter": "Contact us",
		"Nous venons de prévenir votre correspondant.":
			"We just notified your correspondent.",
		Nov: "Novembre",
		Novembre: "November",
		"Numéro de téléphone": "Phone number",
		Oct: "October",
		Octobre: "October",
		Ok: "Ok",
		"Option 1 :": "Option 1 :",
		"Option 2 :": "Option 2 :",
		Oui: "Yes",
		"Oups, je me suis trompé": "Oops, I made a mistake",
		Ouvriers: "Workers",
		PRÉNOM: "FIRST NAME",
		"Par SMS": "By SMS",
		"Par email": "By email",
		"Par mail": "By email",
		"Par sms": "By sms",
		"Par téléphone": "By phone",
		"Pas d'email enregistré": "No registered email",
		"Pas de numéro de téléphone enregistré": "No registered phone number",
		"Pas de numéro enregistré": "No registered number",
		"Pas de piles, pas besoin de connexion à internet : je colle mon SPOORS de manière visible et je n'ai plus à m'en préoccuper.":
			"No battery. No internet connection needed. I stick my SPOORS and I no longer worry about it.",
		"Pays-bas": "Netherlands",
		"Perdu\u00a0? Retrouvé\u00a0!": "Lost\u00a0? Found\u00a0!",
		Personnalisation: "Custumization",
		"Personnaliser mon SPOORS": "Customize my SPOORS",
		"Plage horaire :": "Time slot:",
		"Politique d'envoi des sms": "SMS sending policy",
		Pologne: "Poland",
		Portugal: "Portugal",
		"Pour activer mon SPOORS": "To activate my SPOORS",
		"Pour cela, il suffit de": "By clicking",
		"Pour connaître vos droits sur les données personnelles que vous nous confiez, consultez notre politique de confidentialité":
			"To know your rights regarding the personal data you entrust to us, please consult our privacy policy on",
		"Pour l'extérieur": "For outdoor",
		"Pour le pare-brise de mon véhicule et les surfaces vitrées":
			"For my vehicle's windshield and glass surfaces",
		"Pour m'assurer que je n'ai pas laissé quelque part un navigateur connecté à mon compte, je peux forcer leur déconnexion en révoquant leur autorisation:":
			"To make sure I haven’t left a browser connected to my account somewhere, I can force them to log out by revoking their permission:",
		"Pour ma boite aux lettres, mon portail ou ma porte d’entrée":
			"For my mailbox, my gate or my front door",
		"Pour mon animal": "For my pet",
		"Pour mon immeuble": "For my apartment building",
		"Pour recharger votre compte, c'est": "To recharge your account, click",
		"Pour visualiser de quels partenaires de SPOORS j'accepte de recevoir par email les propositions :":
			"To view which SPOORS’ partners I agree to receive proposals by email :",
		"Pour visualiser à quels partnenaires de SPOORS mes informations sont transférées pour mettre à jour les données clients :":
			"To view/see which SPOORS’ partners my information is transferred to update customer data:",
		"Prix à l'unité :": "Unit price",
		"Profession libérale, Santé": "Liberal Profession, Health",
		"Professions intermédiaires": "Itermediate professions",
		"Profitez de l'offre !": "Take advantage of the offer!",
		Précédent: "Previous",
		"Prénom *": "First name *",
		"Qu'est-ce que SPOORS ?": "What is SPOORS ?",
		"Quantité :": "Quantity",
		"Questions fréquentes": "Frequently asked questions",
		REDIRECTION: "REDIRECTION",
		"REDIRECTION PROVISOIRE": "TEMPORARY REDIRECTION",
		Raccrocher: "Hang up",
		"Raison sociale *": "company name *",
		Rattachement: "Attachment",
		"Rediriger les alertes": "Redirect alerts",
		"Renvoyer le code par email": "Resend the code by emai",
		Restreint: "Restricted",
		"Retour à l'accueil": "Back to home",
		Retraités: "Retirees",
		Roumanie: "Romania",
		"Royaume-Uni": "United Kingdom",
		Récapitulatif: "Summary",
		"Résilier ce SPOORS": "Cancel this SPOORS",
		"Révocation accomplie !\n \nSeul ce navigateur est désormais connecté à votre compte.":
			"Revocation completed !\n \nOnly this browser is now logged into your account.",
		SCANS: "SCANS",
		"SCANS SELECTIONNES": "SELECTED SCANS",
		SIRET: "REGISTRATION NUMBER",
		"SMS :": "SMS :",
		SPOORS: "SPOORS",
		"SPOORS : toujours joignable sans dévoiler mes coordonnées":
			"SPOORS: always reachable without revealing my contact information",
		"SPOORS activé !": "SPOORS activated !",
		"SPOORS est un écosystème vivant ...": "SPOORS is a living ecosystem …",
		"SPOORS est un écosystème vivant, et nous sommes toujours prêts à trouver un support original ou surmesure répondant à vos besoins, ou à intégrer des services enrichissant notre offre.":
			"SPOORS is a living ecosystem, and we are always ready to find original or tailored support that meets your needs, or to integrate services that enrich our offer.",
		"SPOORS est une entreprise intégrée au startup studio corporate Wefound.":
			"SPOORS is an integrated company in the corporate startup studio Wefound.",
		"SPOORS résilié: appel de [nbUnits]\u00a0minute(s)":
			"SPOORS terminated: call of [nbUnits]\u00a0minute(s)",
		"SPOORS résilié: envoi d'un SMS": "SPOORS cancelled: send an SMS",
		"SPOORS s’est donné comme mission de vous rendre accessible de n’importe où et de manière anonyme, grâce à une technologie brevetée.":
			"SPOORS' mission is to make you accessible from anywhere and anonymously, thanks to patented technology.",
		"SPOORS:": "SPOORS:",
		"Salle Hébergement, de spectacle": "Accommodation Room, entertainment",
		Scannez: "Scan",
		"Scannez mon SPOORS pour me contacter sans voir mon numero\u00a0!":
			"Scan my SPOORS to contact me without seeing my number\u00a0!",
		"Se connecter": "Login",
		"Se déconnecter": "Logout",
		Sept: "September",
		Septembre: "September",
		"Serveur inaccessible": "Server inaccessible",
		"Serveur injoignable": "Unreachable server",
		"Seules les unités disponibles sont consommées !":
			"Only available units are consumed!",
		"Si je ne dispose pas d'unités pour anonymiser mes appels et que je ne souhaite pas en racheter, j'ai le choix entre deux options.":
			"If I don’t want to buy additional units, I have got both options below:",
		"Si je perds mes clés, on pourra me contacter d’un simple scan sans voir mon numéro de téléphone.":
			"If I lose my keys, I can be contacted by a simple scan without seeing my phone number.",
		"Si je perds un objet, on peut d’un simple scan me contacter sans voir mon numéro.":
			"If I lose an object, I can be contacted with a simple scan without seeing my number.",
		"Si vous le souhaitez, vous pouvez contacter notre délégué à la protection des données via l'adresse e-mail : contact@spoors.fr":
			"If you wish, you can contact our Data Protection Officer via the e-mail address: contact@spoors.fr",
		"Si vous ne souhaitez pas en racheter, vous avez le choix entre deux options :":
			"If you don't want to buy more, you have two options:",
		"Si vous êtes une entreprise, nous sommes à votre écoute pour intégrer SPOORS à vos process ou gammes d’offres.":
			"If you are a company, we are at your disposal to integrate SPOORS into your processes or offer ranges.",
		"Solde faible : vous devriez\u00a0recharger\u00a0!":
			"Low balance : you should\u00a0reload\u00a0!",
		"Sonnette Connectée : être prévenu en cas de dégât des eaux":
			"Connected bell : be warned in case of water damage",
		Suisse: "Switzerland",
		Suivant: "Next",
		Supprimer: "Delete",
		"Supprimer [nom]": "Delete [nom]",
		"Sur ma voiture": "On my car",
		"Sur mon objet": "On my object",
		"Sur un bagage": "On a luggage",
		"Sur un box": "On a box",
		"Sur un van (équitation)": "On a van (horseback riding)",
		"Sur un vêtement": "On a piece of clothing",
		"Sur une peluche": "On a toy",
		"Surfaces vitrées": "Glass surfaces",
		Suède: "Sweden",
		TEL: "PHONE NUMBER",
		TPI: "ICTY",
		"Tableau de bord": "Dashboard",
		Taille: "Size",
		"Taille maximale atteinte": "Maximum size reached",
		Technique: "Technical",
		"Tertiaire, bureaux": "Tertiary, offices",
		Total: "Total",
		"Toujours joignable": "Always reachable",
		"Toujours joignable sans divulguer mes coordonnées ! la Sonnette pour être prévenu en cas de dégât des eaux ; la Vignette pour être alerté si mon véhicule gêne la circulation.\n":
			"Always reachable without disclosing my details ! the Bell to be warned in case of water damage; the Sticker to be alerted if my vehicle hinders trafic.\n",
		"Tous les jours": "Every day",
		"Tous les scans ci-dessous proviennent du même téléphone. Je peux bloquer cet interlocuteur : il ne pourra alors me contacter que par email.":
			"All the scans below come from the same phone. I can block this contact person: he can only contact me by email.",
		"Téléphone *": "Phone number *",
		"Téléphone :": "Phone number :",
		UNITES: "UNITS",
		USA: "USA",
		"Un SMS sera envoyé chaque fois que l’utilisateur qui scanne votre QR code décide d’envoyer un message. Tous les contacts pour lesquels le canal SMS est activé recevront simultanément un SMS.":
			"A SMS will be sent whenever the user scanning your QR code decides to send a message. All contacts for which the SMS channel is enabled will simultaneously receive an SMS.",
		"Un colis à ne pas\u00a0manquer\u00a0?": "Never miss any package !",
		"Un numéro pour me joindre *": "A number to reach me *",
		"Une installation simple et rapide. Il suffit de coller mon SPOORS et de le scanner une première fois pour le paramétrer.\n":
			"Quick and easy installation. Just stick my SPOORS and set it up by scanning it for the first time.\n",
		"Unités SMS et appels anonymes": "SMS and anonymous calls",
		Url: "Url",
		Utilisateur: "User",
		"Utiliser comme un visiteur": "Use as a visitor",
		"Valide jusqu'au [date]": "Valid until [date]",
		Valider: "Confirm",
		"Veuillez autoriser l'utilisation du microphone pour le site spoors.fr!":
			"Please allow the use of the microphone for the spoors.fr website !",
		"Veuillez remplir ce champ": "Please fill in this field",
		"Veuillez remplir ce champ correctement":
			"Please complete this field properly",
		"Veuillez saisir le nom du nouveau contact":
			"Please enter the name of the new contact",
		"Veuillez saisir un numéro": "Please, enter a phone number",
		"Veuillez saisir un numéro valide":
			"Please, enter a valid phone number",
		Vitrophanie: "Vitrophanie",
		"Voir +": "See +",
		"Voir mon site web": "Visit my website",
		"Votre accord sur l'utilisation des cookies.":
			"Your agreement on the use of cookies.",
		"Votre adresse mail est maintenant validée":
			"Your email address is now validated",
		"Votre correspondant n'est actuellement joignable que par mail. Nous allons le prévenir, mais voulez-vous lui laisser vos coordonnées ou un message\u00a0?":
			"Your correspondent is currently only reachable by email. We will notify him, but would you like to leave your contact details or a message\u00a0?",
		"Votre message a bien été envoyé":
			"Your message has been successfuly sent",
		"Votre numéro de téléphone sera masqué.":
			"Your phone number will be hidden.",
		"Voulez-vous vraiment vous déconnecter ?":
			"Do you really want to logout ?",
		"Vous avez une idée pour un nouveau SPOORS ?":
			"You have an idea for a new SPOORS ?",
		"Vous avez une question ?": "Do you have any question ?",
		"Vous devez commander au moins une sonnette ou vignette !":
			"You must order at least one bell or sticker !",
		"Vous disposez actuellement de [count] unité(s) pour anonymiser vos alertes, sachant qu'un SMS ou une minute d'appel conssoment une unité. À noter : seules les unités disponibles sont consommées !":
			"You currently have [count] unit(s) to anonymize your alerts, knowing that one SMS or one minute of call consumes one unit. Note: only available units are consumed!",
		"Vous n'avez pas de facture": "You do not have an invoice",
		"Vous n'avez pas renseigné de numéro de téléphone.":
			"You have not entered a phone number.",
		"Vous ne pourrez donc ni être appelé ni recevoir de SMS lorsqu'une personne scanne votre SPOORS.":
			"You won't be able to be called or to receive SMS when someone scans your SPOORS .",
		"Vous ne pouvez pas retirer vos propres droits d'administration, or ce contact utilise votre email":
			"You cannot remove your own administrative rights, or this contact uses your email",
		"Vous êtes curieux ?": "Are you curious ?",
		"Vous êtes libre d'activer et de désactiver à tout moment les familles de cookies suivantes:":
			"You are free to enable and disable the following families of cookies at any time:",
		"Vous êtes une entreprise et souhaitez intégrer SPOORS à votre offre ?":
			"You are a company and want to integrate SPOORS into your offer ?",
		"[nbUnits]\u00a0unité(s)": "[nbUnits]\u00a0unit(s)",
		"[nom] possède les droits suivants pour tous mes SPOORS":
			"[nom] has the following rights for all my SPOORS",
		"[nom]: appel de [nbUnits]\u00a0minute(s)":
			"nom]: call duration of [nbUnits]\u00a0minute(s)",
		"achat d'unité(s)": "Buy Units",
		administrateur: "Administrator",
		autre: "other",
		"carte de transport": "transport card",
		"carte d’identité": "ID card",
		"cliquer ici !": "here !",
		"d'unités": "units",
		"de SPOORS": "0",
		"de SPOORS pour mettre à jour les données clients":
			"to update customer data",
		"de [from] à [to]": "from [from] to [to]",
		dimanche: "sunday",
		email: "email",
		"et d'utilisation de SPOORS, des": "and use of SPOORS,",
		"et les accepter sans réserve.": "and accept them without reservation.",
		ici: "here",
		"j'accepte de recevoir par email les propositions des":
			"I agree to receive by email the proposals of (the)",
		"j'accepte que mes informations soient transférées à des":
			"I agree that my information will be transferred to",
		"je clique ici": "I click here",
		"je continue de recevoir des alertes par téléphone ou SMS, mais mon numéro sera affiché.":
			"I will be able to received alerts by phone (sms or call), but my phone number will be displayed.",
		"je lève l’anonymat :": "I agree to have my phone number disclosed",
		"je reste anonyme :": "I want to keep my phone number undisclosed",
		"je reçois uniquement des alertes par emails.":
			"I will be able to receive emails only (I will not be contacted by phone)",
		"je suis joignable depuis n'importe quel objet sans dévoiler mon numéro":
			"I can be contacted from any object without showing my number",
		"je valide": "I confirm",
		jeudi: "thursday",
		"lever l’anonymat :": "lift anonymity:",
		lundi: "monday",
		mardi: "tuesday",
		mercredi: "wednesday",
		"mes coordonnées": "my details",
		moi: "me",
		"mon SPOORS": "my SPOORS",
		"mon compte": "my account",
		non: "no",
		"non défini": "undefined",
		"non merci": "no thank you",
		"notre lecteur de QR code": "our QR code reader",
		"notre politique de cookies": "our cookie policy",
		offert: "offered",
		"ordinateur portable": "laptop",
		"ou directement depuis l’application “Caméra” de votre smartphone.":
			"or with the camera of my smartphone",
		partenaires: "SPOORS’ partners",
		passeport: "passport",
		payé: "paid",
		"permis de conduire": "driver's license",
		"que voulez-vous faire avec votre SPOORS ?":
			"what do you want to do with your SPOORS?",
		"raison sociale *": "company name *",
		recherche: "research",
		"reste [count]": "remaining [count]",
		"rester anonyme :": "remain anonymous:",
		restreint: "Restricted",
		"saisir le code à 6 chiffres": "enter the 6-digit code",
		"saisir votre identifiant email": "enter your identifying email",
		samedi: "saturday",
		"sans voir mon numero": "whithout seeing my number",
		"secteur d’activité *": "sector of activity",
		smartphone: "smartphone",
		suivant: "next",
		"tableau de bord": "dashboard",
		"unités de bienvenue offertes": "free welcome units",
		"unités offertes lors de l'activation d'un SPOORS résilié":
			"units offered upon activation of a terminated SPOORS",
		"unités offertes lors de l'activation de '[nom]'":
			"units offered upon activation of '[nom]'.",
		unknown: "unknown",
		utilisateur: "user",
		valise: "suitcase",
		vendredi: "friday",
		"veuillez patienter": "please wait",
		"votre numéro de téléphone ne sera plus masqué pour cette alerte.":
			"your phone number will no longer be hidden for this alert.",
		"vous recevrez uniquement cette alerte par email.":
			"you will only receive this alert by email.",
		"y afférentes et de la Politique de confidentialité et les accepter sans réserve.":
			"and the privacy policy and that I accept them without reservation.",
		À: "To",
		"À propos": "About Us",
		"Échec de l'appel !": "The call failed !",
		Éditer: "Edit",
		"Être rappelé": "Get called back",
		à: "at",
		"à la page suivante": "on the next page",
	},
};
export default Loc;
