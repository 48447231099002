import React from "react";
import Util from "../lib/Util";

const ModalEmptyPhone = ({ app, type, notify }) => {
	const handleClickModal = ok => {
		notify(ok);
	};
	return (
		<div id="ModaleStep">
			<div className="modal-step-content">
				<p className="big-title">{Util.loc("Attention !")}</p>

				<p className="mt-4">
					{Util.loc(
						"Vous n'avez pas renseigné de numéro de téléphone."
					)}
				</p>
				<p className="mt-2" style={{ lineHeight: "1.5rem" }}>
					{Util.loc(
						"Vous ne pourrez donc ni être appelé ni recevoir de SMS lorsqu'une personne scanne votre SPOORS."
					)}
				</p>
				<p className="mt-2" style={{ lineHeight: "1.5rem" }}>
					{Util.loc("Est-ce volontaire ?")}
				</p>
				<button
					className="blueOk-bg big-text mb-2"
					onClick={() => handleClickModal(false)}
				>
					{Util.loc("Oups, je me suis trompé")}
				</button>
				<button
					className="blueOk-bg big-text mt-0"
					onClick={() => handleClickModal(true)}
				>
					{Util.loc("Je confirme mon choix")}
				</button>
			</div>
		</div>
	);
};

export default ModalEmptyPhone;
