import React from "react";
import Util from "../lib/Util";

import franceFlag from "../style/img/France_flag.jpg";
import ruFlag from "../style/img/R-U_flag.jpg";

class SelectLanguage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isFrench: Util.LANG === "fr",
		};
	}

	changeLanguage(lang) {
		Util.setLang(lang);
		this.setState({ isFrench: lang === "fr" });
	}

	render() {
		let isFrench = this.state.isFrench;
		// console.log(
		// 	"langue du navigateur :",
		// 	navigator.language.includes("fr")
		// );

		return (
			<div style={{ display: Util.INTERNATIONAL ? null : "none" }}>
				<img
					style={{
						cursor: "pointer",
						transform: isFrench ? "scale(1.25)" : "none",
						marginRight: "7px",
					}}
					src={franceFlag}
					onClick={() => {
						this.changeLanguage("fr");
					}}
				/>

				<img
					style={{
						cursor: "pointer",
						transform: isFrench ? "none" : "scale(1.25)",
					}}
					src={ruFlag}
					onClick={() => {
						this.changeLanguage("en");
					}}
				/>
			</div>
		);
	}
}
export default SelectLanguage;
