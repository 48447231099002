import React from "react";
import Api from "../lib/Api";
import Util from "../lib/Util";

class Visits extends React.Component {
	constructor(props) {
		super(props);
		// let story=this.props.App.storyGet();
		// this.bell=story.id;
		this.state = {
			scans: false,
			filter: [],
			isScanSelected: false,
			selectedScan: "",
			bans: [],
			bell: "",
			ban: [],
		};
		let story = this.props.App.storyGet();
		// console.log("story.id : " + story.id);
		if (story) this.load({ bell: story.id });
	}
	load(data) {
		// let data={bell:this.bell};
		if (this.ongoing) return;
		this.ongoing = true;
		new Api().fetch("scanList", data, result => {
			this.ongoing = false;
			console.log(result);
			if (!result) return this.props.App.alert();
			if (result.err) return this.props.App.alert(result.err, result.msg);
			this.setState({ scans: result.scans, bans: result.bans });
		});
	}

	setFilter(type, bell, tag, ban) {
		this.setState({ bell: bell, ban: ban });
		let filter = [];
		switch (type) {
			case "all":
				console.log("see all");
				this.props.setIsFilter(false);
				for (let s in this.state.scans) {
					console.log("push");
					filter.push(true);
				}
				this.setState({
					filter: filter,
					isScanSelected: false,
					selectedScan: "",
				});
				break;

			case "scans":
				this.props.setIsFilter(true);
				for (let s in this.state.scans) {
					filter.push(this.state.scans[s].tag === tag);
				}
				this.setState({
					filter: filter,
					isScanSelected: true,
					selectedScan: tag,
				});
				break;
		}
	}

	banEdit(bell, tag, value, cb) {
		let data = {
			bell: bell,
			tag: tag,
			value: value ? 1 : 0,
		};

		new Api().fetch("banEdit", data, result => {
			if (!result) return this.props.App.alert();
			if (result.err) return this.props.App.alert(result.err, result.msg);
			if (cb) cb(result.bans, value);
		});
	}

	render() {
		// console.log("SCANS : " + this.state.scans.length);
		let month = false;

		// console.log("SCANS :", this.state.scans);
		// console.log("selected scan :", this.state.selectedScan);
		// console.log("bans :", this.state.bans);

		return (
			<>
				<div className="row d-flex flex-row justify-content-center mt-5">
					<div className="col-12 col-md-10 col-lg-6 scans-title-block">
						{this.state.isScanSelected ? (
							<>
								<button
									className="all-scans-arrow"
									onClick={() => this.setFilter("all")}
								>
									<img className="arrow" />
								</button>
								<p className="etape text-left scans-title">
									{Util.loc("SCANS SELECTIONNES")}
								</p>
							</>
						) : (
							<>
								<p className="etape text-left scans-title">
									{Util.loc("SCANS")}
								</p>
							</>
						)}
					</div>
				</div>
				<div className="row d-flex flex-row justify-content-center">
					<div className="col-12 col-md-10 col-lg-6">
						{this.state.scans && this.state.scans.length == 0 ? (
							<p className="text-left edit-info">
								{Util.loc("Aucun scan à date")}
							</p>
						) : (
							<div className="btn-info-block">
								<span className="edit-info">
									{this.state.isScanSelected
										? Util.loc(
												"Tous les scans ci-dessous proviennent du même téléphone. Je peux bloquer cet interlocuteur : il ne pourra alors me contacter que par email."
										  )
										: Util.loc(
												"En cliquant sur un scan ci-dessous, je vois toutes les alertes adressées par ce même interlocuteur. Je peux alors le bloquer pour qu'il ne puisse me contacter que par email !"
										  )}
								</span>
								{this.state.isScanSelected ? (
									<div className="blacklist-btn-block">
										<div>
											<span className="toggle-att blacklist-toggle">
												{Util.loc(
													"Bloquer cet interlocuteur"
												)}
											</span>

											<div className="custom-control custom-switch role-switch">
												<input
													type="checkbox"
													className="custom-control-input"
													id="blacklist"
													checked={this.state.bans.includes(
														this.state.selectedScan
													)}
													onChange={() => {
														this.banEdit(
															this.state.bell,
															this.state
																.selectedScan,
															!this.state.ban,
															(bans, value) => {
																this.setState({
																	bans: bans,
																	ban: value,
																});
															}
														);
													}}
												/>
												<label
													className="custom-control-label text-left"
													style={{
														cursor: "pointer",
													}}
													htmlFor={"blacklist"}
												>
													&nbsp;
												</label>
											</div>
										</div>
									</div>
								) : null}
							</div>
						)}

						<table className="table visite">
							<tbody>
								{this.state.scans
									? this.state.scans
											.filter((s, i) =>
												!this.state.filter.length
													? true
													: this.state.filter[i]
											)
											.map((s, i) => {
												let _month = month;
												month = Util.timeShortMonthYear(
													s.time
												);
												let t = Util.timeShortFormat(
													s.time
												);
												let ban = this.state.bans.includes(
													s.tag
												);
												//console.log("ban :", ban);

												return (
													<React.Fragment key={i}>
														{month !== _month ? (
															<tr>
																<td>
																	<h4 className="col-12">
																		{month}
																	</h4>
																</td>
															</tr>
														) : null}
														<tr
															style={{
																color: this.state.bans.includes(
																	s.tag
																)
																	? "#e6233e"
																	: "#0483a3",
																cursor:
																	"pointer",
															}}
															onClick={() =>
																this.setFilter(
																	this.state
																		.isScanSelected
																		? "all"
																		: "scans",
																	s.bell,
																	s.tag,
																	ban
																)
															}
														>
															<td
																style={{
																	textDecoration:
																		"underline",
																}}
															>
																{t}
															</td>
															<td>{s.reason}</td>
														</tr>
													</React.Fragment>
												);
											})
									: null}
							</tbody>
						</table>
					</div>
				</div>
			</>
		);
	}
}
export default Visits;
