import ReactGA from "react-ga";
import Cookies from "../Components/Cookies";

import Util from "../lib/Util";

class GoogleAnalytics {
	constructor() {
		this.enabled = false;

		this.checkApproval();
	}

	checkApproval() {
		const googleAnalyticsCookie = window.localStorage.getItem(
			"google-analytics"
		);

		if (googleAnalyticsCookie && googleAnalyticsCookie === "enabled") {
			this.startWatcher();
		}
	}

	setUserID(id) {
		ReactGA.set({ userId: id });
	}

	sendEvent(category, action, label) {
		ReactGA.event({
			category,
			action,
			label,
			nonInteraction: true,
		});
	}

	sendPageView(path) {
		if (this.enabled) {
			ReactGA.pageview(path);
		}
	}

	startWatcher() {
		this.enabled = true;

		ReactGA.initialize("UA-168413972-1", {
			debug: false /*Util.GA_DEBUG*/,
			titleCase: true,
		});
	}

	stopWatcher() {
		this.enabled = false;

		Cookies.removeCookie("_ga");
		Cookies.removeCookie("_gid");
		Cookies.removeCookie("_gat");
	}
}

// GoogleAnalyticsFactory is a javascript closure allowing us to create a GoogleAnalytics singleton
const GoogleAnalyticsFactory = function () {
	let instance;

	return {
		getInstance() {
			if (!instance) {
				instance = new GoogleAnalytics();
				return instance;
			}

			return instance;
		},
	};
};

export default GoogleAnalyticsFactory();
