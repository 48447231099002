import React from "react";
import { Link } from "react-router-dom";

import Util from "../lib/Util";
import Api from "../lib/Api";

import DisplayIdentity from "./DisplayIdentity";
import DisplayList from "./DisplayList";
import DisplayAddress from "./DisplayAddress";

class DisplayInformations extends React.Component {
	constructor(props) {
		super(props);
		let story = this.props.App.storyGet({ emails: [], phones: [] });
		this.state = story;
		this.state.showRevoke = true;
	}
	notify(readyToProcess) {
		this.forceUpdate();
		if (readyToProcess && !this.props.App.checkInformations())
			this.props.App.storyProcess(() => {});
	}
	revoke() {
		this.setState({ showRevoke: false });
		new Api().fetch("revoke", {}, result => {
			if (!result) return this.props.App.alert();
			if (result.err) return this.props.App.alert(result.err, result.msg);
			this.props.App.success(
				Util.loc(
					"Révocation accomplie !\n \nSeul ce navigateur est désormais connecté à votre compte."
				),
				() => {}
			);
		});
	}
	goDetail(value) {
		this.props.App.go(Util.SELECT_PARTNERS + "/" + value);
	}
	render() {
		//		console.log("render",this.props.App.checkInformations())
		return (
			<div className="col-12 needs-validation" noValidate>
				<div className="text-left profil-info px-1">
					<DisplayIdentity App={this.props.App} form={this} />

					<DisplayList
						App={this.props.App}
						field={"emails"}
						form={this}
						me={true}
					/>

					<DisplayList
						App={this.props.App}
						field={"phones"}
						form={this}
						me={true}
					/>

					<DisplayAddress App={this.props.App} form={this} />
				</div>

				<div className="row">
					<div className="col-12 mx-auto mt-4">
						{false ? (
							<React.Fragment>
								<p
									className="text-center"
									style={{ fontSize: "12px" }}
								>
									{Util.loc(
										"Pour visualiser à quels partnenaires de SPOORS mes informations sont transférées pour mettre à jour les données clients :"
									)}
									<span
										style={{
											color: "#26C5F0",
											cursor: "pointer",
										}}
										onClick={() =>
											this.goDetail("optinSend")
										}
									>
										{Util.loc("je clique ici")}
									</span>
								</p>
								<p
									className="text-center"
									style={{ fontSize: "12px" }}
								>
									{Util.loc(
										"Pour visualiser de quels partenaires de SPOORS j'accepte de recevoir par email les propositions :"
									)}
									<span
										style={{
											color: "#26C5F0",
											cursor: "pointer",
										}}
										onClick={() =>
											this.goDetail("optinReceive")
										}
									>
										{Util.loc("je clique ici")}
									</span>
								</p>
							</React.Fragment>
						) : null}
						<p
							className="text-center deconnexion-globale-info"
							/*style={{
								fontSize: "12px",
								color: "#0D4C5B",
							}}*/
						>
							{Util.loc(
								"Pour connaître vos droits sur les données personnelles que vous nous confiez, consultez notre politique de confidentialité"
							)}
							<Link to={Util.HOME_CGV}>
								{Util.loc("à la page suivante") + ". "}
							</Link>
							{Util.loc(
								"Si vous le souhaitez, vous pouvez contacter notre délégué à la protection des données via l'adresse e-mail : contact@spoors.fr"
							)}
						</p>
					</div>
				</div>
			</div>
		);
	}
}
export default DisplayInformations;
