import React from "react";

import "bootstrap/js/src/tooltip";
import Util from "../lib/Util";

import Form from "./Form";

class DisplayAddress extends React.Component {
	constructor(props) {
		super(props);

		let defaultAddress = {
			address_type: Util.ADDRESS_PRIMARY,
			street: "",
			town: "",
			zip: "",
			country: "",
			address_complement: "",
			ok: true,
		};
		if (props.purpose) {
			defaultAddress = { [props.purpose]: defaultAddress };
			this.state = {
				...this.props.App.storyGet(defaultAddress)[props.purpose],
			};
		} else this.state = { ...this.props.App.storyGet(defaultAddress) };
		let q = this.state;
		this.previousAddr = q.street + " " + q.zip + " " + q.town;
		this.state.tmpAddr = this.previousAddr;
		/*		
				if (this.state.street !== "") {
					this.props.App.storyUpdate({ tmpAddr: this.state.street });
					this.state.tmpAddr = this.state.street;
					if (this.props.form) this.props.form.notify();
				}
			*/
	}

	render() {
		return (
			<div className="w-100">
				<div className="row">
					<div className="col-12 px-0">
						<label className="category-title">
							{Util.loc("ADRESSE")}
						</label>
						<label className="profil-information">
							{this.state.tmpAddr}
						</label>
					</div>
				</div>
			</div>
		);
	}
}
export default DisplayAddress;
