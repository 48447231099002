import React from "react";

import Util from "./lib/Util";
import TopMenu from "./Components/TopMenu";
import Footer from "./Components/Footer";
import Api from "./lib/Api.js";
import ChevRetour from "./style/img/chevron-retour.png";
import Close from "./style/img/close.png";

/*
Proposition de format pour la FAQ :
faq=array of {category, choices}
choices=array of {question, answer}

category: String
question: String
answer: Html

fonction de filtrage
-> ne conserve que les 'answer' qui contiennent le fitre
*/

class HomeFaq extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			faq: {},
			done: false,
		};
		new Api().fetch("faqGet", {}, result => {
			if (!result) return this.props.App.alert();
			if (result.err) return this.props.App.alert(result.err, result.msg);
			this.setState({ faq: result.faq, done: true });
		});
	}
	render() {
		let faq = this.state.faq;
		return (
			<React.Fragment>
				<TopMenu App={this.props.App} noBurger={true}></TopMenu>
				<div className="row bg-primary">
					<div className="col-12 pt-sm-block text-center">
						<h1 className="mb-4">
							{Util.loc("Questions fréquentes")}
						</h1>
					</div>
				</div>
				<div className="col-12 col-sm-10 col-md-8 col-lg-6 block text-right mt-30 mx-auto sticky-top">
					<div
						onClick={() => this.props.App.goBack()}
						className="text-right"
						style={{ cursor: "pointer" }}
					>
						<img src={Close} alt="fermer" />
					</div>
				</div>
				<div className="row mt-30 containt">
					<div className="col-12 col-sm-10 col-md-8 col-lg-6 mx-auto">
						{true ? null : (
							<div className="row">
								<div className="block m-auto text-center">
									<div className="row">
										<div className="form-inline w-100">
											<div className="input-recherche pl-0 ml-auto">
												<input
													type="search"
													className="form-control"
													id="recherche"
													placeholder={Util.loc(
														"recherche"
													)}
													aria-label="Search"
												/>
											</div>
											<button
												className="btn btn-primary px-3 mr-auto"
												type="button"
											>
												{Util.loc("suivant")}
											</button>
										</div>
									</div>
								</div>
							</div>
						)}

						<div className="row">
							<div className="accordion col-12 mx-auto" id="faq">
								{Object.keys(faq).map((key, index) => {
									let prefix = "part" + (index + 1);
									return (
										<div key={index} className="card">
											<div
												className="card-header"
												id={prefix + "-head"}
											>
												<h2 className="my-0 pr-3">
													<button
														className="btn btn-link btn-block collapsed"
														type="button"
														data-toggle="collapse"
														data-target={
															"#" + prefix
														}
														aria-expanded="false"
														aria-controls={prefix}
													>
														{key}
													</button>
												</h2>
											</div>
											<div
												id={prefix}
												className="collapse"
												aria-labelledby={
													prefix + "-head"
												}
												data-parent="#faq"
											>
												<div className="card-body">
													<div
														className="accordion"
														id={prefix + "-quest-1"}
													>
														<div className="card">
															{faq[key].map(
																(
																	elem,
																	subindex
																) => {
																	let name =
																		prefix +
																		"-" +
																		(subindex +
																			1);
																	return (
																		<React.Fragment
																			key={
																				subindex
																			}
																		>
																			<div
																				className="card-header"
																				id={
																					name +
																					"-head"
																				}
																			>
																				<h3 className="my-0 pr-3">
																					<button
																						className="btn btn-link btn-block collapsed"
																						type="button"
																						data-toggle="collapse"
																						data-target={
																							"#" +
																							name
																						}
																						aria-expanded="false"
																						aria-controls={
																							name
																						}
																					>
																						{
																							elem.question
																						}
																					</button>
																				</h3>
																			</div>
																			<div
																				id={
																					name
																				}
																				className="collapse"
																				aria-labelledby={
																					prefix +
																					"-head"
																				}
																				data-parent={
																					"#" +
																					prefix +
																					"-quest-1"
																				}
																			>
																				<div className="card-body">
																					<p>
																						{
																							elem.answer
																						}
																					</p>
																				</div>
																			</div>
																		</React.Fragment>
																	);
																}
															)}
														</div>
													</div>
												</div>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
				{/*				<Footer></Footer>*/}
			</React.Fragment>
		);
	}
}
export default HomeFaq;
