// import './App.css';

import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import { customHistory } from "./index";
import Util from "./lib/Util";
import Stripe from "./lib/Stripe";
import Api from "./lib/Api";
import GoogleAnalytics from "./lib/GoogleAnalytics";

import Popup from "./Components/Popup";
import Holdon from "./Components/Holdon";
import Cookies from "./Components/Cookies";
import Modal from "./Components/Modal";

import Home from "./Home";
import HomeFaq from "./HomeFaq";
import HomeContact from "./HomeContact";
import HomeCgv from "./HomeCgv";
import HomeError from "./HomeError";
import HomeSignin from "./HomeSignin";
import SelectPartners from "./SelectPartners";
import AccountEmailConfirm from "./AccountEmailConfirm";
import PurchaseStart from "./PurchaseStart";
import PurchaseForm from "./PurchaseForm";
import PurchaseSignin from "./PurchaseSignin";
import PurchaseConfirm from "./PurchaseConfirm";
import PurchaseDone from "./PurchaseDone";
import Bell from "./Bell";
import BellEdit from "./BellEdit";
import BellAlert from "./BellAlert";
import BellRedirectDone from "./BellRedirectDone";
import Claim from "./Claim";
import ClaimUsage from "./ClaimUsage";
import ClaimForm from "./ClaimForm";
import ClaimSignin from "./ClaimSignin";
import ClaimDone from "./ClaimDone";
import Visit from "./Visit";
import VisitMsg from "./VisitMsg";
import VisitDone from "./VisitDone";
import VisitAuto from "./VisitAuto";
import Contacts from "./Contacts";
import ContactEdit from "./ContactEdit";
import ContactRole from "./ContactRole";
import Account from "./Account";
import AccountEdit from "./AccountEdit";
import AccountInvoices from "./AccountInvoices";
import AccountConsum from "./AccountConsum";
import PurchaseUnits from "./PurchaseUnits";

import HomeScan from "./HomeScan";
import Test from "./Test";
import Board from "./Board";

function updateFieldList(list, id, update) {
	for (let p of list)
		if (p.id === id) {
			if (update) {
				for (let field in update) {
					p[field] = update[field];
				}
				return list;
			}
			let result = list.filter(function (p) {
				if (p.id !== id) return true;
				else return false;
			});
			return result;
		}
	return list;
}

const SESSION_STORY = "story";
const SESSION_PARTNERS = "partners";
const SESSION_PRICES = "prices";

class App extends React.Component {
	constructor(props) {
		super(props);

		Util.setApp(this);
		this.story = {};
		this.partners = {};
		this.prices = {};
		this.first = 1;

		this.state = {
			me: {},
			popup: false,
			holdon: 0,
			listProducts: [],
		};
		this.fetchMe();
		window.addEventListener("resize", () => this.forceUpdate());
	}

	myEmails() {
		return this.state.me.emails;
	}
	myFirstPhone() {
		if (this.state.me.phones && this.state.me.phones.length)
			return this.state.me.phones[0];
		return false;
	}
	myCountry() {
		if (this.state.me.phones && this.state.me.phones.length) {
			return Util.countryFromPhone(this.state.me.phones[0]);
		}
		return "FR";
	}
	apiStart() {
		setTimeout(() => this.setState({ holdon: this.state.holdon + 1 }), 500);
	}
	apiDone() {
		setTimeout(() => this.setState({ holdon: this.state.holdon - 1 }), 100);
	}
	popup(options) {
		this.setState({ popup: options });
	}
	firstname() {
		return this.state.me.firstname;
	}
	listProducts() {
		return this.state.listProducts;
	}
	alert(code, msg, cb) {
		if (!code) msg = Util.loc("Serveur injoignable");
		else msg += " (code=" + code + ")";
		this.popup({
			text: msg,
			close: false,
			yes: {
				text: Util.loc("Ok"),
				value: true,
			},
			cb: result => {
				if (cb) cb(result);
			},
		});
	}
	confirmPopup(msg, cb, no) {
		this.popup({
			text: msg,
			close: false,
			no: {
				text: no ? no : Util.loc("Annuler"),
				value: false,
			},
			yes: {
				text: Util.loc("Oui"),
				value: true,
			},
			cb: result => {
				if (cb) cb(result);
			},
		});
	}
	success(msg, cb) {
		this.popup({
			text: msg,
			close: false,
			icon: "ok",
			yes: {
				text: Util.loc("Ok"),
				value: true,
			},
			cb: result => {
				if (cb) cb(result);
			},
		});
	}
	updateMe(me) {
		this.setState({
			me: me,
		});
	}
	fetchMe(cb) {
		new Api().fetch("me", { first: this.first }, result => {
			if (!result) return this.alert();
			if (result.err) return this.alert(result.err, result.msg);
			this.first = 0;
			this.setState(
				{ me: result.me, listProducts: result.listProducts },
				() => this.forceUpdate()
			);
			if (cb) cb(result.me);
		});
	}
	setPolicy(val) {
		new Api().fetch("userSetPolicy", { policy: val }, result => {
			if (!result) return this.alert();
			if (result.err) return this.alert(result.err, result.msg);
			this.state.me.policy = val;
			this.setState({ me: this.state.me });
		});
	}

	myServices() {
		return this.state.me.services ? this.state.me.services : [];
	}
	logout(cb) {
		new Api().fetch("logout", {}, result => {
			if (!result) return this.alert();
			if (result.err) return this.alert(result.err, result.msg);
			this.setState({ me: result.me });
			this.storySet();
			if (cb) return cb();
			this.go(Util.HOME);
		});
	}
	signin(email, code, cb) {
		new Api().fetch("signin", { email: email, code: code }, result => {
			if (!result) return this.alert();
			if (result.err) return this.alert(result.err, result.msg);
			this.setState({ me: result.me });
			GoogleAnalytics.getInstance().setUserID(result.me.id);
			if (cb) return cb(result);
			this.success(Util.loc("Connexion réussie !"), () => {
				this.goStart();
			});
		});
	}
	confirm(data, cb) {
		new Api().fetch("confirm", { data: data }, result => {
			if (!result) return this.alert();
			if (!result.err) this.setState({ me: result.me });
			if (cb) return cb(result);
			if (result.err) return this.alert(result.err, result.msg);
			this.goStart();
		});
	}
	isRegistered() {
		if (this.state.me.level >= Util.LEVEL_REGISTERED) return true;
	}
	isAdmin() {
		if (this.state.me.level >= Util.LEVEL_ADMIN) return true;
	}

	mergeToContact(contacts, label, phone, email) {
		if (!label || label === "") return contacts;
		//		console.log("mergeToContact",JSON.stringify(contacts));
		for (let c of contacts)
			if (c.label === label) {
				//			console.log("c",JSON.stringify(c));
				c.phones = c.phones.filter(elem => {
					if (elem !== phone) return true;
					return false;
				});
				c.emails = c.emails.filter(elem => {
					if (elem !== email) return true;
					return false;
				});
				return contacts;
			}
		return contacts.concat([
			{
				label: label,
				phones: [],
				emails: [],
			},
		]);
	}
	getContacts(withme, importAlertsFromStory) {
		let me = this.state.me;
		let contacts = me.contacts
			? JSON.parse(JSON.stringify(me.contacts))
			: [];
		if (withme && me.firstname)
			contacts = [
				{
					label: me.firstname,
					phones: me.phones ? me.phones : [],
					emails: me.emails ? me.emails : [],
				},
			].concat(contacts);
		if (importAlertsFromStory && this.story.alerts) {
			for (let p of this.story.alerts)
				if (p.me)
					contacts = this.mergeToContact(
						contacts,
						p.label,
						p.phone,
						p.email
					);
		}
		//		console.log("finalcontact="+JSON.stringify(contacts));
		return contacts;
	}
	getBellNb() {
		// number of bells
		if (!this.isRegistered()) return 0;
		let me = this.state.me;
		return me.bellNb;
	}
	getBellBuilding() {
		// number of car bells
		if (!this.isRegistered()) return 0;
		let me = this.state.me;
		return me.bellBuilding;
	}
	getBellCar() {
		// number of car bells
		if (!this.isRegistered()) return 0;
		let me = this.state.me;
		return me.bellCar;
	}
	alertAdd(type, fill, feedWithMe) {
		let mn = 0;
		for (let a of this.story.alerts) if (a.id < mn) mn = a.id;
		let me = this.state.me;
		let phoneOrSms = type & Util.ALERT_PHONE || type & Util.ALERT_SMS;
		let newAlert = {
			me: true,
			temporary: 0,
			type: type,
			label: "",
			phone: "",
			email: "",
			from: phoneOrSms ? 8 * 60 + 30 : 0,
			to: phoneOrSms ? 23 * 60 + 30 : 0,
			except: "-------",
			id: mn - 1,
		};
		if (fill || feedWithMe) {
			let label = this.story.firstname;
			if (!label) label = me.firstname;
			if (label) newAlert.label = label;
		}
		if (fill) {
			if (type === Util.ALERT_PHONE && me.phones && me.phones.length)
				newAlert.phone = me.phones[0];
			if (type === Util.ALERT_SMS && me.phones && me.phones.length)
				newAlert.phone = me.phones[0];
			if (type === Util.ALERT_EMAIL && me.emails && me.emails.length)
				newAlert.email = me.emails[0];
		}

		let update = {
			alerts: this.story.alerts.concat([newAlert]),
		};
		this.storyUpdate(update);
		return update;
	}
	alertNew(alert) {
		let alerts =
			alert.id == Util.ALERT_FIRST
				? [alert].concat(this.story.alerts)
				: this.story.alerts.concat([alert]);
		alerts.map((item, index) => {
			alerts.order = index + 1;
		});
		this.storyUpdate({ alerts: alerts });
	}
	alertUpdateField(id, update) {
		update = {
			alerts: updateFieldList(this.story.alerts, id, update),
		};
		this.storyUpdate(update);
		return update;
	}
	alertReorder(id, prev) {
		let alerts = [];
		let previousAlert = false;
		for (let a of this.story.alerts)
			if (a.id === prev) previousAlert = a;
			else {
				alerts.push(a);
				if (previousAlert && a.id === id) alerts.push(previousAlert);
			}
		let update = {
			alerts: alerts,
		};
		this.storyUpdate(update);
		return update;
	}
	storyStorage() {
		return sessionStorage;
	}
	storySave() {
		let data = JSON.stringify(this.story);
		if (this.storyStorage().getItem(SESSION_STORY) === data) return;
		console.log("--SAVE", data);
		this.storyStorage().setItem(SESSION_STORY, data);
	}
	storySet(story) {
		this.story = story ? story : {};
		this.storySave();
	}
	storyUpdate(update) {
		for (var key in update) this.story[key] = update[key];

		this.storySave();
	}
	storyGet(filter) {
		if (!filter) return this.story;
		for (let field in filter) {
			if (this.story[field]) filter[field] = this.story[field];
			else this.story[field] = filter[field];
		}
		this.storySave();
		return filter;
	}
	storyNeedMulti(list, urlDefault) {
		for (let url of list)
			if (this.story && this.story.url === url) return true;
		let story = JSON.parse(this.storyStorage().getItem(SESSION_STORY));
		//		console.log("read "+this.storyStorage().getItem(SESSION_STORY));
		if (story)
			for (let url of list)
				if (story.url === url) {
					this.story = story;
					console.log(
						"recover " + this.storyStorage().getItem(SESSION_STORY)
					);
					return true;
				}
		if (urlDefault)
			setTimeout(() => {
				this.go(urlDefault);
			}, 100);
	}
	storyNeed(urlStory, urlDefault) {
		return this.storyNeedMulti([urlStory], urlDefault);
	}
	storyIsPurchase() {
		if (this.story.url === "/PURCHASE") return true;
	}
	storyIsClaim() {
		if (this.story.url === "/CLAIM") return true;
	}

	storyProcess(cb, cberr) {
		if (this.story.sending) return;
		let story = JSON.stringify(this.story);
		console.log("--PROCESS", story);
		this.story.sending = true;
		new Api().fetch("storyProcess", { story }, result => {
			this.story.sending = false;
			if (!result) return this.alert();
			if (result.err) {
				if (cberr) cberr(result);
				return this.alert(result.err, result.msg);
			}
			if (result.email) this.story.email = result.email;
			if (result.story) this.storyUpdate(result.story);
			if (result.nextStory) this.storySet(result.nextStory);
			//			if (result.done) this.storySet();
			this.storySave();
			if (result.me) {
				this.setState({ me: result.me });
				if (cb) return cb(result);
				if (result.stripe)
					return Stripe(result.kpub, stripe =>
						stripe.redirectToCheckout({ sessionId: result.stripe })
					);
				if (result.next) this.go(result.next);
			} else
				this.fetchMe(() => {
					if (cb) return cb(result);
					if (result.stripe)
						return Stripe(result.kpub, stripe =>
							stripe.redirectToCheckout({
								sessionId: result.stripe,
							})
						);
					if (result.next) this.go(result.next);
				});
		});
	}

	bellResign() {
		let story = this.storyGet();
		let msg = "";
		if (story.usage === Util.USAGE_CAR)
			msg = Util.loc(
				"Etes-vous sûr de vouloir supprimer cette vignette ?"
			);
		else if (story.usage === Util.USAGE_BUILDING)
			msg = Util.loc(
				"Etes-vous sûr de vouloir supprimer cette sonnette ?"
			);
		else if (story.usage === Util.USAGE_OBJECT)
			msg = Util.loc(
				"Etes-vous sûr de vouloir supprimer cette étiquette ?"
			);
		else msg = Util.loc("Etes-vous sûr de vouloir supprimer ce SPOORS ?");

		this.confirmPopup(msg, result => {
			if (!result) return;
			new Api().fetch("bellResign", { code: story.code }, result => {
				//			console.log(result);
				if (!result) return this.alert();
				if (result.err) return this.alert(result.err, result.msg);
				this.go(Util.BOARD);
			});
		});
	}
	serviceRemove(id) {
		let data = { id: id };
		new Api().fetch("serviceRemove", data, result => {
			//			console.log(result);
			if (!result) return this.alert();
			if (result.err) return this.alert(result.err, result.msg);
			this.setState({ me: result.me });
			//			cb();
		});
	}
	contactNew(label, cb) {
		let data = { label: label };
		new Api().fetch("contactNew", data, result => {
			//			console.log(result);
			if (!result) return this.alert();
			if (result.err) return this.alert(result.err, result.msg);
			this.setState({ me: result.me });
			cb();
		});
	}
	partnersSet(partners) {
		this.partners = partners;
		sessionStorage.setItem(SESSION_PARTNERS, JSON.stringify(partners));
	}
	partnersGet() {
		let partners = sessionStorage.getItem(SESSION_PARTNERS);
		if (partners && partners !== "") this.partners = JSON.parse(partners);
		return this.partners;
	}
	pricesSet(prices) {
		this.prices = prices;
		sessionStorage.setItem(SESSION_PRICES, JSON.stringify(prices));
	}
	pricesGet() {
		let prices = sessionStorage.getItem(SESSION_PRICES);
		if (prices && prices !== "") this.prices = JSON.parse(prices);
		return this.prices;
	}

	handleScan(result) {
		this.storySet(result.story);
		this.partnersSet(result.partners);
		this.setState({ me: result.me });
		this.go(result.story.url, true);
	}
	defaultProduct() {
		return this._defaultProduct;
	}
	setDefaultProduct(val) {
		this._defaultProduct = val;
	}
	go(route, relative) {
		//		console.log(route);
		if (relative) route = Util.url(route);
		this.props.history.push(route);
	}
	goStart() {
		this.go(this.getBellNb() > 0 ? Util.BOARD : Util.HOME);
	}
	goBack() {
		this.props.history.goBack();
	}
	error(err) {
		if (!err.buttons) err.buttons = [];
		this.err = err;
		this.props.history.push(Util.HOME_ERROR);
	}
	errorGet() {
		return this.err;
	}

	checkIdentity() {
		return (
			Util.check(this.story.firstname) || Util.check(this.story.lastname)
		);
	}
	checkAddress(checkUsage, profil) {
		if (checkUsage && this.story.usage === Util.USAGE_CAR) return;
		if (checkUsage && this.story.usage === Util.USAGE_OBJECT) return;
		if (!profil && !this.story.ok) return true;
		return Util.check(this.story.street, profil);
	}

	checkInvoiceAddress() {
		if (!Util.CHECKSYNTAX) return;
		if (!this.story.withInvoiceAddress) return;
		let next = this.story.invoiceAddress;
		if (!next) return true;
		if (!next.ok) return true;
		return Util.check(next.street);
	}

	checkCompany() {
		if (this.story.properso !== Util.TYPE_PRO) return;
		return (
			Util.check(this.story.company_name) ||
			Util.checkSiret(this.story.siret, true) ||
			Util.check(this.story.business) ||
			Util.checkUrl(this.story.company_url)
		);
	}
	checkCar() {
		if (this.story.usage !== Util.USAGE_CAR) return;
		return (
			Util.check(this.story.plate) ||
			Util.check(this.story.brand) ||
			Util.check(this.story.model)
		);
	}
	checkObject() {
		if (this.story.usage !== Util.USAGE_OBJECT) return;
		return (
			Util.check(this.story.objFamily) || Util.check(this.story.objAlias)
		);
	}
	checkEmail() {
		if (this.isRegistered()) return;
		return Util.checkEmail(this.story.email);
	}
	checkNextAddress() {
		if (!Util.CHECKSYNTAX) return;
		let next = this.story.nextAddress;
		if (!next) return true;
		if (!next.ok) return true;
		return Util.check(next.street);
	}
	checkNextUser() {
		if (!Util.CHECKSYNTAX) return;
		let next = this.story.nextUser;
		if (!next) return true;
		return (
			Util.check(next.firstname) ||
			Util.check(next.lastname) ||
			Util.checkEmail(next.email)
		);
	}
	checkDelayReception() {
		if (!Util.CHECKSYNTAX) return;
		return Util.checkDelay(this.story.delayReception);
	}
	checkDelayResign() {
		if (!Util.CHECKSYNTAX) return;
		return Util.checkDelay(this.story.delayResign);
	}
	checkCallback() {
		if (!Util.CHECKSYNTAX) return;
		return this.checkIdentity() || Util.check(this.story.callback);
	}
	checkMsg() {
		if (!Util.CHECKSYNTAX) return;
		return (
			Util.check(this.story.name) ||
			Util.check(this.story.msg, true) ||
			Util.checkInternationalPhone(this.story.callback, false)
		);
	}
	checkPurchaseForm() {
		if (!Util.CHECKSYNTAX) return;
		return (
			this.checkIdentity() ||
			this.checkAddress(false) ||
			this.checkInvoiceAddress() ||
			this.checkCompany() ||
			this.checkEmail() ||
			!this.story.cgu
		);
	}

	checkPurchaseFlow() {
		return this.checkPurchaseForm() === undefined &&
			this.isRegistered() &&
			this.story.content.length !== 0
			? true
			: false;
	}

	checkClaimUsage() {
		// name, address, ...
		if (!Util.CHECKSYNTAX) return;
		return (
			Util.check(this.story.usage) ||
			(this.story.askTitle && Util.check(this.story.title)) ||
			(this.isRegistered() && this.story.cpv.length && !this.story.cgu)
		);
	}
	checkClaimForm() {
		// name, address, ...
		if (!Util.CHECKSYNTAX) return;
		return (
			this.checkIdentity() ||
			Util.checkEmail(this.story.email) ||
			Util.checkInternationalPhone(this.story.phone, false, true) ||
			!this.story.cgu
		);
	}
	checkBellAlert(state) {
		//		console.log("check",state.withPhone || state.withSms ||state.withEmail)
		if (!(state.withPhone || state.withSms || state.withEmail)) return true;
		if (
			state.withSms &&
			Util.checkSmsNumber(state.phone, state.countryCode)
		)
			return true;
		if (
			state.withPhone &&
			Util.checkInternationalPhone(state.phone, state.countryCode)
		)
			return true;
		if (state.withEmail && Util.checkEmail(state.email)) return true;
		//		console.log("check OK")
		return false;
	}
	checkContact() {
		if (!Util.CHECKSYNTAX) return;
		let v = null;
		for (let val of this.story.phones)
			v = v || Util.checkInternationalPhone(val);
		for (let val of this.story.emails) v = v || Util.checkEmail(val);
		return v;
	}
	checkClaimWelcome() {
		// customize alerts
		if (!Util.CHECKSYNTAX) return;
		return Util.check(this.story.welcome);
	}
	checkInformations() {
		if (!Util.CHECKSYNTAX) return;
		let v = null;
		for (let val of this.story.phones)
			v = v || Util.checkInternationalPhone(val);
		for (let val of this.story.emails) v = v || Util.checkEmail(val);
		return v || this.checkIdentity() || this.checkAddress(false, true);
	}

	purchaseCount() {
		if (!this.storyIsPurchase()) return 0;
		let story = this.story;
		let count = 0;
		for (let c of story.content) if (c.quantity) count += c.quantity;
		for (let code in story.services)
			if (story.services[code].enable) count++;
		return count;
	}

	componentDidMount() {
		const { location } = customHistory;

		// Google Analytics
		const googleAnalytics = GoogleAnalytics.getInstance();
		googleAnalytics.sendPageView(`${location.pathname}${location.search}`);

		//Stripe
		//		Stripe.getInstance().initialize();
	}

	render() {
		if (!this.state.me) return null; // TODO: Should handle this in a better way but do not remove it !!!

		return (
			<>
				<Modal />
				<Cookies />
				<div
					id="app"
					className="App container-fluid d-flex flex-column p-0"
					style={
						{
							/*overflowX:"hidden"*/
						}
					}
				>
					<Switch>
						<Route
							path={Util.BELL + "/:code"}
							render={props => (
								<Bell
									App={this}
									code={props.match.params.code}
								/>
							)}
						/>
						<Route
							path={Util.SCAN}
							render={props => <HomeScan App={this} />}
						/>
						<Route
							path={Util.VISIT}
							render={props => <Visit App={this} />}
						/>
						<Route
							path={Util.VISIT_AUTO}
							render={props => <VisitAuto App={this} />}
						/>
						<Route
							path={Util.VISIT_MSG}
							render={props => <VisitMsg App={this} />}
						/>
						<Route
							path={Util.VISIT_DONE}
							render={props => <VisitDone App={this} />}
						/>
						<Route
							path={Util.CLAIM}
							render={props => <Claim App={this} />}
						/>
						<Route
							path={Util.CLAIM_USAGE}
							render={props => <ClaimUsage App={this} />}
						/>
						<Route
							path={Util.CLAIM_FORM}
							render={props => <ClaimForm App={this} />}
						/>
						<Route
							path={Util.CLAIM_SIGNIN}
							render={props => <ClaimSignin App={this} />}
						/>
						<Route
							path={Util.CLAIM_DONE}
							exact
							render={() => <ClaimDone App={this} />}
						/>
						<Route
							path={Util.CLAIM_WELCOME}
							exact
							render={() => (
								<BellEdit App={this} claim_welcome={true} />
							)}
						/>
						<Route
							path={Util.CLAIM_ALERTS}
							exact
							render={() => (
								<BellEdit App={this} claim_alert={true} />
							)}
						/>
						<Route
							path={Util.CLAIM_ALERT}
							exact
							render={() => (
								<BellAlert App={this} claim_alert={true} />
							)}
						/>

						<Route
							path={Util.PURCHASE_START}
							exact
							render={() => <PurchaseStart App={this} />}
						/>
						<Route
							path={Util.PURCHASE_FORM}
							exact
							render={() => <PurchaseForm App={this} />}
						/>
						<Route
							path={Util.PURCHASE_CONFIRM}
							exact
							render={() => <PurchaseConfirm App={this} />}
						/>
						<Route
							path={Util.PURCHASE_DONE}
							exact
							render={() => <PurchaseDone App={this} />}
						/>
						<Route
							path={Util.SELECT_PARTNERS + "/:optin"}
							render={props => (
								<SelectPartners
									App={this}
									optin={props.match.params.optin}
								/>
							)}
						/>
						<Route
							path={Util.PURCHASE_SIGNIN}
							exact
							render={() => <PurchaseSignin App={this} />}
						/>
						<Route
							path={Util.CONFIRM + "/:data"}
							render={props => (
								<AccountEmailConfirm
									App={this}
									data={props.match.params.data}
								/>
							)}
						/>
						<Route
							path={Util.PURCHASE_UNITS}
							exact
							render={() => <PurchaseUnits App={this} />}
						/>
						<Route
							path={Util.HOME_FAQ}
							exact
							render={() => <HomeFaq App={this} />}
						/>

						<Route
							path={Util.HOME_CONTACT}
							exact
							render={() => <HomeContact App={this} />}
						/>
						<Route
							path={Util.HOME_CGV}
							exact
							render={() => <HomeCgv App={this} />}
						/>
						<Route
							path={Util.HOME_CGV + "/:doc"}
							render={props => (
								<HomeCgv
									App={this}
									doc={props.match.params.doc}
								/>
							)}
						/>
						<Route
							path={Util.HOME_ERROR}
							exact
							render={() => <HomeError App={this} />}
						/>
						<Route
							path={Util.HOME_SIGNIN}
							exact
							render={() => <HomeSignin App={this} />}
						/>
						<Route
							path={Util.HOME_BASE + "/:section"}
							render={props => (
								<Home
									App={this}
									section={props.match.params.section}
								/>
							)}
						/>
						{/* <Route
							path={
								Util.FROM + "/:providerName/:providerOperation"
							}
							render={props => {
								//	console.log("FROM detected")
								const {
									providerName,
									providerOperation,
								} = props.match.params;

								// Saving provider name
								Util.setProvider({
									name: providerName,
									operation: providerOperation,
								});

								// Redirecting to PURCHASE_START
								customHistory.push(Util.PURCHASE_START);
							}}
						/> */}
						<Route
							path={Util.BOARD}
							exact
							render={() => <Board App={this} />}
						/>
						<Route
							path={Util.BELL_EDIT + "/:code"}
							exact
							render={props => (
								<BellEdit
									App={this}
									code={props.match.params.code}
									bell_edit={true}
								/>
							)}
						/>
						<Route
							path={Util.BELL_ALERT}
							exact
							render={() => <BellAlert App={this} />}
						/>
						<Route
							path={Util.BELL_REDIRECT_DONE}
							exact
							render={() => <BellRedirectDone App={this} />}
						/>
						<Route
							path={Util.CONTACTS}
							exact
							render={() => <Contacts App={this} />}
						/>
						<Route
							path={Util.CONTACT_EDIT}
							exact
							render={() => <ContactEdit App={this} />}
						/>
						<Route
							path={Util.CONTACT_ROLE}
							exact
							render={() => <ContactRole App={this} />}
						/>
						<Route
							path={Util.ACCOUNT}
							exact
							render={() => <Account App={this} />}
						/>
						<Route
							path={Util.ACCOUNT_EDIT}
							exact
							render={() => <AccountEdit App={this} />}
						/>
						<Route
							path={Util.ACCOUNT_INVOICES}
							exact
							render={() => <AccountInvoices App={this} />}
						/>
						<Route
							path={Util.ACCOUNT_CONSUM}
							exact
							render={() => <AccountConsum App={this} />}
						/>
						{Util.TEST ? (
							<Route
								path={Util.PAGE_TEST}
								render={props => <Test App={this} />}
							/>
						) : null}
						<Route
							path={Util.HOME}
							exact
							render={() => <Home App={this} />}
						/>
						<Route
							path="/:code"
							render={props => (
								<Redirect
									to={"/BELL/" + props.match.params.code}
								/>
							)}
						/>
					</Switch>
					{this.state.popup ? (
						<Popup App={this} options={this.state.popup} />
					) : null}
					{this.state.holdon > 0 ? <Holdon App={this} /> : null}
				</div>
			</>
		);
	}
}

export default App;
