import React from "react";
import Util from "../lib/Util";
import "../../node_modules/bootstrap/js/src/carousel";
import IcoMinus from "../style/img/ei-moins.png";
import IcoPlus from "../style/img/icon-plus.svg";
import SelectCountry from "./SelectCountry";

class FormList extends React.Component {
	constructor(props) {
		super(props);
		this.count = 0;
		this.phone = this.props.field === "phones";

		let update = {};
		update[this.props.field] = [];
		let story = this.props.App.storyGet(update);
		this.state = {
			dataList: JSON.parse(JSON.stringify(story[this.props.field])),
			isPhoneFocus: false,
			isEmailFocus: false,
			isPhoneDisabled: false,
			isEmailDisabled: false,
			countryList: [],
		};
		this.inputs = [];
		this.init();
	}
	init() {
		let data = this.state.dataList;

		// if (this.state.dataList.length == 0) {
		// 	this.state.isPhoneDisabled = false;
		// 	this.state.isEmailDisabled = true;
		// 	this.state.dataList = [""];
		// 	this.state.countryList = [this.props.App.myCountry()];
		// 	return;
		// }

		if (this.phone) {
			let countryCodeList = this.state.countryList;
			data.map((number, index) => {
				countryCodeList[index] = Util.countryFromPhone(number);
			});
			this.state.countryList = countryCodeList;
		}
	}

	restore() {
		console.log("restore");
		let story = this.props.App.storyGet();
		this.setState({
			dataList: JSON.parse(JSON.stringify(story[this.props.field])),
		});
	}

	finalize(dataList) {
		let field = this.props.field;
		let update = {};
		update[field] = JSON.parse(JSON.stringify(dataList));
		this.props.App.storyUpdate(update);
		if (this.props.form) this.props.form.notify(true);
	}

	onBlur(value) {
		let field = this.props.field;
		let valueIndex = this.state.dataList.indexOf(value);
		console.log("onBlur", value, field, valueIndex);

		console.log("check restore :", this.check(value, valueIndex));
		if (this.check(value, valueIndex) && (!this.phone || value !== ""))
			return this.restore();

		let dataList = this.state.dataList.filter(
			(elem, index) => !this.check(elem, index)
		);

		if (value === "") {
			dataList.push(value);
			this.setState({ dataList: dataList });
			return;
		}

		this.setState({ dataList: dataList });

		let story = this.props.App.storyGet();
		if (JSON.stringify(dataList) === JSON.stringify(story[field])) {
			console.log("no change");
			return;
		}
		if (value !== "") this.finalize(dataList);
	}

	onChange(value, index) {
		if (this.phone)
			value = Util.niceInternationalPhone(
				value,
				this.state.countryList[index]
			);
		this.state.dataList[index] = value;
		this.setState({ dataList: this.state.dataList });
	}

	add() {
		let data = this.state.dataList;
		let countryList = this.state.countryList;

		if (data.length && data[data.length - 1] === "") {
			if (this.state.isEmailDisabled) {
				this.setState({ isEmailDisabled: false });
			}
			return;
		}

		data.push("");
		countryList.push(this.props.App.myCountry());
		this.setState({ dataList: data, countryList: countryList });

		this.phone
			? this.setState({ isPhoneFocus: true, isPhoneDisabled: false })
			: this.setState({ isEmailFocus: true, isEmailDisabled: false });
	}

	removeNow(index) {
		let data = this.state.dataList;
		let countryList = this.state.countryList;
		data.splice(index, 1);
		countryList.splice(index, 1);
		this.setState({ dataList: data, countryList: countryList });
		this.phone && !data.length
			? this.setState({ isPhoneDisabled: true })
			: this.setState({ isEmailDisabled: true });
		this.finalize(data);
	}
	remove(index) {
		//		console.log("remove", index, this.state.dataList[index]);
		if (this.state.dataList[index] === "") return this.removeNow(index);
		this.props.App.confirmPopup(
			this.phone
				? Util.loc("Etes-vous sûr de vouloir supprimer ce numéro ?")
				: Util.loc(
						"Etes-vous sûr de vouloir supprimer cette adresse ?"
				  ),
			result => {
				if (!result) return;
				this.removeNow(index);
			}
		);
	}

	check(value, index) {
		if (!this.state.isPhoneDisabled || !this.state.isEmailDisabled) {
			if (this.phone) {
				let countryCode = this.state.countryList[index];
				return Util.checkInternationalPhone(
					value,
					countryCode,
					this.props.withNext
				);
			}
			return Util.checkEmail(value, this.props.withNext);
		}
		return;
	}

	onCountry(value, index) {
		let data = this.state.dataList;
		let countryList = this.state.countryList;

		if (value === countryList[index]) {
			console.log("[country code] no change");
			return;
		}
		countryList[index] = value;
		data[index] = "";
		this.setState({
			dataList: data,
			countryList: countryList,
			isPhoneDisabled: false,
		});
		if (this.inputs[index]) this.inputs[index].focus();
	}

	render() {
		//		console.log(this.state.countryList);
		let mayRemove = this.state.dataList.length > 1 || !this.props.me;
		return (
			<React.Fragment>
				<div className="row form-category-title">
					<div className="form-label">
						{this.phone
							? Util.loc("Téléphone *")
							: Util.loc("Email *")}
					</div>
					{this.state.dataList.length ? null : (
						<div
							style={{
								paddingLeft: "50px",
								fontStyle: "italic",
								color: "#666",
							}}
						>
							{this.phone
								? Util.loc("Pas de numéro enregistré")
								: Util.loc("Pas d'email enregistré")}
						</div>
					)}
				</div>
				{this.state.dataList.map((data, subindex) => {
					//console.log("data: ", JSON.stringify(data));
					return (
						<div
							key={subindex}
							className="row form-category-title neverTopMargin"
						>
							<div className="col-12 px-0">
								{this.phone ? (
									<SelectCountry
										onCountry={country =>
											this.onCountry(country, subindex)
										}
										countryCode={
											this.state.countryList[subindex]
										}
									/>
								) : null}
								<input
									type={this.phone ? "tel" : "mail"}
									autoComplete={Util.random()}
									spellCheck={false}
									className="form-profil-information"
									ref={ref => (this.inputs[subindex] = ref)}
									required
									disabled={
										this.phone
											? this.state.isPhoneDisabled
											: this.state.isEmailDisabled
									}
									placeholder={
										this.phone
											? this.state.isPhoneDisabled
												? Util.loc(
														"Pas de numéro enregistré"
												  )
												: ""
											: this.state.isEmailDisabled
											? Util.loc("Pas d'email enregistré")
											: ""
									}
									value={data}
									autoFocus={
										this.phone
											? this.state.isPhoneFocus
											: this.state.isEmailFocus
									}
									onKeyPress={event => {
										if (event.key === "Enter")
											event.target.blur();
									}}
									onBlur={() => {
										this.onBlur(data);
									}}
									onChange={event => {
										this.onChange(
											event.target.value,
											subindex
										);
									}}
								/>
								<div
									style={this.check(data, subindex)}
									className="invalid-tooltip"
								>
									{this.phone
										? Util.loc(
												"Veuillez saisir un numéro valide"
										  )
										: Util.loc(
												"Veuillez remplir ce champ correctement"
										  )}
								</div>
							</div>
							{mayRemove ? (
								<div className="trash">
									<button
										style={{
											background: "none",
											border: "none",
											boxShadow: "none",
											padding: "0 0 20px 0",
										}}
										onClick={() => this.remove(subindex)}
									>
										<img src={IcoMinus} alt="corbeille" />
									</button>
								</div>
							) : null}
						</div>
					);
				})}
				<div className="text-center">
					<button
						onClick={() => this.add()}
						style={{
							background: "none",
							border: "none",
							boxShadow: "none",
							height: "35px",
							marginTop: "20px",
						}}
					>
						<img className="img-add" src={IcoPlus} alt="more" />
						<span className="align-middle add-text">
							{this.phone
								? Util.loc("Ajouter un numéro")
								: Util.loc("Ajouter un email")}
						</span>
					</button>
				</div>
			</React.Fragment>
		);
	}
}
export default FormList;
