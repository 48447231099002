import React from "react";
import Proptypes from "prop-types";

import Util from "./lib/Util";

import Page from "./Components/Page";
import QrScanner from "./lib/qr-scanner.js";

class HomeScan extends React.Component {
	constructor(props) {
		super(props);
		this.scanner = false;

		QrScanner.hasCamera().then(hasCamera =>
			console.log("hasCamera", hasCamera)
		);
	}
	start() {
		console.log("start");
		if (this.video) return;
		this.video = document.getElementById("qr-video");
		console.log("video", this.video);
		this.scanner = new QrScanner(this.video, result => this.scan(result));
		this.scanner.start();
	}
	componentWillUnmount() {
		console.log("destroy scanner");
		this.video = false;
		this.scanner.destroy();
		this.scanner = false;
	}
	checkUrl(pref, url) {
		if (url.startsWith(pref)) return url.substr(pref.length);
	}
	scan(url) {
		console.log(url);

		var code =
			this.checkUrl("https://spoors.fr/BELL/", url) ||
			this.checkUrl("https://spoors.fr/", url) ||
			this.checkUrl("HTTPS://SPOORS.FR/", url) ||
			this.checkUrl("WWW.SPOORS.FR/", url);

		if (code) this.props.App.go(Util.BELL + "/" + code);
	}
	render() {
		const { App } = this.props;

		if (!this.video) setTimeout(() => this.start(), 100);
		//			if (!this.scanner)

		return (
			<Page App={App} title={Util.loc("Je scanne un QR code")}>
				<section id="home-scan" className="screen no-margin">
					<div className="containt">
						<div className="row block my-sm-60 my-30">
							<div className="col-12 col-sm-10 col-md-8 col-lg-6 m-auto">
								<video
									style={{ width: "100%" }}
									muted
									playsInline
									id="qr-video"
								></video>
							</div>
						</div>
					</div>
				</section>
			</Page>
		);
	}
}

HomeScan.propTypes = {
	App: Proptypes.object.isRequired,
};

export default HomeScan;
