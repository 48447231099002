import React from "react";
import Util from "./lib/Util";
import { Link } from "react-router-dom";

import Api from "./lib/Api.js";
import "../node_modules/bootstrap/js/src/tab";
import "../node_modules/bootstrap/js/src/modal";

import CenteredContainer from "./Components/CenteredContainer";
import TopMenu from "./Components/TopMenu";

import FormInformations from "./Components/FormInformations";

import ArrowLeft from "../src/style/img/icon-arrow-left.svg";
import BottomPage from "./Components/BottomPage";

class AccountEdit extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			submenu: false,
		};
		this.menu = {};
		this.menu[Util.MENU_IDENTITY] = {
			icon: "",
			text: Util.loc("Mes informations"),
		};
		this.menu[Util.MENU_PWD] = { icon: "", text: Util.loc("Mot de passe") };
		this.menu[Util.MENU_MOVING] = {
			icon: "",
			text: Util.loc("Je déménage"),
		};

		this.load();
	}
	load() {
		new Api().fetch("accountEdit", {}, result => {
			if (!result) return this.props.App.alert();
			if (result.err) return this.props.App.alert(result.err, result.msg);
			this.props.App.storySet(result.story);
			this.props.App.partnersSet(result.partners);
			this.setState({ submenu: Util.MENU_IDENTITY });
		});
	}
	notify(readyToProcess) {
		this.forceUpdate();
	}
	buttonBackground(type) {
		if (type === this.state.submenu)
			return {
				background: "#8f8",
			};
		return {};
	}
	render() {
		const { App } = this.props;
		const submenu = this.state.submenu;
		// we need FormInformations to be instantiated after the story has been loaded. The following line is a hack to force this behaviour
		if (submenu === false) return null;

		return (
			<CenteredContainer id="page" className="screen-container">
				<div id="account" className="claimBackground">
					<TopMenu App={App} />
					<div className="row d-flex flex-row justify-content-center inputTop">
						<div className="col-12 col-md-10 col-lg-6 ">
							<div className="d-flex align-items-center justify-content-between padd20">
								<Link to={Util.ACCOUNT}>
									<img src={ArrowLeft} />
								</Link>
								<label className="form-control formControlStep ">
									{Util.loc("MON PROFIL")}
								</label>
							</div>
						</div>
					</div>

					<div id="my-profile" className="row containt">
						<section className="col-12 col-sm-10 col-md-8 col-lg-6 mx-auto block">
							<div className="text-center row">
								<div className="w-100">
									<div
										className="tab-content"
										id="pills-tabContent"
									>
										<div
											className="tab-pane fade active show"
											id="pills-info"
											role="tabpanel"
											aria-labelledby="pills-info-tab"
										>
											<FormInformations
												App={App}
												form={this}
											/>
										</div>
									</div>
								</div>
							</div>
						</section>
					</div>
				</div>
				<BottomPage />
			</CenteredContainer>
		);
	}
}
export default AccountEdit;
