import React from "react";
import { Link } from "react-router-dom";
import Proptypes from "prop-types";

import Util from "./lib/Util";
import { PRODUCTS } from "./lib/products";

import Page from "./Components/Page";
import ChevRetour from "./Components/ChevRetour";
import Button from "./Components/Button";

class PurchaseConfirm extends React.Component {
	constructor(props) {
		super(props);

		this.props.App.storyNeed("/PURCHASE", Util.HOME);
		this.state = {
			cgu: false,
		};
	}
	onButtonClicked = () => {
		this.props.App.storyProcess();
	};

	computeProductPrice(product, quantity) {
		let price = 0;
		if (product.stripe)
			price = Util.nicePrice(
				this.props.App.pricesGet()[product.stripe[product.color]]
			);
		return price * quantity;
	}

	renderMyIdentityRecap() {
		const { firstname, lastname, email } = this.props.App.story;
		const registered = this.props.App.isRegistered();

		const goBackButton = !registered ? (
			<Link className="mt-5" to="/PURCHASE_FORM">
				{Util.loc("Modifier")}
			</Link>
		) : null;

		return (
			<div className="recap col-12 mb-5 mb-xl-0 col-xl-5">
				<div className="container">
					<h3>{Util.loc("Mon identité")}</h3>

					<span>{`${firstname} ${lastname}`}</span>

					<span className="mt-4">{Util.loc("Email :")}</span>
					<span>{email}</span>
				</div>
				{goBackButton}
			</div>
		);
	}

	renderAddressRecap() {
		const { street, town, zip, address_complement } = this.props.App.story;

		console.log(address_complement);
		return (
			<div className="recap col-12 col-xl-5">
				<div className="container">
					<h3>{Util.loc("Mon adresse")}</h3>

					<span>{street}</span>
					<span>
						{zip} {town}
					</span>
					<span className="mt-3">{address_complement}</span>
				</div>

				<Link className="mt-5" to="/PURCHASE_FORM">
					{Util.loc("Modifier")}
				</Link>
			</div>
		);
	}

	pricePopup() {
		this.props.App.popup({
			icon: "ok",
			text: Util.loc(
				"Les frais d’expédition couvrent la préparation de la commande, la mise sous pli et l’affranchissement"
			),
			yes: {
				text: Util.loc("Ok"),
				value: true,
			},
		});
	}
	renderItem(purchaseItems, c) {
		if (!c.quantity) return 0;
		let price = 0;
		let product = PRODUCTS[c.productId];
		if (product.stripe)
			price =
				c.quantity *
				this.props.App.pricesGet()[product.stripe[c.color]];
		purchaseItems.push(
			<div key={c.productId} className="delivery">
				<div>
					<span>{product.name}</span>
					{product.maxQuantity && <span>&nbsp;x {c.quantity}</span>}
				</div>
				<span>{Util.nicePrice(price)}</span>
			</div>
		);
		return price;
	}
	renderPurchaseRecap() {
		const { content, shipping } = this.props.App.storyGet({
			shipping: 0,
			content: [],
		});

		let totalPrice = 0;
		let purchaseItems = [];
		for (let c of content) totalPrice += this.renderItem(purchaseItems, c);
		totalPrice += shipping;

		return (
			<div className="purchase-recap">
				<div>
					<h3>{Util.loc("Ma commande")}</h3>
					{purchaseItems}
					<hr />
					<div className="delivery">
						<span>
							{Util.loc("Frais de livraison")}:
							{shipping ? (
								<span
									className="tooltipInfo"
									onClick={() => this.pricePopup()}
									style={{ cursor: "pointer" }}
								>
									?
								</span>
							) : null}
						</span>
						<span>{Util.nicePrice(shipping)}</span>
					</div>
					<div className="total-price">
						<span>{Util.loc("Total")}:</span>
						<span>{Util.nicePrice(totalPrice)}</span>
					</div>
					<div id="buttons-container">
						<div>
							<ChevRetour to={Util.PURCHASE_FORM} />
							<Button
								onClick={this.onButtonClicked}
								enabled
								className="step long-text"
							>
								{Util.loc("Commander")}
							</Button>
						</div>
					</div>
				</div>
			</div>
		);
	}

	render() {
		const { App } = this.props;

		//		console.log(App.story);

		return (
			<Page
				App={App}
				title={Util.loc("Récapitulatif")}
				headerTheme="light"
			>
				<div id="step-container">
					<div>
						<section id="purchase-confirm">
							<div className="row d-flex flex-row justify-content-between">
								<div className="col-12 p-4">
									<div className="row d-flex flex-row justify-content-between">
										{this.renderMyIdentityRecap()}
										{this.renderAddressRecap()}
									</div>
									<div className="row">
										{this.renderPurchaseRecap()}
									</div>
								</div>
							</div>
						</section>
					</div>
				</div>
			</Page>
		);
	}
}

PurchaseConfirm.propTypes = {
	App: Proptypes.object.isRequired,
};

export default PurchaseConfirm;
