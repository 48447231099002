import React, { Component } from "react";

import Util from "../lib/Util";
import { customHistory } from "../index";

import BlueContainer from "./BlueContainer";
import Footer from "./Footer";

class BottomPage extends Component {
	render() {
		return (
			<>
				<BlueContainer
					title={Util.loc("Vous avez une question ?")}
					text={Util.loc(
						"<p>Si vous avez besoin d’aide, n’hésitez pas à nous contacter : <strong>toute l’équipe SPOORS se tient à votre disposition.<strong/></p>"
					)}
					btnText={Util.loc("Contactez notre équipe")}
					btnAction={() => customHistory.push(Util.HOME_CONTACT)}
				/>
				<Footer />
			</>
		);
	}
}

export default BottomPage;
