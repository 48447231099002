import React from "react";
import Proptypes from "prop-types";
import { Link } from "react-router-dom";

import Util from "./lib/Util";
import Api from "./lib/Api.js";

import CenteredContainer from "./Components/CenteredContainer";
import TopMenu from "./Components/TopMenu";
import ConsumSituation from "./Components/ConsumSituation";

import ArrowLeft from "../src/style/img/icon-arrow-left.svg";
import ArrowUp from "./style/img/arrow-up-blue.svg";
import ArrowDown from "./style/img/arrow-down-blue.svg";
import BottomPage from "./Components/BottomPage";

class AccountConsum extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			events: [],
			isDetailShow: false,
			isGestionShow: true,
		};
		new Api().fetch("consumList", {}, result => {
			//			console.log(result);
			if (!result) return this.props.App.alert();
			if (result.err) return this.props.App.alert(result.err, result.msg);
			this.setState({ events: result.events });
		});
	}
	select(elem) {
		console.log("select " + elem.id);
		this.props.App.go(Util.BELL_EDIT + "/" + elem.code);
	}

	setPolicy(val) {
		this.props.App.setPolicy(val);
	}

	renderSwitch(elem) {
		switch (elem.data) {
			case "welcome":
				return Util.loc("unités de bienvenue offertes");
			case "buy":
				return Util.loc("achat d'unité(s)");
			case "claim":
				return elem.code
					? Util.fillStr(
							Util.loc(
								"unités offertes lors de l'activation de '[nom]'"
							),
							{ nom: Util.bellTitle(elem) }
					  )
					: Util.loc(
							"unités offertes lors de l'activation d'un SPOORS résilié"
					  );
			case "sms":
				return elem.code
					? Util.fillStr(Util.loc("'[nom]' : envoi d'un SMS"), {
							nom: Util.bellTitle(elem),
					  })
					: Util.loc("SPOORS résilié: envoi d'un SMS");
			case "call":
				return elem.code
					? Util.fillStr(
							Util.loc(
								"[nom]: appel de [nbUnits]\u00a0minute(s)"
							),
							{
								nom: Util.bellTitle(elem),
								nbUnits: Math.abs(elem.units),
							}
					  )
					: Util.fillStr(
							Util.loc(
								"SPOORS résilié: appel de [nbUnits]\u00a0minute(s)"
							),
							{
								nbUnits: Math.abs(elem.units),
							}
					  );
			default:
				return Util.loc("unknown");
		}
	}

	render() {
		const { App } = this.props;
		let events = this.state.events;
		let policy = this.props.App.state.me.policy;
		//		bells = bells.concat(bells).concat(bells);
		let items = [
			{
				num: 1,
				optionText: Util.loc("Option 1 :"),
				mainText: Util.loc("je reste anonyme :"),
				subText: Util.loc(
					"je reçois uniquement des alertes par emails."
				),
				policy: Util.POLICY_ANONYMOUS_ALWAYS,
			},
			{
				num: 2,
				optionText: Util.loc("Option 2 :"),
				mainText: Util.loc("je lève l’anonymat :"),
				subText: Util.loc(
					"je continue de recevoir des alertes par téléphone ou SMS, mais mon numéro sera affiché."
				),
				policy: Util.POLICY_ANONYMOUS_BEST,
			},
		];
		return (
			<CenteredContainer id="page" className="screen-container">
				<div id="board" className="claimBackground">
					<TopMenu App={App} />
					<div className="row d-flex flex-row justify-content-center inputTop">
						<div className="col-12 col-md-10 col-lg-6 ">
							<div className="d-flex align-items-center justify-content-between padd20">
								<Link to={Util.BOARD}>
									<img src={ArrowLeft} alt="ArrowLeft" />
								</Link>
								<label className="form-control">
									{Util.loc("DETAIL CONSO")}
								</label>
							</div>
						</div>
					</div>
					<div id="board" className="row  pb-block">
						<div className="col-12 col-sm-10 col-md-8 col-lg-6 text-center mx-auto">
							<ConsumSituation />
							<div className="text-left">
								<div
									className="col-12 list-row pointer"
									onClick={() =>
										this.setState({
											isDetailShow: !this.state
												.isDetailShow,
										})
									}
								>
									<span className="label-list">
										{Util.loc("Détails de ma consommation")}
									</span>
									<div>
										<img
											src={
												this.state.isDetailShow
													? ArrowDown
													: ArrowUp
											}
											alt={
												this.state.isDetailShow
													? "ArrowDown"
													: "ArrowUp"
											}
										/>
									</div>
								</div>
								{this.state.isDetailShow && events
									? events.map((elem, index) => {
											return (
												<div
													className="col-12 mx-auto mb-3 mt-30"
													key={index}
													onClick={() =>
														this.select(elem)
													}
													style={{
														cursor: "pointer",
													}}
												>
													<div className="detail-block">
														<label className="label-date">
															{Util.timeFormat2(
																elem.time
															)}
														</label>
														<label className="label-bell-title">
															{this.renderSwitch(
																elem
															)}
															&nbsp;
														</label>
														<label className="label-unit">
															{elem.units > 0
																? Util.fillStr(
																		Util.loc(
																			"+[nbUnits]\u00a0unité(s)"
																		),
																		{
																			nbUnits:
																				elem.units,
																		}
																  )
																: Util.fillStr(
																		Util.loc(
																			"[nbUnits]\u00a0unité(s)"
																		),
																		{
																			nbUnits:
																				elem.units,
																		}
																  )}
														</label>
													</div>
												</div>
											);
									  })
									: null}
								{/* <div
									className="col-12 list-row pointer"
									onClick={() =>
										this.setState({
											isGestionShow: !this.state
												.isGestionShow,
										})
									}
								>
									<span className="label-list">
										{Util.loc("Gestion de ma consommation")}
									</span>
									<div>
										<img
											src={
												this.state.isGestionShow
													? ArrowDown
													: ArrowUp
											}
											alt={
												this.state.isGestionShow
													? "ArrowDown"
													: "ArrowUp"
											}
										/>
									</div>
								</div>
								{this.state.isGestionShow ? (
									<div className="col-12">
										<label className="pt-4">
											<strong>
												{Util.loc(
													"Si je ne dispose pas d'unités pour anonymiser mes appels et que je ne souhaite pas en racheter, j'ai le choix entre deux options."
												)}
											</strong>
										</label>

										{items.map((item, index) => (
											<div
												className="consum-radio-groupe"
												key={index}
											>
												<label
													className="consum-label-radio"
													htmlFor={
														"option-" + item.num
													}
												>
													{item.optionText + " "}
													<strong>
														{item.mainText + " "}
													</strong>
													{item.subText}
												</label>
												<input
													type="radio"
													id={"option-" + item.num}
													name={"option-" + item.num}
													checked={
														policy === item.policy
													}
													onChange={() =>
														this.setPolicy(
															item.policy
														)
													}
												/>
											</div>
										))}
										<label className="pt-4">
											<strong>
												{Util.loc(
													"Seules les unités disponibles sont consommées !"
												)}
											</strong>
										</label>
									</div>
								) : null} */}
							</div>
						</div>
					</div>
				</div>
				<BottomPage />
			</CenteredContainer>
		);
	}
}

AccountConsum.propTypes = {
	App: Proptypes.object.isRequired,
};

export default AccountConsum;
