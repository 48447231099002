import React from "react";
import Util from "./lib/Util";

import Api from "./lib/Api.js";
import "../node_modules/bootstrap/js/src/tab";
import "../node_modules/bootstrap/js/src/modal";

import CenteredContainer from "./Components/CenteredContainer";
import TopMenu from "./Components/TopMenu";

import DisplayInformations from "./Components/DisplayInformations";
import BottomPage from "./Components/BottomPage";

class Account extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loaded: false,
		};
		this.load();
	}
	load() {
		new Api().fetch("accountEdit", {}, result => {
			if (!result) return this.props.App.alert();
			if (result.err) return this.props.App.alert(result.err, result.msg);
			this.props.App.storySet(result.story);
			this.props.App.partnersSet(result.partners);
			this.setState({ loaded: true });
		});
	}
	notify(readyToProcess) {
		this.forceUpdate();
	}
	buttonBackground(type) {
		if (type === this.state.submenu)
			return {
				background: "#8f8",
			};
		return {};
	}
	render() {
		const { App } = this.props;

		let loaded = this.state.loaded;
		return (
			<CenteredContainer id="page" className="screen-container">
				<div
					id="account"
					className="claimBackground"
					style={{ minHeight: loaded ? null : "100vh" }}
				>
					<TopMenu App={App} />
					<div className="row d-flex flex-row justify-content-center inputTop">
						<div className="col-12 col-md-10 col-lg-6 ">
							<div className="d-flex align-items-center justify-content-between padd20">
								<label className="form-control formControlStep ">
									{Util.loc("MON PROFIL")}
								</label>
							</div>
						</div>
					</div>

					{loaded && (
						<div id="my-profile" className="row containt pt-3">
							<section className="col-12 col-sm-10 col-md-8 col-lg-6 mx-auto block pt-block">
								<div className="text-center row">
									<div className="w-100">
										<div
											className="tab-content"
											id="pills-tabContent"
										>
											<div
												className="tab-pane fade active show"
												id="pills-info"
												role="tabpanel"
												aria-labelledby="pills-info-tab"
											>
												<DisplayInformations
													App={App}
													form={this}
												/>
											</div>
										</div>
									</div>
								</div>
							</section>
						</div>
					)}
				</div>
				<BottomPage />
			</CenteredContainer>
		);
	}
}
export default Account;
