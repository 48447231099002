import React from "react";
import Proptypes from "prop-types";

import Util from "../lib/Util";

class FormIdentity extends React.Component {
	constructor(props) {
		super(props);
		this.count = 0;
		let data = {
			properso: 0,
			gender: 0,
			firstname: "",
			lastname: "",
			csp: "",
			role: "",
		};
		this.state = this.props.App.storyGet(data);

		console.log("-------------------Formidentity");
		console.log(JSON.stringify(this.state));
		this.sociopro = {
			"": Util.loc("Ma situation personnelle"),
			AGR: Util.loc("Agriculteurs exploitants"),
			ART: Util.loc("Artisans, commerçants, chefs d'entreprise"),
			CAD: Util.loc("Cadres et professions intellectuelles supérieures"),
			INT: Util.loc("Professions intermédiaires"),
			EMP: Util.loc("Employés"),
			OUV: Util.loc("Ouvriers"),
			RET: Util.loc("Retraités"),
			AUT: Util.loc("Autres personnes sans activité professionnelle"),
		};
		this.roles = {
			"": Util.loc("Fonction"),
			DIR: Util.loc("Direction"),
			MRK: Util.loc("Marketing"),
			TEC: Util.loc("Technique"),
		};
	}
	onBlur(field) {
		let value = this.state[field].trim();
		this.onChange(value, field, true);
	}
	onChange(value, field, process) {
		value = Util.nice(value, field);
		let update = {};
		update[field] = value;
		this.setState(update);
		this.props.App.storyUpdate(update);
		if (this.props.form) this.props.form.notify(process);
	}
	render() {
		let showCsp = false;
		let showRole = false;
		if (!this.props.simple) {
			if (this.state.properso === Util.TYPE_PRO) showRole = true;
			else showCsp = true;
			if (this.props.purchase) showCsp = false;
		}
		return (
			<div className="w-100">
				<div className="row form-category-title">
					<div className="col-12 px-0">
						<input
							type="text"
							autoComplete={Util.random()}
							spellCheck={false}
							className="form-profil-information"
							id="first-name"
							placeholder={""}
							required
							value={this.state.firstname}
							onKeyPress={event => {
								if (event.key === "Enter") event.target.blur();
							}}
							onChange={event => {
								this.onChange(event.target.value, "firstname");
							}}
							onBlur={event => {
								this.onBlur("firstname");
							}}
						/>
						<label htmlFor="first-name">
							{Util.loc("Prénom *")}
						</label>
						<div
							style={Util.check(
								this.state.firstname,
								this.props.withNext
							)}
							className="invalid-tooltip"
						>
							{Util.loc("Veuillez remplir ce champ correctement")}
						</div>
					</div>
				</div>
				<div className="row form-category-title">
					<div className="col-12 px-0">
						<input
							type="text"
							autoComplete={Util.random()}
							spellCheck={false}
							className="form-profil-information"
							id="name"
							placeholder={""}
							required
							value={this.state.lastname}
							onKeyPress={event => {
								if (event.key === "Enter") event.target.blur();
							}}
							onChange={event => {
								this.onChange(event.target.value, "lastname");
							}}
							onBlur={event => {
								this.onBlur("lastname");
							}}
						/>
						<label htmlFor="name">{Util.loc("Nom *")}</label>
						<div
							style={Util.check(
								this.state.lastname,
								this.props.withNext
							)}
							className="invalid-tooltip"
						>
							{Util.loc("Veuillez remplir ce champ correctement")}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

FormIdentity.propTypes = {
	App: Proptypes.object.isRequired,
	purchase: Proptypes.bool,
	form: Proptypes.object,
	withText: Proptypes.bool,
};

export default FormIdentity;
