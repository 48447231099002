import React from "react";
import Proptypes from "prop-types";

import Util from "./lib/Util";
import { Link } from "react-router-dom";

import { PRODUCTS } from "./lib/products";

import Page from "./Components/Page";
import ProductsSlideShow from "./Components/ProductsSlideShow";
import PurchaseProduct from "./Components/PurchaseProduct";
import FormEmail from "./Components/FormEmail";
import FormIdentity from "./Components/FormIdentity";
import FormAddress from "./Components/FormAddress";
import FormCompany from "./Components/FormCompany";
import Button from "./Components/Button";
import { customHistory } from "./index";

class PurchaseForm extends React.Component {
	constructor(props) {
		super(props);
		this.props.App.storyNeed("/PURCHASE", Util.HOME);

		this.state = this.props.App.storyGet({
			email: "",
			content: [],
			properso: 0,
			provider: "",
			productId: "",
			optinSend: false,
			optinReceive: false,
			cgu: false,
			cpv: false,
		});
		if (this.state.content.length) return;
		let content = [];
		if (this.state.provider) {
			this.initContent(content, Util.ENGIE_BELL, "blue", 1);
			this.initContent(content, Util.ENGIE_VIGNETTE, "blue", 1);
		} else {
			// this.initContent(content, Util.SPOORS_BELL, "green", 0);
			// this.initContent(content, Util.SPOORS_VIGNETTE, "orange", 0);
			// this.initContent(content, Util.SPOORS_TAG, "blue", 0);
		}
		this.state.content = content;
		this.props.App.storyUpdate({ content: content });
	}
	initContent(content, productId, color, quantity) {
		let product = PRODUCTS[productId];
		let stripe = false;
		if (product.stripe) stripe = product.stripe[color];
		content.push({ productId, color, quantity, stripe });
	}
	onChange(value, field) {
		let update = { [field]: Util.nice(value, field) };
		this.setState(update);
		this.props.App.storyUpdate(update);
	}
	changeQuantity(value, c0) {
		let story = this.props.App.storyGet();
		for (let c of story.content)
			if (c.productId === c0.productId && c.color === c0.color)
				c.quantity = value;

		this.setState({ content: story.content });
		this.props.App.storyUpdate({ content: story.content });
	}

	goNext = e => {
		let total = 0;
		for (let c of this.state.content) total += c.quantity;

		if (total === 0)
			return this.props.App.alert(
				-1,
				Util.loc(
					"Vous devez commander au moins une sonnette ou vignette !"
				)
			);
		if (this.props.App.checkPurchaseForm()) return;

		// First we check if the user is registered
		if (this.props.App.isRegistered())
			return customHistory.push(Util.PURCHASE_CONFIRM);
		this.props.App.storyProcess();
	};

	notify(readyToProcess) {
		this.forceUpdate();
	}

	renderTitle() {
		const { provider } = this.state;

		return provider !== undefined
			? Util.loc("Ma commande inclut")
			: Util.loc("Je visualise ma commande");
	}

	renderProducts() {
		const { content, productId } = this.state;
		let cards = [];
		let defaultIndex = 0;
		let index = 0;
		for (let c of content) {
			let product = PRODUCTS[c.productId];

			if (product.id === this.props.App.defaultProduct())
				defaultIndex = index;
			index++;

			cards.push(
				<PurchaseProduct
					App={this.props.App}
					key={cards.length}
					onChange={val => this.changeQuantity(val, c)}
					product={product}
					currentQuantity={c.quantity}
					className={c.color}
				/>
			);
		}
		if (cards.length == 2)
			return <div className="provider-products">{cards}</div>;
		return (
			<ProductsSlideShow
				className="spoors-products"
				products={cards}
				defaultIndex={defaultIndex}
			/>
		);
	}

	render() {
		const { App } = this.props;
		const registered = App.isRegistered();

		return (
			<Page App={App} title={this.renderTitle()} headerTheme="light">
				<div id="step-container">
					<div>
						<section id="purchase-form" className="px-1">
							{this.renderProducts()}
							{registered ? null : (
								<FormEmail
									onChange={event => {
										this.onChange(
											event.target.value,
											"email"
										);
									}}
									value={this.state.email}
								/>
							)}
							{registered ? null : (
								<FormIdentity
									App={this.props.App}
									purchase={true}
									form={this}
									withNext={true}
								/>
							)}
							{this.state.properso === Util.TYPE_PRO ? (
								<FormCompany
									App={this.props.App}
									form={this}
									withNext={true}
								/>
							) : null}
							<FormAddress
								App={this.props.App}
								properso={this.state.properso}
								headLine={Util.loc("Mon adresse")}
								form={this}
							/>
							{(!registered || this.state.cpv) && (
								<div className="row mt-30">
									<div className="col-12 m-auto">
										<div className="custom-control custom-checkbox">
											<input
												type="checkbox"
												className="custom-control-input"
												id="cgu-part"
												checked={this.state.cgu}
												onChange={event => {
													this.onChange(
														event.target.checked,
														"cgu"
													);
												}}
											/>
											<label
												className="custom-control-label text-left worksans-light"
												htmlFor="cgu-part"
											>
												{Util.loc(
													"Je déclare avoir pris connaissance des CGV et CGU et de la politique de confidentialité consultables"
												) + " "}
												<Link
													to={
														Util.HOME_CGV +
														(this.state.cpv
															? "/" +
															  this.state.cpv
															: "")
													}
												>
													{Util.loc("ici")}
												</Link>
												{" " +
													Util.loc(
														"et les accepter sans réserve."
													)}
											</label>
										</div>
										{false ? (
											<React.Fragment>
												{" "}
												<div className="custom-control custom-checkbox">
													<input
														type="checkbox"
														className="custom-control-input"
														id="transfert-part"
														checked={
															this.state
																.optinSend !==
															false
														}
														onChange={event => {
															this.onChange(
																event.target
																	.checked,
																"optinSend"
															);
														}}
													/>
													<label
														className="custom-control-label"
														htmlFor="transfert-part"
													>
														{Util.loc(
															"j'accepte que mes informations soient transférées à des"
														) + " "}
														<span
															style={{
																color:
																	"#26C5F0",
																cursor:
																	"pointer",
															}}
															onClick={() =>
																this.goDetail(
																	"optinSend"
																)
															}
														>
															{Util.loc(
																"partenaires"
															)}
														</span>
														{" " +
															Util.loc(
																"de SPOORS pour mettre à jour les données clients"
															)}
													</label>
												</div>
												<div className="custon-control custom-checkbox">
													<input
														type="checkbox"
														className="custom-control-input"
														id="mail-part"
														checked={
															this.state
																.optinReceive !==
															false
														}
														onChange={event => {
															this.onChange(
																event.target
																	.checked,
																"optinReceive"
															);
														}}
													/>
													<label
														className="custom-control-label"
														htmlFor="mail-part"
													>
														{Util.loc(
															"j'accepte de recevoir par email les propositions des"
														) + " "}
														<span
															style={{
																color:
																	"#26C5F0",
																cursor:
																	"pointer",
															}}
															onClick={() =>
																this.goDetail(
																	"optinReceive"
																)
															}
														>
															{Util.loc(
																"partenaires"
															)}
														</span>
														{" " +
															Util.loc(
																"de SPOORS"
															)}
													</label>
												</div>
											</React.Fragment>
										) : null}
									</div>
								</div>
							)}
							<div id="buttons-container">
								<div>
									<Button
										onClick={this.goNext}
										enabled={!App.checkPurchaseForm()}
										className="step"
									>
										{Util.loc("Suivant")}
									</Button>
								</div>
							</div>
						</section>
					</div>
				</div>
			</Page>
		);
	}
}

PurchaseForm.propTypes = {
	App: Proptypes.object.isRequired,
};

export default PurchaseForm;
