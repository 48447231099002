import Util from "../lib/Util.js";

class Api {
	fetch(action, data, cb, silent) {
		if (!silent) Util.App.apiStart();
		let url =
			Util.URL +
			"api.php?version=" +
			Util.VERSION +
			"&lang=" +
			Util.LANG +
			"&action=" +
			action;
		let post = "";
		let formData = new FormData();
		for (let k in data) formData.append(k, data[k]);
		for (let k in data)
			post +=
				"&" +
				k +
				"=" +
				encodeURIComponent(data[k]).replace(/%20/g, "+");
		console.log(url + post);

		let done = false;
		setTimeout(() => {
			if (done) return;
			done = true;
			console.log("--API timeout");
			if (!silent) Util.App.apiDone();
			if (cb) cb();
		}, Util.HTTP_TIMEOUT);

		fetch(url, {
			method: "post",
			body: formData,
			credentials: "include",
			mode: "cors",
		})
			.then(function (response) {
				if (true) return response.json();
				let txt = response.text();
				console.log(txt);
				return JSON.parse(txt);
			})
			.then(
				result => {
					if (done) return;
					done = true;
					console.log("--API", JSON.stringify(result));
					if (!silent) Util.App.apiDone();
					if (cb) cb(result);
				},
				error => {
					if (done) return;
					done = true;
					console.log("--API error", error);
					if (!silent) Util.App.apiDone();
					if (cb) cb();
				}
			);
	}
}

export default Api;
