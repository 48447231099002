import React from "react";
import Proptypes from "prop-types";

import Util from "../lib/Util";

import IcoEdit from "../style/img/ico-edit.png";

class DisplayIdentity extends React.Component {
	constructor(props) {
		super(props);
		this.count = 0;
		let data = {
			properso: 0,
			gender: 0,
			firstname: "",
			lastname: "",
			csp: "",
			role: "",
		};
		this.state = this.props.App.storyGet(data);

		console.log("-------------------Formidentity");
		console.log(JSON.stringify(this.state));
		this.sociopro = {
			"": Util.loc("Ma situation personnelle"),
			AGR: Util.loc("Agriculteurs exploitants"),
			ART: Util.loc("Artisans, commerçants, chefs d'entreprise"),
			CAD: Util.loc("Cadres et professions intellectuelles supérieures"),
			INT: Util.loc("Professions intermédiaires"),
			EMP: Util.loc("Employés"),
			OUV: Util.loc("Ouvriers"),
			RET: Util.loc("Retraités"),
			AUT: Util.loc("Autres personnes sans activité professionnelle"),
		};
		this.roles = {
			"": Util.loc("Fonction"),
			DIR: Util.loc("Direction"),
			MRK: Util.loc("Marketing"),
			TEC: Util.loc("Technique"),
		};
	}

	render() {
		let showCsp = false;
		let showRole = false;
		if (!this.props.simple) {
			if (this.state.properso === Util.TYPE_PRO) showRole = true;
			else showCsp = true;
			if (this.props.purchase) showCsp = false;
		}
		return (
			<div className="w-100">
				<div className="row">
					<div className="col-12 px-0">
						<div className="category-title">
							<label>{Util.loc("PRÉNOM")}</label>
							<div className="text-right">
								<button
									className="btn-edit-profil"
									onClick={() =>
										this.props.App.go(Util.ACCOUNT_EDIT)
									}
								>
									<img src={IcoEdit} />
									<span className="text-dark align-middle">
										{Util.loc("Éditer")}
									</span>
								</button>
							</div>
						</div>
						<label className="profil-information">
							{this.state.firstname}
						</label>
					</div>
				</div>
				<div className="row">
					<div className="col-12 px-0">
						<label className="category-title">
							{Util.loc("NOM")}
						</label>
						<label className="profil-information">
							{this.state.lastname}
						</label>
					</div>
				</div>
			</div>
		);
	}
}

DisplayIdentity.propTypes = {
	App: Proptypes.object.isRequired,
	purchase: Proptypes.bool,
	form: Proptypes.object,
	withText: Proptypes.bool,
};

export default DisplayIdentity;
