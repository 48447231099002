import React from "react";
import { Link } from "react-router-dom";
import Proptypes from "prop-types";

import Util from "./lib/Util";
import Api from "./lib/Api.js";

import "bootstrap/js/src/dropdown";
import { Container, Draggable } from "react-smooth-dnd";

import CenteredContainer from "./Components/CenteredContainer";
import TopMenu from "./Components/TopMenu";
import Footer from "./Components/Footer";

import BoutonRevenir from "./Components/BoutonRevenir";
import ButtonSuivant from "./Components/ButtonSuivant";
import Visits from "./Components/Visits";

// Svg
import Pencil from "../src/style/img/pencil.svg";
import Call from "../src/style/img/icon-call.svg";
import CallNo from "../src/style/img/icon-call-no.svg";
import Mail from "../src/style/img/icon-mail.svg";
import MailNo from "../src/style/img/icon-mail-no.svg";
import Sms from "../src/style/img/icon-sms.svg";
import SmsNo from "../src/style/img/icon-sms-no.svg";
import Plus from "../src/style/img/icon-plus.svg";
import ArrowLeft from "../src/style/img/icon-arrow-left.svg";
import EditContour from "../src/style/img/icon-edit-contour.svg";

class BellEdit extends React.Component {
	constructor(props) {
		super(props);

		if (
			props.App.storyNeed("/BELL_UPDATE") &&
			(!props.code || props.App.storyGet().code == props.code)
		) {
			this.state = this.props.App.storyGet({
				code: "",
				title: "",
				welcome: "",
				alerts: [],
				alertId: 0,
				isFilter: false,
			});
			if (!this.state.alertId && this.state.alerts.length)
				setTimeout(() => this.openAlert(this.state.alerts[0].id), 0);
		} else {
			this.state = {};
			this.load(this.props.code);
		}
		this.setIsFilter = this.setIsFilter.bind(this);
	}

	load(code) {
		new Api().fetch("bellEdit", { code: code }, result => {
			if (!result) return this.props.App.alert();
			if (result.err) return this.props.App.alert(result.err, result.msg);
			this.props.App.storySet(result.story);
			this.setState(
				this.props.App.storyGet({
					code: "",
					title: "",
					welcome: "",
					alerts: [],
				})
			);
			console.log("load alerts ", result.story.alerts.length);
			if (result.story.alerts.length)
				this.openAlert(result.story.alerts[0].id);
		});
	}

	changeField(value, field) {
		let update = { [field]: value };
		this.setState(update);
		this.props.App.storyUpdate(update);
	}

	onBlur = () => {
		//TODO check
		this.props.App.storyProcess();
	};

	notify(readyToProcess) {
		this.forceUpdate();
	}

	openAlert(id) {
		this.setState({ alertId: id });
		this.props.App.storyUpdate({ alertId: id });
	}

	addAlert() {
		this.props.App.storyUpdate({ alertId: Util.ALERT_LAST });
		this.props.App.go(
			this.props.claim_alert ? Util.CLAIM_ALERT : Util.BELL_ALERT
		);
	}

	alertEdit() {
		this.props.App.go(
			this.props.claim_alert ? Util.CLAIM_ALERT : Util.BELL_ALERT
		);
	}

	resign() {
		this.props.App.bellResign();
	}

	reorderAlerts(dragResult) {
		let alerts = this.state.alerts;
		const { removedIndex, addedIndex, payload } = dragResult;
		if (removedIndex === null && addedIndex === null) return;

		const result = [...alerts];
		let itemToAdd = payload;

		if (removedIndex !== null) {
			itemToAdd = result.splice(removedIndex, 1)[0];
		}

		if (addedIndex !== null) {
			result.splice(addedIndex, 0, itemToAdd);
		}
		result.map((item, index) => {
			result.order = index + 1;
		});

		let update = { alerts: result };
		this.setState(update);
		this.props.App.storyUpdate(update);
		this.props.App.storyProcess();
	}

	setIsFilter(val) {
		this.setState({ isFilter: val });
	}

	render() {
		const {
			App,
			className,
			bell_edit,
			claim_welcome,
			claim_alert,
		} = this.props;
		let alerts = this.state.alerts;
		let loaded = alerts;
		//		loaded = false;
		let showTop = bell_edit;
		let showWelcome = loaded && (bell_edit || claim_welcome);
		let showAlert = loaded && (bell_edit || claim_alert);
		let showScan = loaded && bell_edit;
		let showResign = loaded && bell_edit;
		let showNav = claim_alert || claim_welcome;
		let showBackward = claim_alert ? Util.CLAIM_WELCOME : null;
		let showForward = claim_alert ? Util.BOARD : Util.CLAIM_ALERTS;

		return (
			<CenteredContainer id="page" className={className ?? ""}>
				<div
					id="bell-edit"
					className="claimBackground"
					style={{ minHeight: loaded ? null : "100vh" }}
				>
					<TopMenu App={App} />
					{showTop ? (
						<>
							<div className="row d-flex flex-row justify-content-center inputTop">
								<div className="col-12 col-md-10 col-lg-6 ">
									<div className="d-flex align-items-center justify-content-between padd20">
										<Link to={Util.BOARD}>
											<img src={ArrowLeft} />
										</Link>
										<input
											ref={element => {
												this.titleInput = element;
											}}
											type="text"
											spellCheck={false}
											className="form-control formControlStep "
											id="title"
											placeholder={""}
											required
											value={this.state.title}
											onKeyPress={event => {
												if (event.key === "Enter")
													event.target.blur();
											}}
											onChange={event => {
												this.changeField(
													event.target.value,
													"title"
												);
											}}
											onBlur={() => {
												this.onBlur();
											}}
										/>
										<div
											onClick={e =>
												this.titleInput.focus()
											}
										>
											<img src={EditContour} />
										</div>
									</div>
								</div>
							</div>
						</>
					) : null}
					<div className="padd20">
						{showWelcome ? (
							<div className="row d-flex flex-row justify-content-center">
								<div className="col-12 col-md-10 col-lg-6 margin-title">
									<p className="etape text-left mt-5">
										{Util.loc("MESSAGE DE BIENVENUE")}
									</p>
									<p className="text-left edit-info">
										{Util.loc(
											"Je peux modifier le message que verra la personne qui scanne mon SPOORS."
										)}
									</p>

									<div className="claim-textarea">
										<textarea
											className="text-center mx-auto"
											rows="3"
											value={this.state.welcome}
											onChange={event => {
												this.changeField(
													event.target.value,
													"welcome"
												);
											}}
											onBlur={() => {
												this.onBlur();
											}}
										/>
									</div>
									<div
										style={Util.check(this.state.welcome)}
										className="invalid-tooltip"
									>
										{Util.loc(
											"Veuillez remplir ce champ correctement"
										)}
									</div>
								</div>
							</div>
						) : null}
						{showAlert ? (
							<>
								<div className="row d-flex flex-row justify-content-center mt-5">
									<div className="col-12 col-md-10 col-lg-6 margin-title">
										<p className="etape text-left">
											{Util.loc("ALERTES")}
										</p>
										<p className="text-left edit-info">
											{Util.loc(
												"Je choisis l'ordre et les personnes à contacter lorsqu'un utilisateur scanne le SPOORS"
											)}
										</p>
										<Container
											dragHandleSelector=".column-drag-handle"
											onDrop={e => {
												console.log(e);
												console.log(
													JSON.stringify(alerts)
												);
												this.reorderAlerts(e);
												/*							let items = applyDrag(this.state.items, e);
							items.map((item, index) => {
								item.num = index;
							});
							console.log(JSON.stringify(items));
							this.setState({
								items: items,
							});
						*/
											}}
										>
											{alerts.map((alert, index) => {
												let activePhone =
													alert.type &
													Util.ALERT_PHONE;
												let activeEmail =
													alert.type &
													Util.ALERT_EMAIL;
												let activeSms =
													alert.type & Util.ALERT_SMS;
												let anonym =
													alert.policy !==
													Util.POLICY_ANONYMOUS_NEVER
														? "ON"
														: "OFF";
												let temporary = alert.temporary;
												let label = alert.label;
												if (
													label ===
													this.props.App.firstname()
												)
													label +=
														" (" +
														Util.loc("moi") +
														")";
												if (
													alert.id ==
													this.state.alertId
												)
													return (
														<Draggable key={index}>
															<div
																key={index}
																className="btn-rattachement mb-1 text-left btn-height"
															>
																<div
																	className="name-edit"
																	onClick={() =>
																		this.openAlert(
																			0
																		)
																	}
																>
																	<p className="name">
																		<span
																			className="column-drag-handle"
																			style={{
																				float:
																					"left",
																				padding:
																					"0 10px",
																				marginRight:
																					"58px",
																				cursor:
																					"grab",
																			}}
																		>
																			{/* &#x2630; */}
																			&#x205D;
																		</span>
																		{label}
																	</p>
																	<div
																		onClick={event => {
																			event.stopPropagation();
																			this.alertEdit(
																				alert
																			);
																		}}
																	>
																		<button className="btn-edit">
																			<img
																				src={
																					Pencil
																				}
																				alt="icon stylo"
																			/>
																		</button>
																	</div>
																</div>
																<div className="btn-edit-info pb-3">
																	{temporary ? (
																		<p>
																			{Util.fillStr(
																				Util.loc(
																					"Valide jusqu'au [date]"
																				),
																				{
																					date: Util.timeFormat(
																						temporary
																					),
																				}
																			)}
																		</p>
																	) : null}
																	<div>
																		<strong>
																			<div>
																				{Util.loc(
																					"Par téléphone"
																				)}
																			</div>
																		</strong>
																	</div>
																	{activePhone ? (
																		<div>
																			<p className="info-edit tel-edit">
																				{
																					alert.phone
																				}
																			</p>
																			<p className="info-edit day-edit">
																				{temporary
																					? ""
																					: Util.schedule(
																							alert
																					  )}
																			</p>
																		</div>
																	) : (
																		<div>
																			<p className="info-edit-undefined">
																				{Util.loc(
																					"non défini"
																				)}
																			</p>
																		</div>
																	)}
																	<div>
																		<strong>
																			<div>
																				{Util.loc(
																					"Par mail"
																				)}
																			</div>
																		</strong>
																	</div>
																	{activeEmail ? (
																		<div>
																			<p className="info-edit">
																				{
																					alert.email
																				}
																			</p>
																		</div>
																	) : (
																		<div>
																			<p className="info-edit-undefined">
																				{Util.loc(
																					"non défini"
																				)}
																			</p>
																		</div>
																	)}
																	<div>
																		<strong>
																			<div>
																				{Util.loc(
																					"Par sms"
																				)}
																			</div>
																		</strong>
																	</div>
																	{activeSms ? (
																		<div>
																			<p className="info-edit">
																				{
																					alert.phone
																				}
																			</p>
																		</div>
																	) : (
																		<div>
																			<p className="info-edit-undefined">
																				{Util.loc(
																					"non défini"
																				)}
																			</p>
																		</div>
																	)}
																	<div>
																		<strong>
																			<div>
																				{Util.loc(
																					"Anonymisation des appels et SMS"
																				)}
																			</div>
																		</strong>
																	</div>

																	<div>
																		<p className="info-edit">
																			{
																				anonym
																			}
																		</p>
																	</div>
																</div>
															</div>
														</Draggable>
													);
												else
													return (
														<Draggable key={index}>
															<div
																className="btn-rattachement draggable-item text-left mb-1"
																onClick={() =>
																	this.openAlert(
																		alert.id
																	)
																}
															>
																<div
																	className="column-drag-handle"
																	style={{
																		float:
																			"left",
																		padding:
																			"0 10px",
																		marginRight:
																			"40px",
																		cursor:
																			"grab",
																		fontSize:
																			"1.7rem",
																		marginBottom:
																			"5px",
																	}}
																>
																	{/* &#x2630; */}
																	&#x205D;
																</div>
																<div className="btn-name-icon ">
																	<div className="">
																		{label}
																	</div>
																	<div className="d-flex">
																		{activePhone ? (
																			<img
																				src={
																					Call
																				}
																			/>
																		) : (
																			<img
																				src={
																					CallNo
																				}
																			/>
																		)}
																		{activeEmail ? (
																			<img
																				src={
																					Mail
																				}
																			/>
																		) : (
																			<img
																				src={
																					MailNo
																				}
																			/>
																		)}
																		{activeSms ? (
																			<img
																				src={
																					Sms
																				}
																			/>
																		) : (
																			<img
																				src={
																					SmsNo
																				}
																			/>
																		)}
																	</div>
																</div>
															</div>
														</Draggable>
													);
											})}
										</Container>
									</div>
								</div>
								<div className="row d-flex flex-row justify-content-center">
									<div className="col-12 col-md-10 col-lg-6">
										<div className="custom-btn">
											<button
												onClick={() => this.addAlert()}
												className="btn btn-border custom-contact"
											>
												<img src={Plus} />
												{Util.loc("Ajouter une alerte")}
											</button>
										</div>
									</div>
								</div>
							</>
						) : null}
						{showScan ? (
							<Visits
								App={this.props.App}
								form={this}
								setIsFilter={this.setIsFilter}
							/>
						) : null}
						{showResign && !this.state.isFilter ? (
							<div className="row">
								<div className="m-auto d-flex justify-content-center">
									<button
										onClick={() => this.resign()}
										className="btn btn-border trash-border"
									>
										{Util.loc("Résilier ce SPOORS")}
									</button>
								</div>
							</div>
						) : null}
						{showNav ? (
							<div className="row d-flex flex-row justify-content-center mt-5">
								<div className="col-12 col-md-10 col-lg-6">
									<div className="row btnRevenirSuivant withNext">
										{showBackward ? (
											<BoutonRevenir to={showBackward} />
										) : (
											<span></span>
										)}
										{showForward ? (
											<ButtonSuivant
												onClick={() =>
													App.go(showForward)
												}
												enabled={true}
												className="step"
											>
												{Util.loc("Suivant")}
											</ButtonSuivant>
										) : null}
									</div>
								</div>
							</div>
						) : null}
					</div>
				</div>
				<Footer />
			</CenteredContainer>
		);
	}
}

BellEdit.propTypes = {
	App: Proptypes.object.isRequired,
};

export default BellEdit;
