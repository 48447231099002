import React, { Component } from "react";
import Proptypes from "prop-types";

import Util from "../lib/Util";

class PurchaseProduct extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showMoreInformation: false,
		};
	}

	onSpanTapped = () => {
		this.setState(prevState => ({
			showMoreInformation: !prevState.showMoreInformation,
		}));
	};

	onQuantityChanged = e => {
		this.props.onChange(parseInt(e.currentTarget.value));
	};

	renderHeader() {
		const { icons, text } = this.props.product.card.header;

		const iconsComp = icons.map((icon, index) => (
			<img
				key={index}
				src={icon}
				alt="product icon"
				className="header-image mx-2"
			/>
		));

		return (
			<div className="header d-flex flex-row justify-content-start align-items-center">
				{iconsComp}
				<span>{text}</span>
			</div>
		);
	}

	renderInformationItems(info) {
		const characteristics = Object.keys(info);

		return characteristics.map((name, index) => {
			let itemName = name;
			itemName = itemName[0].toUpperCase() + itemName.slice(1);

			return (
				<span key={index} className="item">
					<strong>{itemName}:</strong> {info[name]}
				</span>
			);
		});
	}

	renderInformationContent() {
		const { showMoreInformation } = this.state;
		const { info } = this.props.product;
		const informationClassName = showMoreInformation ? "visible" : "";

		const informationContainer = (
			<div className={`information ${informationClassName}`}>
				{this.renderInformationItems(info)}
			</div>
		);

		return (
			<>
				{informationContainer}
				<span onClick={this.onSpanTapped}>
					{showMoreInformation
						? Util.loc("- d'informations")
						: Util.loc("+ d'informations")}
				</span>
			</>
		);
	}

	renderQuantitySelector() {
		const maxQuantity = this.props.product.maxQuantity;
		const currentQuantity = this.props.currentQuantity;

		if (!maxQuantity) return null;

		const selectItems = [];
		for (let i = 0; i <= maxQuantity; i++)
			selectItems.push(
				<option key={i} value={i}>
					{i}
				</option>
			);

		return (
			<div className="quantity-selector">
				<span>{Util.loc("Quantité :") + " "}</span>
				<select
					onChange={this.onQuantityChanged}
					value={currentQuantity}
				>
					{selectItems}
				</select>
			</div>
		);
	}

	render() {
		const { product, className } = this.props;

		if (!product) return null;

		let price = 0;
		if (product.stripe)
			price = Util.nicePrice(
				this.props.App.pricesGet()[product.stripe[product.color]]
			);
		return (
			<div className="purchase-product">
				<div className={`product-card ${className ?? ""}`}>
					{this.renderHeader()}
					<div
						className="title text-center"
						dangerouslySetInnerHTML={{ __html: product.card.title }}
					/>
					<img
						src={product.pictures.small}
						alt=""
						className="product"
					/>
					<div
						className="card-text text-center"
						dangerouslySetInnerHTML={{
							__html: product.card.description,
						}}
					/>
					{price ? (
						<div className="price mt-5">
							{Util.loc("Prix à l'unité :") + " "}
							{price}
						</div>
					) : null}
					<div className="information-container">
						{this.renderInformationContent()}
					</div>
				</div>
				{this.renderQuantitySelector()}
			</div>
		);
	}
}

PurchaseProduct.propTypes = {
	onChange: Proptypes.func,
	product: Proptypes.object.isRequired,
	currentQuantity: Proptypes.number,
	className: Proptypes.string,
};

export default PurchaseProduct;
