import React, { Component } from "react";
import Proptypes from "prop-types";

// Svg
import ChevNextIcon from "../style/img/chevron-next.svg";

class Button extends Component {
	handleClick = () => {
		const { onClick, enabled } = this.props;

		if (enabled) onClick();
	};

	render() {
		const { enabled, className, style, children, final } = this.props;
		let btnClassName;
		if (!final) {
			btnClassName = `button ${
				enabled ? "blueOk-bg" : "blueOpacity-bg"
			} ${className}`;
			return (
				<button
					onClick={this.handleClick}
					className={btnClassName}
					style={style}
				>
					{children}
					<img src={ChevNextIcon} alt="" />
				</button>
			);
		} else {
			btnClassName = `button ${
				enabled ? "orange-bg" : "orangeOpacity-bg"
			} ${className}`;

			return (
				<button
					onClick={this.handleClick}
					className={btnClassName}
					style={style}
				>
					{children}
				</button>
			);
		}
	}
}

Button.propTypes = {
	onClick: Proptypes.func.isRequired,
	enabled: Proptypes.bool.isRequired,
	className: Proptypes.string,
	style: Proptypes.object,
};

export default Button;
