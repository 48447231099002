import React from "react";

import qrcode from "../style/img/qrcodeHome.png";
import phoneLogo from "../style/img/phoneLogoHome.png";

import Util from "../lib/Util";

const HomeDescription = () => (
	<section id="home-description">
		<div className="text-center mb-4">
			<span className="title">
				{Util.loc(
					"Scannez mon SPOORS pour me contacter sans voir mon numero\u00a0!"
				)}
			</span>
		</div>
		<div className="img-col">
			<div className="column">
				<img src={qrcode} alt="QrCode"></img>
				<span className="img-title">{Util.loc("Scannez")}</span>
				<span className="img-subtitle">{Util.loc("mon SPOORS")}</span>
			</div>

			<div className="column">
				<img src={phoneLogo} alt="Appel-anonyme"></img>
				<span className="img-title">{Util.loc("Contactez-moi")}</span>
				<span className="img-subtitle">
					{Util.loc("sans voir mon numero")}
				</span>
			</div>
		</div>
	</section>
);

export default HomeDescription;
