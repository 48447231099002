import React from "react";
import { Link } from "react-router-dom";

import Util from "./lib/Util";
import Api from "./lib/Api.js";

import CenteredContainer from "./Components/CenteredContainer";
import TopMenu from "./Components/TopMenu";
import BottomPage from "./Components/BottomPage";
import UnitsSelector from "./Components/UnitsSelector";

import ArrowLeft from "./style/img/icon-arrow-left.svg";
import DialogueBubble from "./style/img/dialogue_bubble.svg";
import Glasses from "./style/img/glasses.svg";

class PurchaseUnits extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			nbUnits: "0",
			ready: false,
			packs: {},
		};

		new Api().fetch(
			"purchaseStart",
			{
				provider: "SPOORS",
				operation: "UNITS",
			},
			result => {
				if (!result) return this.props.App.alert();
				if (result.err)
					return this.props.App.alert(result.err, result.msg);
				this.props.App.storySet(result.story);
				if (result.prices) this.props.App.pricesSet(result.prices);
				this.setState({
					ready: true,
					packs: result.packs,
					nbUnits: "30",
				});
			}
		);
	}

	onChange(value) {
		this.setState({ nbUnits: value });
	}

	onBuy() {
		console.log("pack", this.state.packs[this.state.nbUnits]);
		this.props.App.storyUpdate({
			content: [this.state.packs[this.state.nbUnits]],
		});
		this.props.App.storyProcess();
	}
	render() {
		const { App } = this.props;
		return (
			<CenteredContainer id="page" className="screen-container">
				<div id="purchase-units" className="claimBackground">
					<TopMenu App={App} />
					<div className="row d-flex flex-row justify-content-center inputTop">
						<div className="col-12 col-md-10 col-lg-6 ">
							<div className="d-flex align-items-center justify-content-between padd20">
								<Link to={Util.ACCOUNT_CONSUM}>
									<img src={ArrowLeft} />
								</Link>
								<label className="form-control">
									{Util.loc("ACHETER DES UNITES")}
								</label>
							</div>
						</div>
					</div>
					<div id="purchase-units" className="row  pb-block">
						<div className="col-12 col-sm-10 col-md-8 col-lg-6 text-center mx-auto">
							<div className="text-left purchase-box">
								<div className="img-purchase-box">
									<img src={DialogueBubble} />
									<img className="glasses" src={Glasses} />
								</div>
								<div className="text-purchase-box">
									<strong>
										{Util.loc(
											"Unités SMS et appels anonymes"
										)}
									</strong>
									<br />
									{Util.loc(
										"Les unités sont utilisées pour envoyer des SMS et recevoir des appels anonymisés. 1\u00a0SMS consomme une unité. 1\u00a0mn d’appel anonyme consomme une unité."
									)}

									<div className="units-text">
										<div>{Util.loc("UNITES")}</div>
									</div>
									<div className="units-price-row">
										<UnitsSelector
											onValueChange={event =>
												this.onChange(
													event.target.value
												)
											}
											nbUnits={this.state.nbUnits}
											packs={this.state.packs}
										/>
										<div className="price-display">
											<span className="price">
												{this.state.packs[
													this.state.nbUnits
												]
													? this.props.App.pricesGet()[
															this.state.packs[
																this.state
																	.nbUnits
															].stripe
													  ] / 100
													: 0}
											</span>
											<span className="currency">€</span>
										</div>
									</div>
								</div>
							</div>
							<div className="col-11 text-right">
								<button
									className="btn-buy"
									onClick={() => this.onBuy()}
								>
									{Util.loc("Acheter des unités")}
								</button>
							</div>
						</div>
					</div>
				</div>
				<BottomPage />
			</CenteredContainer>
		);
	}
}

export default PurchaseUnits;
