import React, { Component } from "react";
import Proptypes from "prop-types";

class Button extends Component {
	handleClick = () => {
		const { onClick, enabled } = this.props;

		if (enabled && onClick) onClick();
	};

	render() {
		const { enabled, className, style, children } = this.props;
		const btnClassName = `button ${
			enabled ? "orange-bg" : "grey-bg"
		} ${className}`;

		return (
			<button
				onClick={this.handleClick}
				className={btnClassName}
				style={style}
			>
				{children}
			</button>
		);
	}
}

Button.propTypes = {
	//	onClick: Proptypes.func.isRequired,
	enabled: Proptypes.bool.isRequired,
	className: Proptypes.string,
	style: Proptypes.object,
};

export default Button;
