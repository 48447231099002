import React, { Component } from "react";
import Proptypes from "prop-types";

import Util from "../lib/Util";
import { customHistory } from "../index";

import CenteredContainer from "./CenteredContainer";
import BlueContainer from "./BlueContainer";
import TopMenu from "./TopMenu";
import Footer from "./Footer";

class Page extends Component {
	renderHeader() {
		const {
			withoutHeader,
			headerTheme,
			title,
			subtitle,
			titleH2,
			stepClass,
			claimTitle,
			titleCenterH2,
			paddingBot,
		} = this.props;

		if (withoutHeader) return null;

		return (
			<div
				className={`title-container row ${headerTheme ?? ""} ${
					stepClass ?? ""
				} ${paddingBot ?? ""}`}
			>
				<div className="col-12 pb-sm-block pt-sm-block text-center">
					<h1 className={`${claimTitle ?? null}`}>{title}</h1>
					<h2 className="claimTitle upper">{titleH2}</h2>
					<h2 className="claimTitleCenter upper">{titleCenterH2}</h2>
					<span>{subtitle}</span>
				</div>
			</div>
		);
	}

	render() {
		const { App, className, children } = this.props;

		return (
			<CenteredContainer id="page" className={className ?? ""}>
				<TopMenu App={App} />
				{this.renderHeader()}
				{children}
				<BlueContainer
					title={Util.loc("Vous avez une question ?")}
					text={Util.loc(
						"<p>Si vous avez besoin d’aide, n’hésitez pas à nous contacter : <strong>toute l’équipe SPOORS se tient à votre disposition.<strong/></p>"
					)}
					btnText={Util.loc("Contactez notre équipe")}
					btnAction={() => customHistory.push(Util.HOME_CONTACT)}
				/>
				<Footer />
			</CenteredContainer>
		);
	}
}

Page.propTypes = {
	App: Proptypes.object.isRequired,
	className: Proptypes.string,
	withoutHeader: Proptypes.bool,
	headerTheme: Proptypes.oneOf(["light"]),
	title: Proptypes.string,
	subtitle: Proptypes.string,
};

export default Page;
