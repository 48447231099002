import React from "react";
import { Link } from "react-router-dom";

import Util from "../lib/Util";

import HomeInstallationStep from "./HomeInstallationStep";

const HomeInstallation = () => (
	<section id="home-installation">
		<div className="row">
			<div className="col-12 title">{Util.loc("Installation")}</div>
		</div>
		<div className="row d-flex flex-column align-items-center">
			<div className="col-8 body-2 pt-4 text-center">
				{Util.loc(
					"Une installation simple et rapide. Il suffit de coller mon SPOORS et de le scanner une première fois pour le paramétrer.\n"
				)}
			</div>
		</div>
		<div className="row steps-container d-flex flex-row justify-content-center">
			<div className="col-sm-12 p-0 col-lg-8">
				<HomeInstallationStep
					stepNumber={1}
					title={Util.loc("Je commande un SPOORS")}
				>
					<p className="body-2">
						{Util.loc("Pour cela, il suffit de") + " "}
						<a
							href="https://www.amazon.fr/stores/page/AC67A204-56B4-4C8A-BECC-710DF040FBF8?ingress=0&visitId=f2e3cada-2f14-431e-a499-4c56ad690a0c&ref_=ast_bln"
							target="_blank"
							className="highlight"
						>
							{Util.loc("cliquer ici !")}
						</a>
					</p>
				</HomeInstallationStep>
				<HomeInstallationStep
					stepNumber={2}
					title={Util.loc("Je scanne le QR code")}
				>
					<p className="body-2">
						{Util.loc("Avec")}{" "}
						<Link to={Util.SCAN} className="highlight">
							{Util.loc("notre lecteur de QR code")}
						</Link>{" "}
						{Util.loc(
							"ou directement depuis l’application “Caméra” de votre smartphone."
						)}
					</p>
				</HomeInstallationStep>
				<HomeInstallationStep
					stepNumber={3}
					title={Util.loc("Je personnalise mes alertes")}
				>
					<p className="body-2">
						{Util.loc(
							"Je me laisse guider et je personnalise les heures d’appels, le mode de contact et le numéro de téléphone."
						)}
					</p>
				</HomeInstallationStep>
				<HomeInstallationStep
					stepNumber={4}
					title={Util.loc("Je colle mon SPOORS")}
				>
					<p className="body-2">
						{Util.loc("Je colle mon SPOORS, il est opérationnel.")}
					</p>
				</HomeInstallationStep>
			</div>
		</div>
	</section>
);

export default HomeInstallation;
