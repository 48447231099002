import React from "react";
import IcoSpinner from "../style/img/spinner.gif";

class Holdon extends React.Component {
	/*	constructor(props) {
		super(props);
	}
*/
	render() {
		return (
			<div
				className="modal"
				id="modalCookies"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="modalCookiesTitle"
				aria-hidden="false"
				style={{ display: "block", background: "#fff", opacity: 0.5 }}
			>
				<div
					className="modal-dialog modal-dialog-centered text-center"
					role="document"
				>
					<img
						src={IcoSpinner}
						className="mx-auto"
						style={{ width: "50%" }}
						alt=""
					/>
				</div>
			</div>
		);
	}
}
export default Holdon;
