import React from "react";

import Util from "../lib/Util";

import Form from "./Form";

class FormCompany extends React.Component {
	constructor(props) {
		super(props);
		this.state = this.props.App.storyGet({
			company_name: "",
			siret: "",
			business: "",
			company_url: "",
		});
		this.business = {
			"": Util.loc("secteur d’activité *"),
			AGR: Util.loc("Agriculture"),
			AUT: Util.loc("Auto-entrepreneur, artisans"),
			COMM: Util.loc("Autre commerce de détail"),
			BOU: Util.loc("Boulangeries"),
			CAF: Util.loc("Café-Hôtels-Restaurant"),
			GAR: Util.loc("Garages, concessions automobiles"),
			LIB: Util.loc("Profession libérale, Santé"),
			HEB: Util.loc("Salle Hébergement, de spectacle"),
			TER: Util.loc("Tertiaire, bureaux"),
			TPI: Util.loc("TPI"),
		};
	}
	onChange(value, field, process) {
		value = Util.nice(value, field);
		let update = {};
		update[field] = value;
		this.setState(update);
		this.props.App.storyUpdate(update);
		if (this.props.form) this.props.form.notify(process);
	}
	render() {
		return (
			<Form label={Util.loc("Mon entreprise")}>
				<div className="form-row">
					<div className="w-100">
						<div className="row form-group mb-3">
							<div className="col-12 px-0">
								<input
									type="text"
									autoComplete={Util.random()}
									spellCheck={false}
									className="form-control"
									id="raison-sociale"
									placeholder={Util.loc("raison sociale *")}
									required
									value={this.state.company_name}
									onKeyPress={event => {
										if (event.key === "Enter")
											event.target.blur();
									}}
									onChange={event => {
										this.onChange(
											event.target.value,
											"company_name",
											false
										);
									}}
								/>
								<label htmlFor="raison-sociale">
									{Util.loc("Raison sociale *")}
								</label>
								<div
									style={Util.check(
										this.state.company_name,
										this.props.withNext
									)}
									className="invalid-tooltip"
								>
									{Util.loc(
										"Veuillez remplir ce champ correctement"
									)}
								</div>
							</div>
						</div>
						<div className="row form-group mb-3">
							<div className="col-12 px-0">
								<input
									type="text"
									autoComplete={Util.random()}
									spellCheck={false}
									className="form-control"
									id="siret"
									placeholder={Util.loc("SIRET")}
									required
									value={this.state.siret}
									onKeyPress={event => {
										if (event.key === "Enter")
											event.target.blur();
									}}
									onChange={event => {
										this.onChange(
											event.target.value,
											"siret",
											false
										);
									}}
								/>
								<label htmlFor="siret">
									{Util.loc("SIRET")}
								</label>
								<div
									style={Util.checkSiret(
										this.state.siret,
										true
									)}
									className="invalid-tooltip"
								>
									{Util.loc(
										"Veuillez remplir ce champ correctement"
									)}
								</div>
							</div>
						</div>
						<div className="row form-group">
							<div className="col-12 px-0">
								<select
									className="custom-select"
									id="sect-activite"
									required
									value={this.state.business}
									onChange={event => {
										this.onChange(
											event.target.value,
											"business",
											true
										);
									}}
								>
									{Object.keys(this.business).map(
										(key, i) => (
											<option key={i} value={key}>
												{this.business[key]}
											</option>
										)
									)}
								</select>
								<div
									style={Util.check(
										this.state.business,
										this.props.withNext
									)}
									className="invalid-tooltip"
								>
									{Util.loc(
										"Veuillez remplir ce champ correctement"
									)}
								</div>
							</div>
						</div>
						{this.props.url ? (
							<div className="row form-group">
								<div className="col-12 px-0">
									<input
										type="text"
										autoComplete={Util.random()}
										spellCheck={false}
										className="form-control"
										id="url"
										placeholder={Util.loc("Url")}
										required
										value={this.state.company_url}
										onKeyPress={event => {
											if (event.key === "Enter")
												event.target.blur();
										}}
										onChange={event => {
											this.onChange(
												event.target.value,
												"company_url",
												false
											);
										}}
									/>
									<label htmlFor="url">
										{Util.loc("Adresse internet du site")}
									</label>
									<div
										style={Util.checkUrl(
											this.state.company_url,
											this.props.withNext
										)}
										className="invalid-tooltip"
									>
										{Util.loc(
											"Veuillez remplir ce champ correctement"
										)}
									</div>
								</div>
							</div>
						) : null}
					</div>
				</div>
			</Form>
		);
	}
}
export default FormCompany;
