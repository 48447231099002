import React, { Component } from "react";
import PropTypes from "prop-types";

class Toggle extends Component {
	onToggle = () => {
		this.props.onChange(!this.props.enabled);
	};

	renderToggleClassnames() {
		const { enabled } = this.props;
		let classnames = "toggle toggle-container";

		if (enabled) {
			classnames = `${classnames} enabled`;
		}

		return classnames;
	}

	render() {
		return (
			<div className={this.renderToggleClassnames()}>
				<div onClick={this.onToggle} className="toggle-circle" />
			</div>
		);
	}
}

Toggle.propTypes = {
	onChange: PropTypes.func.isRequired,
	enabled: PropTypes.bool,
};

export default Toggle;
