import React from "react";
import Proptypes from "prop-types";
import { Link } from "react-router-dom";

import Util from "./lib/Util";
import "../node_modules/bootstrap/js/src/dropdown";

import Page from "./Components/Page";
import FormCoord from "./Components/FormCoord";
import ButtonSuivant from "./Components/ButtonSuivant";

// Components
import BoutonRevenir from "./Components/BoutonRevenir";
import ModalEmptyPhone from "./Components/ModalEmptyPhone";

class ClaimForm extends React.Component {
	constructor(props) {
		super(props);
		this.props.App.storyNeed("/CLAIM", Util.HOME);
		this.state = this.props.App.storyGet({
			firstname: "",
			lastname: "",
			optinSend: false,
			optinReceive: false,
			cgu: false,
			cpv: "",
		});
		this.state.popupDone = true;
	}

	onChange(value, field) {
		let update = { [field]: Util.nice(value, field) };
		this.setState(update);
		this.props.App.storyUpdate(update);
	}

	goNext() {
		//		console.log(JSON.stringify(this.props.App.storyGet()));
		if (this.props.App.checkClaimForm()) return;
		if (this.props.App.storyGet().phone !== "")
			return this.props.App.storyProcess();
		this.setState({ popupDone: false });
	}
	handleEmptyPhone(ok) {
		this.setState({ popupDone: true });
		if (ok) this.props.App.storyProcess();
	}
	notify(readyToProcess) {
		this.forceUpdate();
	}
	render() {
		const { App } = this.props;

		return (
			<>
				{this.state.popupDone ? null : (
					<ModalEmptyPhone
						App={App}
						type={this.state.type}
						notify={ok => this.handleEmptyPhone(ok)}
					/>
				)}
				<Page
					App={App}
					title={Util.loc("Etape 2 :")}
					titleH2={Util.loc("mes coordonnées")}
					stepClass="title-containerEtape"
					claimTitle="claimTitle"
				>
					{/* <div className="row containt claimBackground"> */}
					<div id="claimForm" className="claimBackground">
						<div
							/* className="col-12 col-sm-10 col-md-8 col-lg-6 pt-block mx-auto needs-validation claimPadding20" */
							className="needs-validation claimPadding20"
							noValidate
						>
							<div className="row d-flex flex-row justify-content-center">
								<div className="col-12 col-md-10 col-lg-6">
									<div className="no-form-container">
										<p
											className="color-title"
											style={{ fontSize: "0.85rem" }}
										>
											{Util.loc(
												"Je donne mes coordonnées pour activer mon SPOORS et être contacté si on scanne mon QR code. Mon numéro reste masqué !"
											)}
										</p>
										<FormCoord
											App={this.props.App}
											form={this}
											withNext={true}
										/>
									</div>
								</div>
							</div>

							<div className="row d-flex flex-row justify-content-center mb-30">
								<div className="col-12 col-md-10 col-lg-6">
									<div className="custom-control custom-checkbox">
										<input
											type="checkbox"
											className="custom-control-input"
											id="cgu-part"
											checked={this.state.cgu}
											onChange={event => {
												this.onChange(
													event.target.checked,
													"cgu"
												);
											}}
										/>
										{this.state.cpv ? (
											<label
												className="custom-control-label text-left worksans-light cgu"
												htmlFor="cgu-part"
											>
												{Util.loc(
													"Je déclare avoir pris connaissance des"
												) + " "}
												<Link to={Util.HOME_CGV}>
													{Util.loc(
														"Conditions générales de vente"
													)}
												</Link>
												{" " +
													Util.loc(
														"et d'utilisation de SPOORS, des"
													) +
													" "}
												<Link
													to={
														Util.HOME_CGV +
														"/" +
														this.state.cpv
													}
												>
													{Util.loc(
														"Conditions particulières"
													)}
												</Link>
												{" " +
													Util.loc(
														"y afférentes et de la Politique de confidentialité et les accepter sans réserve."
													)}
											</label>
										) : (
											<label
												className="custom-control-label text-left worksans-light cgu"
												htmlFor="cgu-part"
											>
												{Util.loc(
													"Je déclare avoir pris connaissance des CGV et CGU et de la politique de confidentialité consultables"
												) + " "}
												<Link to={Util.HOME_CGV}>
													{Util.loc("ici")}
												</Link>
												{" " +
													Util.loc(
														"et les accepter sans réserve."
													)}
											</label>
										)}
									</div>
									{false ? (
										<React.Fragment>
											{" "}
											<div className="custom-control custom-checkbox">
												<input
													type="checkbox"
													className="custom-control-input"
													id="transfert-part"
													checked={
														this.state.optinSend !==
														false
													}
													onChange={event => {
														this.onChange(
															event.target
																.checked,
															"optinSend"
														);
													}}
												/>
												<label
													className="custom-control-label"
													htmlFor="transfert-part"
												>
													{Util.loc(
														"j'accepte que mes informations soient transférées à des"
													) + " "}
													<span
														style={{
															color: "#26C5F0",
															cursor: "pointer",
														}}
														onClick={() =>
															this.goDetail(
																"optinSend"
															)
														}
													>
														{Util.loc(
															"partenaires"
														)}
													</span>
													{" " +
														Util.loc(
															"de SPOORS pour mettre à jour les données clients"
														)}
												</label>
											</div>
											<div className="custon-control custom-checkbox">
												<input
													type="checkbox"
													className="custom-control-input"
													id="mail-part"
													checked={
														this.state
															.optinReceive !==
														false
													}
													onChange={event => {
														this.onChange(
															event.target
																.checked,
															"optinReceive"
														);
													}}
												/>
												<label
													className="custom-control-label"
													htmlFor="mail-part"
												>
													{Util.loc(
														"j'accepte de recevoir par email les propositions des"
													) + " "}
													<span
														style={{
															color: "#26C5F0",
															cursor: "pointer",
														}}
														onClick={() =>
															this.goDetail(
																"optinReceive"
															)
														}
													>
														{Util.loc(
															"partenaires"
														)}
													</span>
													{" " +
														Util.loc("de SPOORS")}
												</label>
											</div>
										</React.Fragment>
									) : null}
								</div>
							</div>

							{/* <div id="buttons-container"> */}
							<div className="row d-flex flex-row justify-content-center">
								<div className="col-12 col-md-10 col-lg-6">
									<div className="row btnRevenirSuivant withNext">
										{/* <ChevRetour to={Util.CLAIM_USAGE} /> */}
										<BoutonRevenir to={Util.CLAIM_USAGE} />
										<ButtonSuivant
											onClick={() => this.goNext()}
											enabled={!App.checkClaimForm()}
											className="step"
											final={true}
										>
											{Util.loc("J'ai terminé")}
										</ButtonSuivant>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Page>
			</>
		);
	}
}

ClaimForm.propTypes = {
	App: Proptypes.object.isRequired,
};

export default ClaimForm;
