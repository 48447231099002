import React from "react";

import Util from "../lib/Util";

import Phone from "../style/img/phone.svg";
import Security from "../style/img/security.svg";
import Celebration from "../style/img/celebration.svg";

import AssetCard from "./AssetCard";

const HomeAssets = () => (
	<section id="assets" className="pb-5 px-5">
		<div className="row mb-lg-5">
			<div className="col-12 text-title">
				<div className="row">
					<div className="div col-12 pt-5 pt-lg-0 title text-center">
						{Util.loc("Les atouts de notre solution")}
					</div>
				</div>
				{/*<div className="row">
									<div className="col-12 subtitle text-lg-right text-sm-center">
										Sous-titre du titre
									</div>
								</div>*/}
			</div>
		</div>
		<div className="cards-container row d-flex justify-content-between">
			<div className="col-sm-12 col-lg-3">
				<AssetCard
					title={Util.loc("Toujours joignable")}
					text={Util.loc(
						"Joignable par email ou appel téléphonique, selon l’heure de la journée et en fonction de mes choix. Je peux aussi déléguer l’alerte à un(e) ami(e)."
					)}
					image={Phone}
				/>
			</div>
			<div className="col-sm-12 col-lg-3">
				<AssetCard
					title={Util.loc("Mon numéro n'apparaît pas")}
					text={Util.loc(
						"Mes coordonnées ne sont pas dévoilées. Mon anonymat est préservé."
					)}
					image={Security}
				/>
			</div>
			<div className="col-sm-12 col-lg-3">
				<AssetCard
					title={Util.loc("Installation en 3 minutes")}
					text={Util.loc(
						"Pas de piles, pas besoin de connexion à internet : je colle mon SPOORS de manière visible et je n'ai plus à m'en préoccuper."
					)}
					image={Celebration}
				/>
			</div>
		</div>
	</section>
);

export default HomeAssets;
