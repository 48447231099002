import React from "react";
import Util from "../lib/Util";

const ModalStep = ({ app, type, notify }) => {
	const handleClickModal = () => {
		notify();
	};
	return (
		<div id="ModaleStep">
			<div className="modal-step-content">
				<p className="big-title">{Util.loc("Bravo !")}</p>
				{type === Util.BELL_PAPER ? (
					<>
						<p>{Util.loc("Je viens de scanner mon étiquette.")}</p>
						<br />
						<p style={{ lineHeight: "1.5rem" }}>
							{Util.loc(
								"L’étiquette permet de me contacter facilement et anonymement."
							)}
						</p>
						<p style={{ lineHeight: "1.5rem" }}>
							{Util.loc(
								"Si je perds un objet, on peut d’un simple scan me contacter sans voir mon numéro."
							)}
						</p>
					</>
				) : type === Util.BELL_STD ? (
					<>
						<p>{Util.loc("Je viens de scanner ma sonnette.")}</p>
						<br />
						<p style={{ lineHeight: "1.5rem" }}>
							{Util.loc(
								"Avec ma sonnette, même si je suis absent, mes voisins pourront me prévenir d’un problème dans mon logement sans jamais voir mon numéro."
							)}
						</p>
					</>
				) : type === Util.BELL_BACK ? (
					<>
						<p>{Util.loc("Je viens de scanner ma vignette.")}</p>
						<br />
						<p style={{ lineHeight: "1.5rem" }}>
							{Util.loc(
								"La vignette permettra de me contacter sans voir mon numéro de téléphone, si je suis mal garé ou que mon véhicule est percuté sur un parking !"
							)}
						</p>
					</>
				) : type === Util.BELL_KEYCHAIN ? (
					<>
						<p>
							{Util.loc("Je viens de scanner mon porte-clefs.")}
						</p>
						<br />
						<p style={{ lineHeight: "1.5rem" }}>
							{Util.loc(
								"Si je perds mes clés, on pourra me contacter d’un simple scan sans voir mon numéro de téléphone."
							)}
						</p>
					</>
				) : (
					<>
						<p>{Util.loc("Je viens de scanner mon SPOORS.")}</p>
						<br />
						<p style={{ lineHeight: "1.5rem" }}>
							{Util.loc(
								"Grâce à mon SPOORS, je serai joignable sans dévoiler mes coordonnées !"
							)}
						</p>
					</>
				)}
				<p className="mt-4 mb-4 big-text">
					{Util.loc(
						"Maintenant, je paramètre mon SPOORS en 2 étapes."
					)}
				</p>
				<button
					className="blueOk-bg big-text"
					onClick={handleClickModal}
				>
					{/* {Util.loc("Je commence")} */}
					{Util.loc("Go !")}
				</button>
			</div>
		</div>
	);
};

export default ModalStep;
