import React, { Component } from "react";
import Proptypes from "prop-types";

import Util from "../lib/Util";

import HorizontalList from "./HorizontalList";
import UtilityItem from "./UtilityItem";

import BuildingIllustration from "../style/img/usage_habitation.png";
import PlaceIllustration from "../style/img/usage_immeuble.png";
import AutoIllustration from "../style/img/usage_auto.png";
import ObjectIllustration from "../style/img/usage_object.png";
import BoxIllustration from "../style/img/usage_box.png";
import VanIllustration from "../style/img/usage_van.png";
import KeychainIllustration from "../style/img/usage_keychain.png";
import PetIllustration from "../style/img/usage_pet.png";
import BagIllustration from "../style/img/usage_bag.png";
import ClothesIllustration from "../style/img/usage_clothes.png";
import ToyIllustration from "../style/img/usage_toy.png";

class UtilitiesSelector extends Component {
	renderUtilityItems() {
		const { usage, usageList } = this.props;

		let items = {
			[Util.USAGE_OBJECT]: {
				label: Util.loc("Sur mon objet"),
				img: ObjectIllustration,
			},
			[Util.USAGE_BUILDING]: {
				label: Util.loc("Dans mon habitat"),
				img: BuildingIllustration,
			},
			[Util.USAGE_CAR]: {
				label: Util.loc("Sur ma voiture"),
				img: AutoIllustration,
			},
			[Util.USAGE_BOX]: {
				label: Util.loc("Sur un box"),
				img: BoxIllustration,
			},
			[Util.USAGE_VAN]: {
				label: Util.loc("Sur un van (équitation)"),
				img: VanIllustration,
			},
			[Util.USAGE_KEYCHAIN]: {
				label: Util.loc("Comme porte-clef"),
				img: KeychainIllustration,
			},
			[Util.USAGE_PET]: {
				label: Util.loc("Pour mon animal"),
				img: PetIllustration,
			},
			[Util.USAGE_BAG]: {
				label: Util.loc("Sur un bagage"),
				img: BagIllustration,
			},
			[Util.USAGE_CLOTHES]: {
				label: Util.loc("Sur un vêtement"),
				img: ClothesIllustration,
			},
			[Util.USAGE_PLACE]: {
				label: Util.loc("Pour mon immeuble"),
				img: PlaceIllustration,
			},
			[Util.USAGE_TOY]: {
				label: Util.loc("Sur une peluche"),
				img: ToyIllustration,
			},
		};

		return usageList.map(key => {
			let item = items[key];
			return (
				<UtilityItem
					onChecked={() => this.props.onItemChecked(key)}
					key={key}
					id={key}
					label={item.label}
					check={usage === key}
					img={item.img}
				/>
			);
		});
	}

	render() {
		return (
			<HorizontalList id="utilities-selector">
				{this.renderUtilityItems()}
			</HorizontalList>
		);
	}
}

UtilitiesSelector.propTypes = {
	onItemChecked: Proptypes.func.isRequired,
	usage: Proptypes.string.isRequired,
};

export default UtilitiesSelector;
